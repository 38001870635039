import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, CardContent, IconButton, Tooltip, Menu, Skeleton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useDispatch, useSelector } from 'react-redux';
import WidgetPlaceholder from '../WidgetPlaceholder/WidgetPlaceholder';
import { SelectChart } from '../../../../../utils/SelectChart';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import TableChartIcon from '@mui/icons-material/TableChart';
import './WidgetCard.scss';
import WidgetActionMenu from './WidgetActionMenu';
import {
  setIsResetZoomTriggerOnWidget,
  setZoomLevelWidgetSVG
} from '../../../../../Redux/slices/processModelCofigSlice';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { getNearestValueFromSet } from '../../../../../utils/GetNearestValueFromSet';
import ZoomLevelDropDown from '../../../../processModelConfiguration/ZoomLevelDropdown/ZoomLevelDropdown';
import DuplicateWidget from '../DuplicateWidget/DuplicateWidget';
import { setIsDashboardRefreshed } from '../../../../../Redux/slices/allDashboardSlice';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  setChartDada,
  setViewAsTableOpenDialog
} from '../../../../../Redux/slices/viewAsTableDialog';
import {
  setAppliedGlobalFilter,
  setAppliedWidgetFilter,
  setFilterListPopoverRef
} from '../../../../../Redux/slices/filterLisrPopoverSlice';
import FilterListPopover from '../../../../Dailogs/FilterListPopover/FilterListPopover';
import DashboardTypography from '../../../../shared/DashboardTypography/DashboardTypography';

const WidgetCard = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedChart, setSelectedChart] = useState(null);
  const {
    chartOption,
    chartData,
    layoutItem,
    handleDeleteChart,
    handleClickOpen,
    openProcessModelPreviewPanel,
    dashboards,
    dashboardData,
    addDuplicateWidget
  } = props;
  const isDashboardEditMode = useSelector((state) => state.dashboard.isDashboardEditMode);
  const { zoomLevelForSVGWidgetsDropdown } = useSelector((state) => state.processModelConfig);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isGlobalFilterApplied, setIsGlobalFilterApplied] = useState(false);
  const [svgZoomValueForWidget, setSvgZoomValueForWidget] = useState(0);
  const open = Boolean(anchorEl);
  const [openDuplicateWidgetDialog, setOpenDuplicateWidgetDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const index = zoomLevelForSVGWidgetsDropdown.findIndex(
      (zoomLevelForSVGWidgetDropdown) =>
        zoomLevelForSVGWidgetDropdown.chartId === chartOption?.chartId
    );
    if (index > -1) {
      setSvgZoomValueForWidget(
        getNearestValueFromSet(zoomLevelForSVGWidgetsDropdown[index].zoomLevel)
      );
    }
  }, [zoomLevelForSVGWidgetsDropdown, zoomLevelForSVGWidgetsDropdown.zoomLevel]);

  const openWidgetMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeWidgetMenu = () => {
    setAnchorEl(null);
  };

  const handleClosePopover = () => {
    dispatch(setFilterListPopoverRef(null));
  };

  useEffect(() => {
    if (
      chartOption?.shape !== 'text' &&
      chartOption?.shape !== 'svg' &&
      chartOption?.shape !== 'image' &&
      chartOption?.type !== 'designReport'
    ) {
      const isFilterApplied = chartOption?.filters?.length > 0 ? true : false;
      setIsGlobalFilterApplied(isFilterApplied);
    }
  }, [chartData]);

  useEffect(() => {
    if (
      (chartOption?.chartData?.length === 0 && chartOption?.shape !== 'svg') ||
      (chartOption?.chartData?.length > 0 && chartOption?.type === 'designReport') ||
      (chartOption?.chartData &&
        chartOption?.type === 'designReport' &&
        chartOption?.dataToDisplay === 'LICENSE_UTILIZATION') ||
      chartOption?.chartData?.length > 0 ||
      chartOption?.shape === 'text' ||
      chartOption?.shape === 'image'
    ) {
      setSelectedChart(SelectChart(props, chartOption.chartData, chartOption));
      setLoading(false);
    }
  }, [chartOption, chartOption?.chartData, props]);

  const setChartData = (chartId) => {
    if (chartOption?.chartId === chartId) {
      dispatch(setChartDada(chartOption));
    }
  };

  const resetZoomOnWidget = () => {
    dispatch(
      setIsResetZoomTriggerOnWidget({
        isResetZoomTriggerOnWidget: true,
        chartId: chartOption?.chartId
      })
    );
    dispatch(setZoomLevelWidgetSVG({ zoomLevel: 0, chartId: chartOption?.chartId }));
  };

  const updateSvgZoomValueForWidget = (zoomValue) => {
    setSvgZoomValueForWidget(zoomValue);
    dispatch(setZoomLevelWidgetSVG({ zoomLevel: zoomValue, chartId: chartOption?.chartId }));
  };

  const createDuplicateWidget = () => {
    closeWidgetMenu();
    setOpenDuplicateWidgetDialog(true);
  };

  const closeDuplicateWidgetDialog = () => {
    setOpenDuplicateWidgetDialog(false);
  };

  const duplicateWidget = (selectedDashboardId, duplicatedWidgetName) => {
    dispatch(setIsDashboardRefreshed(false));
    const updatedChartOption = { ...chartOption, title: duplicatedWidgetName };
    addDuplicateWidget(selectedDashboardId, updatedChartOption, layoutItem, false);
    setOpenDuplicateWidgetDialog(false);
  };

  const displayFilterList = (event) => {
    let globalFilter = [];
    let widgetFilter = [];
    globalFilter = chartOption?.filters?.filter((item) => item.isGlobalFilter === true);
    widgetFilter = chartOption?.filters?.filter((item) => item.isGlobalFilter === undefined);
    dispatch(setAppliedGlobalFilter(globalFilter));
    dispatch(setAppliedWidgetFilter(widgetFilter));
    dispatch(setFilterListPopoverRef(event.currentTarget));
  };

  const getTitle = () => {
    if (loading) {
      return <Skeleton variant='text' width={200} height={30} sx={{ marginBottom: '15px' }} />;
    }
    return chartOption && chartOption.title ? chartOption.title : t('chartTitle');
  };

  return (
    <>
      <Card className='widget-card' data-testid='widget-card' id='widget-card'>
        <div className='widget-action-row'>
          <DashboardTypography
            id='widget-card-title'
            title={chartOption && chartOption?.title ? chartOption?.title : t('chartTitle')}
            typographyClass={'title-16-600 text-overflow-95 widget-card-title'}
          >
            {getTitle()}
          </DashboardTypography>

          <span className='widget-icons-container'>
            {!isDashboardEditMode && isGlobalFilterApplied && (
              <div className='action-buttons'>
                <IconButton
                  variant='plain'
                  color='neutral'
                  size='sm'
                  data-testid='chart-widget-menu'
                >
                  <Tooltip title={t('APPLIED_FILTERS')} arrow>
                    <FilterAltOutlinedIcon
                      id='open-filter-list-popover-icon'
                      onClick={(event) => displayFilterList(event)}
                    ></FilterAltOutlinedIcon>
                  </Tooltip>
                </IconButton>
              </div>
            )}
            {!isDashboardEditMode &&
              (chartOption?.shape === 'line' ||
                chartOption?.shape === 'bar' ||
                chartOption?.shape === 'pie') && (
                <div className='action-buttons'>
                  {loading ? (
                    <Skeleton
                      variant='rectangular'
                      width={30}
                      height={30}
                      sx={{ marginRight: '15px', marginTop: '10px' }}
                    />
                  ) : (
                    <IconButton
                      variant='plain'
                      color='neutral'
                      size='sm'
                      data-testid='view-as-table'
                      onClick={() => {
                        closeWidgetMenu();
                        dispatch(setViewAsTableOpenDialog(true));
                        setChartData(layoutItem.chartId);
                      }}
                    >
                      <Tooltip title={t('VIEW_AS_TABLE')} arrow>
                        <TableChartIcon id='view-as-table-icon' className='action-icons' />
                      </Tooltip>
                    </IconButton>
                  )}
                </div>
              )}
            {chartData?.length > 0 && chartOption?.shape === 'svg' && (
              <div className='action-buttons'>
                <IconButton
                  variant='plain'
                  color='neutral'
                  size='sm'
                  data-testid='full-screen-button'
                  onClick={() => {
                    resetZoomOnWidget();
                  }}
                >
                  <Tooltip title={t('PROCESS_MODEL.RESET_ZOOM')} arrow>
                    <RestartAltIcon id='reset-fullscreen-icon' />
                  </Tooltip>
                </IconButton>
                <ZoomLevelDropDown
                  svgZoomValue={svgZoomValueForWidget}
                  changeZoomValue={(event) => updateSvgZoomValueForWidget(event.target.value)}
                ></ZoomLevelDropDown>

                <IconButton
                  variant='plain'
                  color='neutral'
                  size='sm'
                  data-testid='full-screen-button'
                  onClick={() => {
                    openProcessModelPreviewPanel(chartOption?.chartId);
                  }}
                >
                  <Tooltip title={t('PROCESS_MODEL.FULLSCREEN_WIDGET')} arrow>
                    <FullscreenIcon id='full-screen-pmv-icon' />
                  </Tooltip>
                </IconButton>
              </div>
            )}

            {isDashboardEditMode && (
              <div className='action-buttons'>
                {loading ? (
                  <Skeleton
                    variant='rectangular'
                    width={30}
                    height={30}
                    sx={{ marginRight: '15px', marginTop: '10px' }}
                  />
                ) : (
                  <>
                    <IconButton
                      variant='plain'
                      color='neutral'
                      size='sm'
                      data-testid='chart-edit-menu'
                      onClick={() => {
                        props.handleClickOpen(layoutItem.chartId);
                      }}
                    >
                      <Tooltip title={t('EDIT_Widget')} arrow>
                        <SettingsOutlinedIcon id='edit-widget-icon' />
                      </Tooltip>
                    </IconButton>
                    <IconButton
                      variant='plain'
                      color='neutral'
                      size='sm'
                      data-testid='chart-widget-menu'
                      onClick={openWidgetMenu}
                    >
                      <Tooltip title={t('WIDGET.WIDGET_ACTIONS')} arrow>
                        <MoreVertIcon id='widget-menu'></MoreVertIcon>
                      </Tooltip>
                    </IconButton>
                    <Menu id='basic-menu' anchorEl={anchorEl} open={open} onClose={closeWidgetMenu}>
                      <WidgetActionMenu
                        chartId={layoutItem.chartId}
                        handleDeleteChart={handleDeleteChart}
                        handleClickOpen={handleClickOpen}
                        createDuplicateWidget={createDuplicateWidget}
                        setChartData={setChartData}
                        chartType={chartOption?.shape}
                        closeWidgetMenu={closeWidgetMenu}
                      />
                    </Menu>
                  </>
                )}
              </div>
            )}
          </span>
        </div>
        {(chartData && chartData?.length > 0) ||
        (chartOption && chartOption.shape === 'text') ||
        (chartOption && chartOption.shape === 'image') ||
        (chartOption && chartOption.type === 'designReport') ? (
          <div data-testid='chart-container' id='chart-container'>
            {loading ? <Skeleton variant='rectangular' width='100%' height={300} /> : selectedChart}
          </div>
        ) : (
          <WidgetPlaceholder
            isDashboardEditMode={props.isDashboardEditMode}
            chartId={chartOption?.chartId}
            handleClickOpen={handleClickOpen}
            isLoading={loading}
          ></WidgetPlaceholder>
        )}
        <CardContent orientation='vertical'></CardContent>
      </Card>
      {openDuplicateWidgetDialog && (
        <DuplicateWidget
          handleClose={closeDuplicateWidgetDialog}
          duplicateWidget={duplicateWidget}
          selectedWidgetTitle={chartOption?.title}
          dashboardData={dashboardData}
          dashboards={dashboards}
          openPopup={openDuplicateWidgetDialog}
        ></DuplicateWidget>
      )}
      <FilterListPopover
        handleClose={handleClosePopover}
        chartOption={chartOption}
      ></FilterListPopover>
    </>
  );
};

WidgetCard.propTypes = {
  chartOption: PropTypes.object,
  chartData: PropTypes.any,
  isDashboardEditMode: PropTypes.bool,
  layoutItem: PropTypes.object,
  handleDeleteChart: PropTypes.func,
  handleClickOpen: PropTypes.func,
  openProcessModelPreviewPanel: PropTypes.func,
  dashboards: PropTypes.array,
  addDuplicateWidget: PropTypes.func,
  dashboardData: PropTypes.object
};

export default React.memo(WidgetCard);
