import { MenuItem, Select, Tab, Tabs, Grid, InputLabel, FormControl, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { toast } from 'react-toastify';
import ApiService from '../../services/ApiService';
import '../../styles/DashboardLayout.css';
import {
  PERIOD_TYPES,
  PERIOD_TYPES_LABELS,
  PROCESS_SCOPE,
  PROCESS_SCOPE_LABELS
} from '../../utils/Constants';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import 'dayjs/plugin/timezone';
import 'dayjs/plugin/utc';
import { useStyles } from '../../styles/periodFilterStyles';
import TaskFilter from './TaskFilter';
import LinkFilter from './LinkFilter';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.css';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import '../../styles/FiltersLayout.scss';
import tabTheme from '../shared/Themes/TabTheme';

const PeriodFilter = (props) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(1);
  const classes = useStyles();
  const processScope = PROCESS_SCOPE[0].ProcessScopes;
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(dayjs('2022-04-17'));
  const [endDate, setEndDate] = useState(dayjs('2022-04-17'));
  const [startTime, setStartTime] = useState(dayjs('2022-04-17T00:00:00.000'));
  const [endTime, setEndTime] = useState(dayjs('2022-04-17T00:00:00.000'));
  const [minStartDate, setMinStartDate] = useState(dayjs('2022-04-17'));
  const [maxEndDate, setMaxEndDate] = useState(dayjs('2022-04-17'));
  const periodTypes = PERIOD_TYPES[0].periodTypes;
  const [selectedTask, setSelectedTask] = useState({
    taskIds: [],
    filterType: 'task',
    name: [],
    isExcluded: false
  });

  const [selectedLinks, setSelectedLinks] = useState({
    linkIds: [],
    filterType: 'link',
    name: [],
    isExcluded: false
  });

  const getSelectOptions = (tabIndex) => {
    if (tabIndex === 0) {
      return processScope.map((pScope) => ({
        value: pScope.ProcessScope,
        label: `${PROCESS_SCOPE_LABELS[pScope.ProcessScope]?.task || ''}`
      }));
    } else if (tabIndex === 1) {
      props.setIsValid(true);
      return processScope.map((pScope) => ({
        value: pScope.ProcessScope,
        label: `${PROCESS_SCOPE_LABELS[pScope.ProcessScope]?.cases || ''}`
      }));
    } else if (tabIndex === 2) {
      return processScope.map((pScope) => ({
        value: pScope.ProcessScope,
        label: `${PROCESS_SCOPE_LABELS[pScope.ProcessScope]?.links || ''}`
      }));
    }
    return [];
  };
  const [selectOptions, setSelectOptions] = useState(getSelectOptions(selectedTab));

  const [periodFilter, setPeriodFilter] = useState({
    filterType: 'period',
    periodType: 'CUSTOM_PERIOD',
    isExcluded: false,
    scope: 'CASE',
    name: [],
    processScope: 'ALL',
    startPeriod: '2023-09-26T08:00:00.000',
    endPeriod: '2023-09-26T08:00:00.000'
  });

  const handlePeriodFilterSelection = (
    updatedStartDate,
    updatedStartTime,
    updatedEndDate,
    updatedEndTime
  ) => {
    const timeFormatRegex = /(\d+):(\d+):(\d+)\.(\d+)/;
    const [, startHours, startMinutes, startSeconds, startMilliseconds] = updatedStartTime
      .format('HH:mm:ss.SSS')
      .match(timeFormatRegex) //.split(':')
      .map(Number);
    const [, endHours, endMinutes, endSeconds, endMilliseconds] = updatedEndTime
      .format('HH:mm:ss.SSS')
      .match(timeFormatRegex) //.split(':')
      .map(Number);

    const formattedStartDate = updatedStartDate.format('YYYY-MM-DD');
    const formattedStartTime = `${startHours.toString().padStart(2, '0')}:${startMinutes
      .toString()
      .padStart(2, '0')}:${startSeconds.toString().padStart(2, '0')}.${startMilliseconds
        .toString()
        .padStart(3, '0')}`;
    const formattedEndDate = updatedEndDate.format('YYYY-MM-DD');
    const formattedEndTime = `${endHours.toString().padStart(2, '0')}:${endMinutes
      .toString()
      .padStart(2, '0')}:${endSeconds.toString().padStart(2, '0')}.${endMilliseconds
        .toString()
        .padStart(3, '0')}`;

    const combinedStartPeriod = `${formattedStartDate}T${formattedStartTime}Z`;
    const combinedEndPeriod = `${formattedEndDate}T${formattedEndTime}Z`;

    setPeriodFilter((prevPeriodFilterData) => ({
      ...prevPeriodFilterData,
      startPeriod: combinedStartPeriod,
      endPeriod: combinedEndPeriod
    }));
  };

  useEffect(() => {
    const filteredPeriodFilter = {
      ...periodFilter,
      ...(periodFilter.periodType !== 'CUSTOM_PERIOD' &&
        periodFilter.periodType !== 'LAST_30_DAYS' && {
        startPeriod: undefined,
        endPeriod: undefined
      }),
      ...(periodFilter.periodType === 'LAST_30_DAYS' && {
        startPeriod: undefined
      }),
      ...(periodFilter.scope === 'TASKS' && {
        linkIds: undefined
      }),
      ...(periodFilter.scope === 'LINKS' && {
        taskIds: undefined
      })
    };
    props.handleFilterChange(filteredPeriodFilter);
  }, [periodFilter]);

  const getScope = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return 'TASKS';
      case 1:
        return 'CASE';
      case 2:
        return 'LINKS';
    }
  };

  const handleTabChange = (event, newValue) => {
    const selectedScope = getScope(newValue);
    setPeriodFilter((prevPeriodFilterData) => ({
      ...prevPeriodFilterData,
      scope: selectedScope
    }));
    setSelectedTab(newValue);
    setSelectOptions(getSelectOptions(newValue));
  };

  const fetchPeriodDates = async () => {
    const requestBody = {
      search: null,
      filterPageNo: '1',
      filterPageSize: '100',
      filterSortOrder: 'ASC'
    };
    try {
      setLoading(true);
      const response = await ApiService.getPeriodFilter(
        requestBody,
        props.workgroupId,
        props.projectId
      );

      dayjs.extend(require('dayjs/plugin/timezone'));
      dayjs.extend(require('dayjs/plugin/utc'));
      const fromDate = dayjs(response.from).utc();
      const toDate = dayjs(response.to).utc();
      setLoading(false);
      setStartDate(fromDate);
      setEndDate(toDate);
      setStartTime(dayjs(fromDate));
      setEndTime(dayjs(toDate));
      setMinStartDate(fromDate);
      setMaxEndDate(toDate);
      handlePeriodFilterSelection(fromDate, dayjs(fromDate), toDate, dayjs(toDate));
    } catch (error) {
      setLoading(false);
      toast.error(t('FILTER.FAILED_TO_FETCH_PERIOD_DATES'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchPeriodDates();
      if (props?.filterObj && props?.filterObj?.periodType && props?.filterObj?.scope) {
        setPeriodFilter(props.filterObj);
        if (props?.filterObj?.periodType === 'CUSTOM_PERIOD') {
          dayjs.extend(require('dayjs/plugin/timezone'));
          dayjs.extend(require('dayjs/plugin/utc'));
          const fromDate = dayjs(props?.filterObj?.startPeriod).utc();
          const toDate = dayjs(props?.filterObj?.endPeriod).utc();
          setLoading(false);
          setStartDate(fromDate);
          setEndDate(toDate);
          setStartTime(dayjs(fromDate));
          setEndTime(dayjs(toDate));
          handlePeriodFilterSelection(fromDate, dayjs(fromDate), toDate, dayjs(toDate));
        }
        if (props?.filterObj?.scope === 'TASKS') {
          setSelectedTab(0);
          setSelectedTask((prevSelectedTask) => ({
            ...prevSelectedTask,
            taskIds: props.filterObj.taskIds
          }));
        }
        if (props?.filterObj?.scope === 'LINKS') {
          setSelectedTab(2);
          setSelectedLinks((prevSelectedLinks) => ({
            ...prevSelectedLinks,
            linkIds: props.filterObj.linkIds
          }));
        }
      }
    };

    fetchData();
  }, []);

  const handleTaskFilterChange = (value) => {
    setPeriodFilter((prevPeriodFilterData) => ({
      ...prevPeriodFilterData,
      taskIds: value ? value.taskIds : []
    }));
    props.handleFilterChange(periodFilter);
  };

  const handleLinkFilterChange = (value) => {
    setPeriodFilter((prevPeriodFilterData) => ({
      ...prevPeriodFilterData,
      linkIds: value ? value.linkIds : []
    }));
    props.handleFilterChange(periodFilter);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    handlePeriodFilterSelection(date, startTime, endDate, endTime);
  };

  const onStartTimeChange = (date) => {
    const updatedStartTime = date.millisecond(0);
    setStartTime(updatedStartTime);
    handlePeriodFilterSelection(startDate, updatedStartTime, endDate, endTime);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    handlePeriodFilterSelection(startDate, startTime, date, endTime);
  };

  const onEndTimeChange = (date) => {
    const updatedEndTime = date.millisecond(0);
    setEndTime(updatedEndTime);
    handlePeriodFilterSelection(startDate, startTime, endDate, updatedEndTime);
  };

  const handleProcessScopeChange = (selectedProcessScope) => {
    setPeriodFilter((prevPeriodFilterData) => ({
      ...prevPeriodFilterData,
      processScope: selectedProcessScope
    }));
  };

  const handlePeriodTypeChange = (selectedPeriodType) => {
    setPeriodFilter((prevPeriodFilterData) => ({
      ...prevPeriodFilterData,
      periodType: selectedPeriodType
    }));
  };
  const handleExcludChange = (selectedIsExcluded) => {
    setPeriodFilter((prevPeriodFilterData) => ({
      ...prevPeriodFilterData,
      isExcluded: selectedIsExcluded
    }));
  };

  const dateTimePickers = () => {
    return (
      <>
        <FormControl sx={{ width: '100%', marginTop: '20px' }}>
          <InputLabel id='filter-dialog-title'>
            {t('PROCESS_SCOPE')}
          </InputLabel>
          <Select
            label={t('PROCESS_SCOPE')}
            labelId='filter-dialog-title'
            value={periodFilter.processScope}
            onChange={(e) => {
              handleProcessScopeChange(e.target.value);
            }}
            fullWidth
            sx={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 500,
              color: '#4D5464',
              height: '40px',

            }}
            data-testid='process-scope-select'
          >
            {selectOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#4D5464'
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {periodFilter.periodType === 'CUSTOM_PERIOD' && (
          <span>
            <div className={classes.customPeriod}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                    <DatePicker
                      label='Start Date'
                      value={startDate}
                      onChange={handleStartDateChange}
                      minDate={minStartDate ? minStartDate : null}
                      maxDate={maxEndDate}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['TimePicker']} sx={{ width: '100%' }}>
                    <TimePicker
                      label='Start Time'
                      views={['hours', 'minutes', 'seconds']}
                      value={startTime}
                      ampm={false}
                      timeSteps={{ minutes: 1, seconds: 1 }}
                      onChange={(newValue) => onStartTimeChange(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            <div className={classes.endPeriod}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                    <DatePicker
                      label='End Date'
                      value={endDate}
                      onChange={handleEndDateChange}
                      minDate={startDate ? startDate : null}
                      maxDate={maxEndDate}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['TimePicker']} sx={{ width: '100%' }}>
                    <TimePicker
                      label='End Time'
                      views={['hours', 'minutes', 'seconds']}
                      value={endTime}
                      ampm={false}
                      timeSteps={{ minutes: 1, seconds: 1 }}
                      onChange={(newValue) => onEndTimeChange(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </span>
        )}
      </>
    );
  };

  return (
    <Grid
      style={{ width: '100%', marginLeft: '2px' }}
      container
      spacing={2}
      className='genericfilter-grid'

    >
      <Grid item lg={10.2} xl={10.2} className='genericfilter-grid'>
        <FormControl size='small' sx={{ width: '100%' }}>
          <InputLabel id='period_type_label'>
            {t('PERIOD_TYPE')}
          </InputLabel>
          <Select
            label={t('PERIOD_TYPE')}
            value={periodFilter.periodType}
            onChange={(e) => {
              handlePeriodTypeChange(e.target.value);
            }}
            fullWidth
            data-testid='period-type-select'
            labelId='period_type_label'
            id='period_type-label'
            key='period_type'
            role='listbox'
            size='small'
          >
            {periodTypes.map((periodType) => (
              <MenuItem key={periodType.periodType} value={periodType.periodType}>
                {PERIOD_TYPES_LABELS[periodType.periodType]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={1.8} xl={1.8} className='genericfilter-grid'>
        <FormGroup>
          <FormControlLabel
            labelPlacement='top'
            control={
              <Checkbox
                checked={periodFilter.isExcluded}
                onChange={(e) => handleExcludChange(e.target.checked)}
                data-testid='exclude-switch'
                id='exclude-switch'
              />
            }
            label='Exclude'
          />
        </FormGroup>
      </Grid>

      <Grid item lg={12} xl={12} className='genericfilter-grid'>
        <div className={classes.tabsContainer} id='filters-main-container'>
          <ThemeProvider theme={tabTheme}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor='primary'
              textColor='primary'
              variant='fullWidth'
              sx={{
                borderRadius: '6px',
                padding: '4px',
                margin: '2%'
              }}
              id='filters-container-tabs'
            >
              <Tab
                label='Tasks'
                className={selectedTab === 0 ? classes.selectedTab : ''}
              />
              <Tab
                label='Cases'
                id='filters-container-tab'
                className={selectedTab === 1 ? classes.selectedTab : ''}
              />
              <Tab
                label='Links'
                id='filters-container-tab'
                className={selectedTab === 2 ? classes.selectedTab : ''}
              />
            </Tabs>
          </ThemeProvider>
          <div>
            {selectedTab === 0 && (
              <div className={classes.tabContent}>
                <TaskFilter
                  workgroupId={props.workgroupId}
                  projectId={props.projectId}
                  filterObj={selectedTask}
                  handleFilterChange={handleTaskFilterChange}
                  setIsValid={props.setIsValid}
                />
                {dateTimePickers()}
              </div>
            )}
            {selectedTab === 1 && <div className={classes.tabContent}>{dateTimePickers()}</div>}
            {selectedTab === 2 && (
              <div className={classes.tabContent}>
                <LinkFilter
                  workgroupId={props.workgroupId}
                  projectId={props.projectId}
                  handleFilterChange={handleLinkFilterChange}
                  filterObj={selectedLinks}
                  setIsValid={props.setIsValid}
                />
                {dateTimePickers()}
              </div>
            )}
          </div>
        </div>
      </Grid>
      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </Grid>
  );
};

PeriodFilter.propTypes = {
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  selectedPeriodType: PropTypes.string,
  filterObj: PropTypes.object,
  handleFilterChange: PropTypes.func,
  setIsValid: PropTypes.func
};
export default PeriodFilter;
