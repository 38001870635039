import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ToasterError } from '../../../utils/toaster';
import TableWidget from '../../charts/tableWidget/TableWidget';
import LoadingSpinner from '../../loader/LoadingSpinner';
import { USER_LIST_REPORT_TABLE_HEADERS } from '../../../utils/Constants';
import './ReportPreviewPanel.scss';
import ReportService from '../../../services/ReportService';
import BarChart from '../../charts/Bar/BarChart';
import StackBarChart from '../../charts/StackBarChart/StackBarChart';
import { licenseUtilizationReportDataFormatting } from '../../../utils/licenseUtilizationReportDataFormatting';
import chartImage from '../../../assets/thumbnails/chartPlaceholder.svg';
import { getAxisTitle, getReportData, getReportTableViewData } from '../../../utils/reportUtils';

const ReportPreviewPanel = (props) => {
  const { t } = useTranslation();
  const { chartOptions, setChartOptions, workgroupId } = props;
  const [loading, setLoading] = useState(false);
  const [licenseUtilization, setLicenseUtilization] = useState(null);
  useEffect(() => {
    if (chartOptions?.dataToDisplay === 'LICENSE_UTILIZATION' && chartOptions?.shape) {
      Promise.all([fetchReportData('LICENSE_COUNT'), fetchReportData('LICENSE_EFFECTIVE')]).then(
        (combinedReportData) => {
          const licenseUtilizations = licenseUtilizationReportDataFormatting(combinedReportData);
          setChartOptions((prevChartOptions) => ({
            ...prevChartOptions,
            chartData: licenseUtilizations
          }));
          setLicenseUtilization(licenseUtilizations);
        }
      );
    } else if (
      chartOptions?.dataToDisplay &&
      chartOptions?.shape &&
      chartOptions?.shape !== 'designReport' &&
      isRepositorySelectionValid(chartOptions)
    ) {
      fetchReportData();
    }
  }, [
    chartOptions.dataToDisplay,
    chartOptions.shape,
    chartOptions.secondThreshold,
    chartOptions.repositoryName
  ]);

  const isRepositorySelectionValid = (chartOption) => {
    if (
      chartOption.dataToDisplay === 'USER_LOGINS_COUNT_BY_USERNAME' ||
      chartOption.dataToDisplay === 'APP_INTERACTIONS_COUNT_BY_USERNAME' ||
      chartOption.dataToDisplay === 'OBJECT_ACCESS_COUNT' ||
      chartOption.dataToDisplay === 'TABS_VISIT_COUNT'
    ) {
      return chartOption.repositoryName && chartOption.repositoryName.trim() !== '';
    }
    return true;
  };

  const fetchReportData = async (dataToDisplay = '') => {
    try {
      setLoading(true);
      const requestBody = {
        reportType: dataToDisplay ? dataToDisplay : chartOptions.dataToDisplay,
        designReportDto: {
          approvedOnly: true,
          filters: [],
          properties: []
        },
        workgroupId: workgroupId,
        repositoryName: chartOptions?.repositoryName ? chartOptions.repositoryName : '',
        secondThreshold: chartOptions?.secondThreshold ? chartOptions.secondThreshold : 604800
      };
      const response = await ReportService.generateReport(requestBody);
      if (chartOptions?.dataToDisplay === 'LICENSE_UTILIZATION') {
        return response;
      } else {
        setChartOptions((prevChartOptions) => ({
          ...prevChartOptions,
          chartData: getReportData(chartOptions?.dataToDisplay, response),
          xAxisTitle: getAxisTitle(chartOptions?.dataToDisplay).xAxisTitle,
          yAxisTitle: getAxisTitle(chartOptions?.dataToDisplay).yAxisTitle,
          tableViewData: getReportTableViewData(chartOptions?.dataToDisplay, response)
        }));
      }
    } catch (error) {
      ToasterError(t('REPORT.FAILED_GENERATE_REPORT_API'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='report-preview-container'>
      <div data-testid='preview-report-title' className='report-container'>
        {props?.chartOptions?.title}
      </div>
      {props?.chartOptions?.chartData && (
        <>
          {chartOptions?.dataToDisplay === 'USER_LIST' &&
            props?.chartOptions?.chartData?.length > 0 && (
              <TableWidget
                tableHeaders={USER_LIST_REPORT_TABLE_HEADERS}
                tableData={props?.chartOptions?.chartData}
              />
            )}
          {(chartOptions?.dataToDisplay === 'LICENSE_EFFECTIVE' ||
            chartOptions?.dataToDisplay === 'USER_LOGINS_COUNT_BY_USERNAME' ||
            chartOptions?.dataToDisplay === 'APP_INTERACTIONS_COUNT_BY_USERNAME' ||
            chartOptions?.dataToDisplay === 'OBJECT_ACCESS_COUNT' ||
            chartOptions?.dataToDisplay === 'TABS_VISIT_COUNT') &&
            props?.chartOptions?.chartData?.length > 0 && (
              <BarChart
                width='100%'
                height='100%'
                options={chartOptions}
                chartData={props?.chartOptions?.chartData}
                themeColors={props.themeColors}
                isWidgetConfigureMode={true}
              />
            )}
          {chartOptions?.dataToDisplay === 'LICENSE_UTILIZATION' && licenseUtilization && (
            <StackBarChart chartData={licenseUtilization}></StackBarChart>
          )}
        </>
      )}

      {!(
        (chartOptions?.dataToDisplay === 'LICENSE_UTILIZATION' && licenseUtilization) ||
        ((chartOptions?.dataToDisplay === 'USER_LIST' ||
          chartOptions?.dataToDisplay === 'LICENSE_EFFECTIVE' ||
          chartOptions?.dataToDisplay === 'USER_LOGINS_COUNT_BY_USERNAME' ||
          chartOptions?.dataToDisplay === 'APP_INTERACTIONS_COUNT_BY_USERNAME' ||
          chartOptions?.dataToDisplay === 'OBJECT_ACCESS_COUNT' ||
          chartOptions?.dataToDisplay === 'TABS_VISIT_COUNT') &&
          props?.chartOptions?.chartData?.length > 0)
      ) && (
        <div className='widget-placeholder'>
          <img src={chartImage} alt='chart thumbnail' data-testid='chart-thumbnail' />
        </div>
      )}
      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </div>
  );
};

ReportPreviewPanel.propTypes = {
  chartOptions: PropTypes.object,
  setChartOptions: PropTypes.func,
  workgroupId: PropTypes.string,
  themeColors: PropTypes.array
};

export default ReportPreviewPanel;
