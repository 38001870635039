import { Tab, Tabs, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../styles/DashboardLayout.css';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useStyles } from '../../styles/periodFilterStyles';
import TaskFilter from './TaskFilter';
import LinkFilter from './LinkFilter';
import { checkIsValid, getScope } from '../../utils/filter';
import ReworkAndOccurrencesPickers from './ReworkAndOccurrencesPickers';
import tabTheme from '../shared/Themes/TabTheme';

const ReworkFilter = (props) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState();
  const [reworkError, setReworkError] = useState(false);
  const classes = useStyles();
  const [selectedTask, setSelectedTask] = useState({
    taskIds: [],
    filterType: 'task',
    name: []
  });

  const [selectedLinks, setSelectedLinks] = useState({
    linkIds: [],
    filterType: 'link',
    name: []
  });

  const [reworkFilter, setReworkFilter] = useState({
    filterType: 'rework',
    scope: 'CASE',
    name: [],
    operator: '>=',
    value: 0
  });

  useEffect(() => {
    setSelectedTab(1);
    if (props?.filterObj && props?.filterObj?.scope) {
      if (props?.filterObj?.scope === 'TASKS') {
        setSelectedTab(0);
        setSelectedTask((prevSelectedTask) => ({
          ...prevSelectedTask,
          taskIds: props.filterObj.taskIds
        }));
      }
      if (props?.filterObj?.scope === 'LINKS') {
        setSelectedTab(2);
        setSelectedLinks((prevSelectedLinks) => ({
          ...prevSelectedLinks,
          linkIds: props.filterObj.linkIds
        }));
      }
      setReworkFilter(props.filterObj);
    }
    props.setIsValid(
      checkIsValid(
        props?.filterObj?.scope === 'TASKS' ? props.filterObj.taskIds : props.filterObj.linkIds,
        props?.filterObj?.value >= 0,
        props?.filterObj?.scope === 'CASE' ? true : false
      )
    );
  }, []);

  useEffect(() => {
    const filteredReworkFilter = {
      ...reworkFilter,
      ...(reworkFilter.scope === 'TASKS' && {
        linkIds: undefined
      }),
      ...(reworkFilter.scope === 'LINKS' && {
        taskIds: undefined
      }),
      ...(reworkFilter.scope === 'CASE' && {
        taskIds: undefined,
        linkIds: undefined
      })
    };
    props.handleFilterChange(filteredReworkFilter);
  }, [reworkFilter]);

  const handleTabChange = (event, newValue) => {
    const selectedScope = getScope(newValue);
    setReworkFilter((prevReworkFilterData) => ({
      ...prevReworkFilterData,
      scope: selectedScope
    }));
    setSelectedTab(newValue);
    props.setIsValid(
      checkIsValid(
        selectedScope === 'TASKS' ? reworkFilter.taskIds : reworkFilter.linkIds,
        reworkFilter.value >= 0,
        selectedScope === 'CASE' ? true : false
      )
    );
  };

  const handleTaskFilterChange = (value) => {
    setReworkFilter((prevReworkFilterData) => ({
      ...prevReworkFilterData,
      taskIds: value ? value.taskIds : []
    }));
    props.handleFilterChange(reworkFilter);
    props.setIsValid(checkIsValid(value.taskIds, reworkFilter.value >= 0, false));
  };

  const handleLinkFilterChange = (value) => {
    setReworkFilter((prevReworkFilterData) => ({
      ...prevReworkFilterData,
      linkIds: value ? value.linkIds : []
    }));
    props.handleFilterChange(reworkFilter);
    props.setIsValid(checkIsValid(value.linkIds, reworkFilter.value >= 0, false));
  };

  const handleReworkNumberChange = (event) => {
    setReworkFilter((prevReworkFilterData) => ({
      ...prevReworkFilterData,
      value: event.target.value
    }));
    if (event.target.value !== '' && Number(event.target.value) >= 0) {
      setReworkError(false);
      props.setIsValid(
        checkIsValid(
          reworkFilter.scope === 'TASKS' ? reworkFilter.taskIds : reworkFilter.linkIds,
          Number(event.target.value) >= 0,
          reworkFilter.scope === 'CASE' ? true : false
        )
      );
    } else {
      setReworkError(true);
      props.setIsValid(false);
    }
  };

  const handleOperatorChange = (selectedOperator) => {
    setReworkFilter((prevReworkFilterData) => ({
      ...prevReworkFilterData,
      operator: selectedOperator
    }));
  };

  return (
    <>
      <div className={classes.tabsContainer} id='filters-main-container'>
        <ThemeProvider theme={tabTheme}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            sx={{
              padding: '4px',
              margin: '2%',
              border: '1px solid rgb(224, 226, 231)'
            }}
            id='filters-container-tabs'
          >
            <Tab
              label='Tasks'
              className={selectedTab === 0 ? classes.selectedTab : ''}
              id='filters-container-tab'
            />
            <Tab
              label='Cases'
              className={selectedTab === 1 ? classes.selectedTab : ''}
              id='filters-container-tab'
            />
            <Tab
              label='Links'
              className={selectedTab === 2 ? classes.selectedTab : ''}
              id='filters-container-tab'
            />
          </Tabs>
        </ThemeProvider>

        <div>
          {selectedTab === 0 && (
            <div className={classes.tabContent}>
              <TaskFilter
                workgroupId={props.workgroupId}
                projectId={props.projectId}
                filterObj={selectedTask}
                handleFilterChange={handleTaskFilterChange}
                setIsValid={props.setIsValid}
              />
              <ReworkAndOccurrencesPickers
                reworkOccurrencesError={reworkError}
                reworkOccurrencesFilter={reworkFilter}
                reworkOccurrenceNumberChange={handleReworkNumberChange}
                handleOperatorChange={handleOperatorChange}
                numericFieldTitle={t('FILTER.NUMERIC_VALUE_TO_COMPARE')}
                minNumber={'0'}
                minNumberErrorMessage={t('FILTER.NUMERIC_VALUE_ERROR_MESSAGE')}
                placeholder={t('FILTER.PLEASE_ENTER_NUMERIC_VALUE_TO_COMPARE')}
              ></ReworkAndOccurrencesPickers>
            </div>
          )}
          {selectedTab === 1 && (
            <div className={classes.tabContent}>
              <ReworkAndOccurrencesPickers
                reworkOccurrencesError={reworkError}
                reworkOccurrencesFilter={reworkFilter}
                reworkOccurrenceNumberChange={handleReworkNumberChange}
                handleOperatorChange={handleOperatorChange}
                numericFieldTitle={t('FILTER.NUMERIC_VALUE_TO_COMPARE')}
                minNumber={'0'}
                minNumberErrorMessage={t('FILTER.NUMERIC_VALUE_ERROR_MESSAGE')}
                placeholder={t('FILTER.PLEASE_ENTER_NUMERIC_VALUE_TO_COMPARE')}
              ></ReworkAndOccurrencesPickers>
            </div>
          )}
          {selectedTab === 2 && (
            <div className={classes.tabContent}>
              <LinkFilter
                workgroupId={props.workgroupId}
                projectId={props.projectId}
                handleFilterChange={handleLinkFilterChange}
                filterObj={selectedLinks}
                setIsValid={props.setIsValid}
                data-testid='filter_link_select'
              />
              <ReworkAndOccurrencesPickers
                reworkOccurrencesError={reworkError}
                reworkOccurrencesFilter={reworkFilter}
                reworkOccurrenceNumberChange={handleReworkNumberChange}
                handleOperatorChange={handleOperatorChange}
                numericFieldTitle={t('FILTER.NUMERIC_VALUE_TO_COMPARE')}
                minNumber={'0'}
                minNumberErrorMessage={t('FILTER.NUMERIC_VALUE_ERROR_MESSAGE')}
                placeholder={t('FILTER.PLEASE_ENTER_NUMERIC_VALUE_TO_COMPARE')}
              ></ReworkAndOccurrencesPickers>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

ReworkFilter.propTypes = {
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  filterObj: PropTypes.object,
  handleFilterChange: PropTypes.func,
  setIsValid: PropTypes.func
};
export default ReworkFilter;
