import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Skeleton
} from '@mui/material';
import placeholderImage from '../../assets/thumbnails/placeholderImage.svg';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import './AppCard.scss';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatDate } from '../../utils/utils';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const AppCard = (props) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActionButtonMenu = () => {
    setAnchorEl(null);
  };

  const onEditAppClick = () => {
    closeActionButtonMenu();
    props.editApp(props.appDetails.id);
  };

  const onDeleteAppClick = () => {
    closeActionButtonMenu();
    props.handleDeleteApp(props.appDetails.id);
  };

  return (
    <Card>
      {props.isAppsLoading ? (
        <Skeleton sx={{ height: 104 }} animation='wave' variant='rectangular' />
      ) : (
        <CardMedia
          className='img'
          onClick={() => props.navigateToDashboard(props.appDetails.id)}
          sx={{ height: 104, marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#9FA5E0' }}
          image={
            props.appDetails?.thumbnail
              ? 'data:image/png;base64,' + props.appDetails?.thumbnail
              : placeholderImage
          }
        />
      )}

      <CardContent
        className='app-card-content-grid'
        onClick={() => props.navigateToDashboard(props.appDetails.id)}
      >
        <DashboardTypography typographyClass={'title title-16-700'}>
          {props.isAppsLoading ? <Skeleton variant='text' width='95%' /> : props.appDetails.name}
        </DashboardTypography>

        <DashboardTypography typographyClass={'description title-14-600 text-overflow-95'}>
          {props.isAppsLoading ? (
            <Skeleton variant='text' width='75%' />
          ) : (
            props.appDetails.description
          )}
        </DashboardTypography>
      </CardContent>

      <Divider />

      <CardActions className='app-card-action'>
        <DashboardTypography typographyClass={'modified-date title-12-600'}>
          {props.isAppsLoading ? (
            <Skeleton variant='text' width={150} />
          ) : (
            <>
              {t('APP.DATE_MODIFIED')} {formatDate(props.appDetails.modifiedAt)}
            </>
          )}
        </DashboardTypography>

        {props.isAppsLoading ? null : (
          <>
            <IconButton
              variant='plain'
              color='neutral'
              size='sm'
              data-testid='chart-widget-menu'
              onClick={handleClick}
              className='action-menu-button'
            >
              <MoreVertIcon></MoreVertIcon>
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={closeActionButtonMenu}>
              <MenuItem className='menu-item' onClick={() => onEditAppClick()}>
                <EditOutlinedIcon className='action-button-icon' />
                {t('EDIT')}
              </MenuItem>
              <MenuItem className='menu-item' onClick={() => onDeleteAppClick()}>
                {' '}
                <DeleteOutlinedIcon className='action-button-icon' />
                {t('DELETE')}
              </MenuItem>
            </Menu>
          </>
        )}
      </CardActions>
    </Card>
  );
};

AppCard.propTypes = {
  appDetails: PropTypes.object,
  editApp: PropTypes.func,
  handleDeleteApp: PropTypes.func,
  navigateToDashboard: PropTypes.func,
  isAppsLoading: PropTypes.bool
};

export default AppCard;
