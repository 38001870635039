import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import {
  linkTypesList,
  bubbleStatistics,
  bubbleChartFrequency,
  bubbleChartduration
} from '../../../utils/Constants';
import DashboardTypography from '../../shared/DashboardTypography/DashboardTypography';
import { useTranslation } from 'react-i18next';

const ConfigureBubbleChart = (props) => {
  const { t } = useTranslation();
  const [linkTypesError, setLinkTypesError] = useState(false);
  const [bubbleStatisticsError, setBubbleStatisticsError] = useState(false);
  const [yAxisStatisticsError, setYAxisStatisticsError] = useState(false);

  const setBubbleChartProperties = (key, value) => {
    const chartObj = {
      ...props?.chartOptions,
      [key]: value
    };
    props?.setChartOptions(chartObj);
    props?.fetchBubbleChartData(chartObj);
  };

  const setBubbleMetricProperties = (key, value) => {
    let selectedMetricData = [];
    if (value !== bubbleChartFrequency && value !== bubbleChartduration) {
      selectedMetricData = props?.bubbleChartData?.filter(
        (bubbleMetricData) => bubbleMetricData.name === value
      );
      const updatedYAxisMetricList = props?.yAxisMetricList?.filter(
        (metricList) => metricList?.name !== value
      );
      props?.setYAxisMetricList(updatedYAxisMetricList);
    }
    const chartObj = {
      ...props?.chartOptions,
      bubbleMetricId:
        value !== bubbleChartFrequency && value !== bubbleChartduration
          ? selectedMetricData[0]?.id
          : '',
      [key]: value !== bubbleChartFrequency && value !== bubbleChartduration ? 'metric' : value,
      bubbleMetricsUnit:
        value !== bubbleChartFrequency && value !== bubbleChartduration
          ? selectedMetricData[0]?.unit
          : value,
      xAxisTitle: value
    };
    props?.setChartOptions(chartObj);
    props?.fetchBubbleChartData(chartObj);
  };

  const setYAxisMetricProperties = (key, value) => {
    let selectedyAxisMetricData = [];
    if (value !== bubbleChartduration && value !== bubbleChartFrequency) {
      selectedyAxisMetricData = props?.bubbleChartData?.filter(
        (yAxisData) => yAxisData.name === value
      );
      const updatedBubbleMetricList = props?.bubbleMetricList?.filter(
        (metricList) => metricList?.name !== value
      );
      props?.setBubbleMetricList(updatedBubbleMetricList);
    }
    const chartObj = {
      ...props?.chartOptions,
      yAxisMetricId:
        value !== bubbleChartduration && value !== bubbleChartFrequency
          ? selectedyAxisMetricData[0]?.id
          : '',
      [key]: value !== bubbleChartduration && value !== bubbleChartFrequency ? 'metric' : value,
      yAxisMetricsUnit:
        value !== bubbleChartduration && value !== bubbleChartFrequency
          ? selectedyAxisMetricData[0]?.unit
          : value,
      yAxisTitle: value
    };
    props?.setChartOptions(chartObj);
    props?.fetchBubbleChartData(chartObj);
  };

  const getBubbleMetricName = (metricName) => {
    const displayedMetricName = props?.bubbleChartData?.filter(
      (bubbleData) => metricName.id === bubbleData.id
    );
    return displayedMetricName[0]?.name;
  };

  return (
    <>
      {props?.chartOptions?.scope === 'links' && (
        <FormControl fullWidth size='small' error={linkTypesError}>
          <InputLabel required id='link-type-select-label'>
            {t('BUBBLE_CHART.LINK_TYPE')}
          </InputLabel>
          <Select
            labelId='link-type-select-label'
            required
            label='Link type'
            data-testid='link-type-select-label'
            value={props?.chartOptions?.bound}
            onChange={(event) => {
              setBubbleChartProperties('bound', event.target.value);
              event ? setLinkTypesError(false) : setLinkTypesError(true);
            }}
          >
            {linkTypesList?.length > 0 &&
              props?.chartOptions?.type === 'dimensionBubblechart' &&
              linkTypesList?.map((option, index) => (
                <MenuItem key={index} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
          </Select>
          {linkTypesError && (
            <FormHelperText>
              <DashboardTypography typographyClass='title-12-400'>
                {t('BUBBLE_CHART.LINK_TYPE_ERROR')}
              </DashboardTypography>
            </FormHelperText>
          )}
        </FormControl>
      )}

      <FormControl fullWidth size='small'>
        <InputLabel id='bubble-metrics-select-label'>
          {t('BUBBLE_CHART.BUBBLE_METRICS')}
        </InputLabel>
        <Select
          labelId='bubble-metrics-select-label'
          label='Bubble metrics'
          data-testid='bubble-metrics-select-label'
          value={props?.chartOptions.xAxisTitle}
          onChange={(event) => {
            setBubbleMetricProperties('bubbleType', event.target.value);
          }}
        >
          {props?.chartOptions?.yAxisTitle !== bubbleChartFrequency && (
            <MenuItem value={bubbleChartFrequency}>{t('FREQUENCY')}</MenuItem>
          )}
          {props?.chartOptions?.yAxisTitle !== bubbleChartduration && (
            <MenuItem value={bubbleChartduration}>{t('FILTER.DURATION')}</MenuItem>
          )}
          {props?.bubbleMetricList?.length > 0 &&
            props?.bubbleMetricList?.map((option, index) => (
              <MenuItem key={index} value={option.name}>
                {getBubbleMetricName(option)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl fullWidth size='small' error={bubbleStatisticsError}>
        <InputLabel id='bubble-statistics-select-label'>
          {t('BUBBLE_CHART.BUBBLE_STATISTICS')}
        </InputLabel>
        <Select
          labelId='bubble-statistics-select-label'
          label='Bubble statistics'
          data-testid='bubble-statistics-select-label'
          value={props?.chartOptions?.bubbleStatistic}
          onChange={(event) => {
            setBubbleChartProperties('bubbleStatistic', event.target.value);
            event ? setBubbleStatisticsError(false) : setBubbleStatisticsError(true);
          }}
        >
          {bubbleStatistics?.length > 0 &&
            bubbleStatistics?.map((option, index) => (
              <MenuItem key={index} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
        </Select>
        {bubbleStatisticsError && (
          <FormHelperText>
            <DashboardTypography typographyClass='title-12-400'>
              {t('BUBBLE_CHART.BUBBLE_STATISTICS_ERROR')}
            </DashboardTypography>
          </FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth size='small'>
        <InputLabel id='y-axis-metrics-select-label'>
          {t('BUBBLE_CHART.Y_AXIS_METRICS')}
        </InputLabel>
        <Select
          labelId='y-axis-metrics-select-label'
          label='Y Axis metrics'
          data-testid='y-axis-metrics-select-label'
          value={props?.chartOptions?.yAxisTitle}
          onChange={(event) => {
            setYAxisMetricProperties('yAxisType', event.target.value);
          }}
        >
          {props?.chartOptions?.xAxisTitle !== bubbleChartFrequency && (
            <MenuItem value={bubbleChartFrequency}>{t('FREQUENCY')}</MenuItem>
          )}
          {props?.chartOptions?.xAxisTitle !== bubbleChartduration && (
            <MenuItem value={bubbleChartduration}>{t('FILTER.DURATION')}</MenuItem>
          )}
          {props?.yAxisMetricList?.length > 0 &&
            props?.yAxisMetricList?.map((option, index) => (
              <MenuItem key={index} value={option.name}>
                {getBubbleMetricName(option)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl fullWidth size='small' error={yAxisStatisticsError}>
        <InputLabel id='y-axis-statistics-select-label'>
          {t('BUBBLE_CHART.Y_AXIS_STATISTICS')}
        </InputLabel>
        <Select
          labelId='y-axis-statistics-select-label'
          label='Y Axis statistics'
          data-testid='y-axis-statistics-select-label'
          value={props?.chartOptions?.yAxisStatistic}
          onChange={(event) => {
            setBubbleChartProperties('yAxisStatistic', event.target.value);
            event ? setYAxisStatisticsError(false) : setYAxisStatisticsError(true);
          }}
        >
          {bubbleStatistics?.length > 0 &&
            bubbleStatistics?.map((option, index) => (
              <MenuItem key={index} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
        </Select>
        {yAxisStatisticsError && (
          <FormHelperText>
            <DashboardTypography typographyClass='title-12-400'>
              {t('BUBBLE_CHART.Y_AXIS_STATISTICS_ERROR')}
            </DashboardTypography>
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

ConfigureBubbleChart.propTypes = {
  chartOptions: PropTypes.object,
  setChartOptions: PropTypes.func,
  fetchBubbleChartData: PropTypes.func,
  bubbleChartData: PropTypes.array,
  bubbleMetricList: PropTypes.array,
  setBubbleMetricList: PropTypes.func,
  yAxisMetricList: PropTypes.array,
  setYAxisMetricList: PropTypes.func
};

export default ConfigureBubbleChart;
