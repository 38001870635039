import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import './AppListCard.scss';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatDate } from '../../utils/utils';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';

const AppListCard = (props) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActionButtonMenu = () => {
    setAnchorEl(null);
  };

  const onEditAppClick = () => {
    closeActionButtonMenu();
    props.editApp(props.appDetails.id);
  };

  const onDeleteAppClick = () => {
    closeActionButtonMenu();
    props.handleDeleteApp(props.appDetails.id);
  };

  return (
    <Card className='list-card'>
      <CardContent className='app-card-content'>
        <div onClick={() => props.navigateToDashboard(props.appDetails.id)} className='card-items '>
          <div className='app-details'>
            <span className='list-view-icon'>
              {props.isAppsLoading ? (
                <Skeleton variant='circular' width={40} height={40} />
              ) : (
                <SpaceDashboardOutlinedIcon />
              )}
            </span>
            <div className='app-name-section'>
              <DashboardTypography typographyClass={'title title-16-700'}>
                {props.isAppsLoading ? (
                  <Skeleton variant='text' width={300} />
                ) : (
                  props.appDetails.name
                )}
              </DashboardTypography>

              <DashboardTypography typographyClass={'description title-14-600 text-overflow-35rem'}>
                {props.isAppsLoading ? (
                  <Skeleton variant='text' width={150} />
                ) : (
                  props.appDetails.description
                )}
              </DashboardTypography>
            </div>
          </div>
        </div>
        <div onClick={() => props.navigateToDashboard(props.appDetails.id)} className='card-items'>
          <DashboardTypography typographyClass={'modified-date title-12-600'}>
            {props.isAppsLoading ? (
              <Skeleton variant='text' width={150} />
            ) : (
              <>
                {t('APP.DATE_MODIFIED')} {formatDate(props.appDetails.modifiedAt)}
              </>
            )}
          </DashboardTypography>
        </div>
        {props.isAppsLoading ? null : (
          <div>
            <IconButton
              variant='plain'
              color='neutral'
              size='sm'
              data-testid='chart-widget-menu'
              onClick={handleClick}
              className='action-menu-button'
            >
              <MoreVertIcon></MoreVertIcon>
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={closeActionButtonMenu}>
              <MenuItem className='menu-item' onClick={() => onEditAppClick()}>
                <EditOutlinedIcon className='action-button-icon' />
                Edit
              </MenuItem>
              <MenuItem className='menu-item' onClick={() => onDeleteAppClick()}>
                {' '}
                <DeleteOutlinedIcon className='action-button-icon' />
                Delete
              </MenuItem>
            </Menu>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

AppListCard.propTypes = {
  appDetails: PropTypes.object,
  editApp: PropTypes.func,
  handleDeleteApp: PropTypes.func,
  navigateToDashboard: PropTypes.func,
  isAppsLoading: PropTypes.bool
};

export default AppListCard;
