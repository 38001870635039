import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './DashboardTemplate.scss';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import { Button, Popover, Skeleton, ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';
import { button } from '../shared/Themes/ButtonTheme';

const DashboardTemplate = (props) => {
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedwidget, setSelectedwidget] = useState([]);
  const [isWidgetMenuOpen, SetIsWidgetMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);

  useEffect(() => {
    if (props?.dashboardTemplates) {
      setSelectedTemplate(props?.dashboardTemplates[0]?.id);
    }
  }, [props?.dashboardTemplates]);

  const setDashboardTemplate = (event, templateId) => {
    setSelectedTemplate(templateId);
    props?.setDashboardTemplateId('dashboardTemplateId', templateId);
    event.stopPropagation();
  };

  const selectWidgetTemplate = (event, widgets) => {
    setSelectedwidget(widgets);
    SetIsWidgetMenuOpen(true);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const closeWidgetMenu = () => {
    SetIsWidgetMenuOpen(false);
    setAnchorEl(false);
  };

  return (
    <div className='dashboard-template-container'>
      <DashboardTypography typographyClass={'title-12-600'}>
        {t('DASHBOARD_TEMPLATE.TEMPLATES')}
      </DashboardTypography>
      <div>
        {props.isLoading
          ? Array.from(new Array(3)).map((_, index) => (
              <div key={index} className='template-card skeleton-card'>
                <Skeleton variant='text' width='70%' height={30} />
                <Skeleton variant='rectangular' width='100%' height={50} />
                <Skeleton
                  variant='rectangular'
                  width='50%'
                  height={30}
                  style={{ marginTop: '10px' }}
                />
              </div>
            ))
          : props?.dashboardTemplates?.length > 0 &&
            props?.dashboardTemplates?.map((template) => (
              <div
                key={template?.id}
                className={
                  selectedTemplate === template?.id ? 'selected-template-card' : 'template-card'
                }
              >
                <DashboardTypography
                  typographyClass={'title-14-600 text-overflow-60 display-inline-block'}
                >
                  {template?.name}
                </DashboardTypography>

                <div className='template-description'>
                  <DashboardTypography
                    title={template?.description}
                    typographyClass={'title-14-400 text-overflow-100 display-inline-block'}
                  >
                    {template?.description}
                  </DashboardTypography>
                </div>
                <div className='template-widgets-container'>
                  <ThemeProvider theme={button}>
                    <Button
                      variant='outlined'
                      onClick={(e) => setDashboardTemplate(e, template?.id)}
                      className={
                        selectedTemplate === template?.id
                          ? 'selected-template-button'
                          : 'select-template-button'
                      }
                    >
                      {selectedTemplate === template?.id
                        ? t('SELECTED')
                        : t('DASHBOARD_TEMPLATE.SELECT_TEMPLATE')}
                    </Button>
                  </ThemeProvider>
                  {template?.widgetNames?.length > 0 && (
                    <div className='template-widget'>
                      <DashboardTypography
                        typographyClass={'template-title-14-400 text-overflow-34'}
                      >
                        <span className='template-widget-count'>
                          {template?.widgetNames?.length}
                        </span>
                        <span onClick={(e) => selectWidgetTemplate(e, template?.widgetNames)}>
                          {t('WIDGETS')}
                        </span>
                      </DashboardTypography>
                    </div>
                  )}
                  <Popover
                    id='basic-menu'
                    open={isWidgetMenuOpen}
                    onClose={closeWidgetMenu}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                  >
                    <div className='widget-list'>
                      <ul>
                        {selectedwidget?.map((widget, index) => (
                          <li key={index}>
                            <DashboardTypography typographyClass={'title-14-400 widget-list'}>
                              {widget}
                            </DashboardTypography>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Popover>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

DashboardTemplate.propTypes = {
  setDashboardTemplateId: PropTypes.func,
  dashboardTemplates: PropTypes.array,
  isLoading: PropTypes.bool
};

export default DashboardTemplate;
