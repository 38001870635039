import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material';
import '../../styles/FiltersLayout.scss';
import { CASE_STATUSES } from '../../utils/Constants.js';
import ThemeMode from '../themeMode/themeMode.js';

const CompletedCasesFilter = (props) => {
  const { t } = useTranslation();
  const [completedCasesFilter, setCompletedCasesFilter] = useState({
    filterType: 'completedCase',
    name: [],
    isCaseCompleted: false
  });

  useEffect(() => {
    props.setIsValid(true);
    if (props?.filterObj && props?.filterObj?.name) {
      setCompletedCasesFilter(props.filterObj);
    }
  }, []);

  useEffect(() => {
    props.handleFilterChange(completedCasesFilter);
  }, [completedCasesFilter]);

  const handleCaseStatusChange = (selectedCaseStatus) => {
    setCompletedCasesFilter((prevCompletedCasesFilterData) => ({
      ...prevCompletedCasesFilterData,
      isCaseCompleted: selectedCaseStatus
    }));
  };

  return (
    <FormControl fullWidth size='small'>

      <InputLabel id='filter-task-label'>
        {t('FILTER.CASE_STATUS')}
      </InputLabel>

      <Select
        labelId='filter-task-label'
        label={t('FILTER.CASE_STATUS')}
        id='filter_case_status_label'
        fullWidth
        key='filter_case_status'
        data-testid='filter_case_status_select'
        style={{ backgroundColor: '#F9F9Fc' }}
        value={completedCasesFilter.isCaseCompleted}
        onChange={(event) => {
          handleCaseStatusChange(event.target.value);
        }}
      >
        <ThemeMode></ThemeMode>
        {CASE_STATUSES.map((caseStatus) => (
          <MenuItem key={caseStatus.value} value={caseStatus.value}>
            {t('FILTER.' + caseStatus.key)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CompletedCasesFilter.propTypes = {
  projectId: PropTypes.string,
  filterObj: PropTypes.object,
  handleFilterChange: PropTypes.func,
  setIsValid: PropTypes.func
};
export default CompletedCasesFilter;
