import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';
import { Button, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import chartImage from '../../../../../assets/thumbnails/generic_placeholder.svg';
import './WidgetPlaceholder.scss';

const WidgetPlaceholder = (props) => {
  const { chartId, handleClickOpen, isLoading } = props;
  const isDashboardEditMode = useSelector((state) => state.dashboard.isDashboardEditMode);
  const { t } = useTranslation();

  const nonEditableTemplate = (
    <div className='initial-grid'>
      <div className='chart-thumbnail'>
        {isLoading ? (
          <Skeleton variant='rectangular' width={400} height={150} />
        ) : (
          <img
            src={chartImage}
            alt='chart thumbnail'
            className='non-editable-chart-image'
            data-testid='chart-thumbnail'
            height='280px'
          />
        )}
      </div>
      <div className='non-editable-chart-header'>
        {isLoading ? null : <InfoOutlinedIcon className='info-icon' />}
        <div>{isLoading ? <Skeleton variant='text' width={150} /> : t('NO_RESULTS')}</div>
      </div>
      <div>
        {isLoading ? (
          <Skeleton variant='text' width={400} />
        ) : (
          <p>{t('CHECK_CHART_SETTING_EDIT_MODE')}</p>
        )}
      </div>
    </div>
  );

  const editableTemplate = (
    <div className='initial-grid'>
      <div className='chart-thumbnail'>
        {isLoading ? (
          <Skeleton variant='rectangular' width={300} height={110} />
        ) : (
          <img
            src={chartImage}
            alt='chart thumbnail'
            data-testid='chart-thumbnail'
            height='110px'
          />
        )}
      </div>
      <div className='chart-header'>
        {isLoading ? null : <InfoOutlinedIcon className='info-icon' />}
        <div className='add-datapoints'>
          {isLoading ? <Skeleton width={150} /> : t('ADD_DATAPOINTS')}
        </div>
      </div>
      <div>
        {isLoading ? (
          <Skeleton variant='rectangular' width={300} height={40} />
        ) : (
          <Button
            variant='outlined'
            color='primary'
            sx={{
              boxShadow: 'unset',
              borderColor: '#041AB9',
              backgroundColor: '#fff',
              color: '#041AB9',
              borderRadius: '8px',
              textTransform: 'unset',
              padding: '10px 14px 10px 14px !important',
              height: '40px',
              width: '86px',
              minWidth: 'unset',
              ':hover': {
                boxShadow: 'unset'
              }
            }}
            onClick={() => {
              handleClickOpen(chartId);
            }}
            data-testid='edit-chart-button'
            role='edit-chart-button'
            startIcon={<ModeEditOutlineOutlinedIcon sx={{ color: '#041AB9' }} />}
          >
            {t('EDIT')}
          </Button>
        )}
      </div>
    </div>
  );

  return !isDashboardEditMode ? (
    <div data-testid='non-editable-template'>{nonEditableTemplate}</div>
  ) : (
    <div className='edit-template-container' data-testid='edit-template'>
      {editableTemplate}
    </div>
  );
};

WidgetPlaceholder.propTypes = {
  chartId: PropTypes.string,
  handleClickOpen: PropTypes.func,
  isLoading: PropTypes.bool
};

export default WidgetPlaceholder;
