import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RECENT, OLDEST, A_Z, Z_A } from '../../utils/Constants';
import { setSortByModifiedDate, setSortByName } from '../../Redux/slices/sortSlice';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import '../../styles/AppListSort.scss';

const AppListSort = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sortOptions = useSelector((state) => state.sorting);
  return (
    <React.Fragment>

      <Menu
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.onSortMenuClose}
        PaperProps={{
          style: {
            borderRadius: '6px ',
            color: ' #667085',
            fontWeight: 'bold',
            border: '1px solid #E0E2E7'
          }
        }}
        data-testid='sort-menu'
      >
        <MenuItem disabled={true} sx={{ fontSize: 'small', width: 'auto' }}>{t('APP.MODIFIED_DATE')}</MenuItem>
        <MenuItem className={sortOptions.sortType === RECENT ? 'selected-sort-option' : ''} sx={{ justifyContent: 'space-between' }}
          data-testid='recent' onClick={() => dispatch(setSortByModifiedDate(RECENT))} >
          {t('APP.RECENT')}
          {sortOptions.sortType === RECENT && <CheckIcon fontSize='25px' />}
        </MenuItem>

        <MenuItem className={sortOptions.sortType === OLDEST ? 'selected-sort-option' : ''} sx={{ justifyContent: 'space-between' }}
          data-testid='oldest' onClick={() => dispatch(setSortByModifiedDate(OLDEST))}>
          {t('APP.OLDEST')}
          {sortOptions.sortType === OLDEST && <CheckIcon fontSize='25px' />}
        </MenuItem>

        <MenuItem disabled={true} sx={{ fontSize: 'small', width: 'auto' }}>{t('APP.NAME')}</MenuItem>
        <MenuItem className={sortOptions.sortType === A_Z ? 'selected-sort-option' : ''} sx={{ justifyContent: 'space-between' }}
          data-testid='sort-by-desc' onClick={() => dispatch(setSortByName(A_Z))}>
          <span> A <ArrowForwardIcon fontSize='25px' /> Z</span>
          {sortOptions.sortType === A_Z && <CheckIcon fontSize='25px' />}
        </MenuItem>

        <MenuItem className={sortOptions.sortType === Z_A ? 'selected-sort-option' : ''} sx={{ justifyContent: 'space-between' }}
          data-testid='sort-by-asc' onClick={() => dispatch(setSortByName(Z_A))}>
          <span> Z <ArrowForwardIcon fontSize='25px' /> A</span>
          {sortOptions.sortType === Z_A && <CheckIcon fontSize='25px' />}
        </MenuItem>
      </Menu>

    </React.Fragment >
  );
};

AppListSort.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  onSortMenuClose: PropTypes.func
};

export default AppListSort;
