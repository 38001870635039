import ApiService from '../../services/ApiService';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import '../../styles/AddNewApp.css';
import { toast } from 'react-toastify';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.css';
import '../../styles/FiltersLayout.scss';
import { useDispatch } from 'react-redux';
import { setVariantList } from '../../Redux/slices/variantListSlice';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import { MultiSelect } from 'primereact/multiselect';

const VariantFilter = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [variantFilters, setVariantFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState({
    variantIds: [],
    filterType: 'variants',
    name: [],
    isExcluded: false
  });

  // To set max Height for dropdown section for multiple data source
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
        width: 250
      }
    }
  };

  useEffect(() => {
    fetchVariants();
    if (props.filterObj && props.filterObj.variantIds) {
      setSelectedVariant(props.filterObj);
    }
    if (props.filterObj && props.filterObj.variantIds && props.filterObj.variantIds.length > 0) {
      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  }, []);
  useEffect(() => {
    props.handleFilterChange(selectedVariant);
  }, [selectedVariant]);

  const fetchVariants = async () => {
    const requestBody = {
      search: null,
      filterPageNo: '1',
      filterPageSize: '100',
      filterSortOrder: 'ASC'
    };
    try {
      setLoading(true);
      const response = await ApiService.getVariantFilter(
        requestBody,
        props.workgroupId,
        props.projectId
      );
      setVariantFilters(
        response.map((variant) => ({
          name: `Variant ${variant.variantName}`,
          value: variant.variantId
        }))
      );
      dispatch(setVariantList(response));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t('FILTER.FAILED_TO_FETCH_VARIENTS'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };

  const handleFilterChange = (value) => {
    // eslint-disable-next-line no-debugger
    debugger;
    setSelectedVariant((prevSelectedVariant) => ({
      ...prevSelectedVariant,
      variantIds: value
    }));
    props.handleFilterChange(selectedVariant);
    if (value && value.length > 0) {
      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  };

  const onShow = () => {
    const selectAllCheckbox = document.querySelector('.p-multiselect-select-all-label');
    selectAllCheckbox?.after(`${t('FILTER.SELECT_ALL')}`);
  };

  return (
    <FormControl fullWidth size='small'>
      <label id='filter_variant_label'>
        <DashboardTypography id='filter-type-label' typographyClass={'title-14-500'}>
          {t('VARIANTS')}
        </DashboardTypography>
      </label>
      <MultiSelect
        value={selectedVariant.variantIds}
        onChange={(e) => handleFilterChange(e.value)}
        options={variantFilters}
        placeholder={t('SELECT')}
        optionLabel='name'
        maxSelectedLabels={3}
        onShow={onShow}
        id='select-filter-type'
        className='select-filter-type'
      />

      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </FormControl>
  );
};
VariantFilter.propTypes = {
  handleFilterChange: PropTypes.func,
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  filterObj: PropTypes.object,
  setIsValid: PropTypes.func
};
export default VariantFilter;
