import ApiService from '../../services/ApiService';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import '../../styles/AddNewApp.css';
import { toast } from 'react-toastify';
import LoadingSpinner from '../loader/LoadingSpinner';
import '../../styles/LoadingSpinner.css';
import '../../styles/FiltersLayout.scss';
import { MultiSelect } from 'primereact/multiselect';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';

const TaskFilter = (props) => {
  const { t } = useTranslation();
  const [taskFilters, setTaskFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTask, setSelectedTask] = useState({
    taskIds: [],
    filterType: 'task',
    name: [],
    isExcluded: false
  });

  useEffect(() => {
    fetchTasks();
    if (props.filterObj && props.filterObj.taskIds) {
      setSelectedTask(props.filterObj);
    }
    if (props.filterObj && props.filterObj.taskIds && props.filterObj.taskIds.length > 0) {
      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  }, []);

  useEffect(() => {
    props.handleFilterChange(selectedTask);
  }, [selectedTask]);

  const fetchTasks = async () => {
    const requestBody = {
      search: null,
      filterPageNo: '1',
      filterPageSize: '100',
      filterSortOrder: 'ASC'
    };
    try {
      setLoading(true);
      const response = await ApiService.getTaskFilter(
        requestBody,
        props.workgroupId,
        props.projectId
      );
      setTaskFilters(
        response.map((task) => ({
          label: `${task.name}`,
          value: task.id
        }))
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t('FILTER.FAILED_TO_FETCH_TASK_ERROR'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };

  const handleFilterChange = (value) => {
    setSelectedTask((prevSelectedTask) => ({
      ...prevSelectedTask,
      taskIds: value
    }));

    if (value && value.length > 0) {
      props.setIsValid(true);
    } else {
      props.setIsValid(false);
    }
  };

  const onShow = () => {
    const selectAllCheckbox = document.querySelector('.p-multiselect-select-all-label');
    selectAllCheckbox?.after(`${t('FILTER.SELECT_ALL')}`);
  };

  return (
    <FormControl fullWidth size='small'>
      <label id='filter_task_label'>
        <DashboardTypography id='filter-type-label' typographyClass={'title-14-500'}>
          {t('TASKS')}
        </DashboardTypography>
      </label>

      <MultiSelect
        value={selectedTask.taskIds}
        onChange={(e) => handleFilterChange(e.value)}
        options={taskFilters}
        placeholder={t('SELECT')}
        optionLabel='label'
        maxSelectedLabels={3}
        onShow={onShow}
        id='select-filter-type'
        className='select-filter-type'
      />

      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </FormControl>
  );
};
TaskFilter.propTypes = {
  handleFilterChange: PropTypes.func,
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  filterObj: PropTypes.object,
  setIsValid: PropTypes.func
};
export default TaskFilter;
