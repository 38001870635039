import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate, generatePath } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import '../../styles/DashboardLayout.css';
import '../../styles/AppsLayout.scss';
import { useStyles } from '../../styles/appsViewStyles';
import AddNewApp from './AddNewApp';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog/DeleteConfirmationDialog';
import AppCard from './AppCard';
import AppListCard from './AppsListCard';
import { useSelector } from 'react-redux';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import noAppsIllustration from '../../assets/thumbnails/noAppsIllustration.svg';

const AppsGridView = (props) => {
  const navigate = useNavigate();
  const isGridView = useSelector((state) => state.apps.isGridView);
  const { t } = useTranslation();
  const [dashboardAllData, setAllDashboardData] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [appToDelete, setAppToDelete] = useState(null);
  const [addNewAppOpen, setAddNewAppOpen] = useState(false);
  const [appToEdit, setAppToEdit] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const data = await chunk(props.allApps, 4);
      setAllDashboardData([...data]);
    })();
  }, [props.allApps]);

  const editApp = (appId) => {
    const appEdit = props.allApps.find((app) => app.id === appId);
    setAppToEdit(appEdit);
    setAddNewAppOpen(true);
  };

  const createApp = (app) => {
    props.handleSave(app);
  };

  const updateApp = (app) => {
    props.updateApp(app);
  };

  const closeAppNewModal = () => {
    setAddNewAppOpen(false);
    setAppToEdit(null);
  };

  const handleDeleteApp = (appId) => {
    setAppToDelete(appId);
    setConfirmationOpen(true);
  };

  const deleteApp = () => {
    props.deleteApp(appToDelete);
    setAppToDelete(null);
    setConfirmationOpen(false);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const chunk = async (arr, size) =>
    Array.from({ length: Math.ceil(arr?.length / size) }, (v, i) =>
      arr?.slice(i * size, i * size + size)
    );

  const navigateToDashboard = (selectedAppId) => {
    const routePattern = '/Workgroups/:workgroupId/Apps/:appId';
    const params = {
      workgroupId: props.workgroupId,
      appId: selectedAppId
    };

    const generatedUrl = generatePath(routePattern, params);
    const urlWithQueryParams = `${generatedUrl}?${new URLSearchParams({
      workgroupName: encodeURIComponent(props.workgroupName)
    })}`;
    navigate(urlWithQueryParams);
  };

  const renderEmptyApps = () => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: 'flex', md: 'flex', sm: 'flex' },
          justifyContent: 'center',
          alignSelf: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '16px'
        }}
      >
        <div className='create-new-img-container'>
          <img src={noAppsIllustration} alt={noAppsIllustration} className={classes.noAppsImage} />
        </div>
        <DashboardTypography id='empty-apps-msg' typographyClass={'title-18-700'}>
          {t('YOU_DO_NOT_HAVE_ANY_APPS')}
        </DashboardTypography>
        <a className={classes.link}>
          <Typography
            data-testid='no-apps-in-list'
            textAlign='center'
            sx={{
              color: '#041AB9',
              fontSize: 16,
              fontWeight: 600,
              fontFamily: 'Inter',
              cursor: 'pointer'
            }}
          >
            {t('CREATE_NEW')}
          </Typography>
        </a>
      </Box>
    );
  };

  return (
    <div id='app-grid-view' data-testid='AppsGridViewContainer'>
      <Grid container spacing={2}>
        {props.isAppsLoading
          ? Array.from(new Array(8)).map((_, index) =>
              isGridView ? (
                <Grid item xs={3} key={index}>
                  <AppCard isAppsLoading={true}></AppCard>
                </Grid>
              ) : (
                <Grid item xs={12} key={index}>
                  <AppListCard isAppsLoading={true}></AppListCard>
                </Grid>
              )
            )
          : dashboardAllData.length > 0 && (
              <>
                {!isGridView &&
                  dashboardAllData.map((dashboardData, i) =>
                    dashboardData.map((app) => (
                      <Grid key={i} xs={12} item>
                        <AppListCard
                          editApp={editApp}
                          handleDeleteApp={handleDeleteApp}
                          appDetails={app}
                          navigateToDashboard={navigateToDashboard}
                          isAppsLoading={false}
                        ></AppListCard>
                      </Grid>
                    ))
                  )}

                {isGridView &&
                  dashboardAllData.map((dashboardData, i) =>
                    dashboardData.map((app) => (
                      <Grid key={i} xs={3} item>
                        <AppCard
                          editApp={editApp}
                          handleDeleteApp={handleDeleteApp}
                          appDetails={app}
                          navigateToDashboard={navigateToDashboard}
                          isAppsLoading={false}
                        ></AppCard>
                      </Grid>
                    ))
                  )}

                {addNewAppOpen && (
                  <AddNewApp
                    open={addNewAppOpen}
                    handleClose={closeAppNewModal}
                    handleSave={createApp}
                    projects={props.projects}
                    totalApps={props.totalApps}
                    appToEdit={appToEdit}
                    updateApp={updateApp}
                    createApp={createApp}
                  />
                )}

                {confirmationOpen && (
                  <DeleteConfirmationDialog
                    open={confirmationOpen}
                    onClose={handleConfirmationClose}
                    onDeleteConfirm={deleteApp}
                    dialogTitle={t('APP.DELETE_APP')}
                    deleteConfirmationMessage={t('APP.DELETE_APP_CONFIRMATION')}
                    deleteConfirmationNote={t('APP.DELETE_APP_NOTE')}
                  ></DeleteConfirmationDialog>
                )}
              </>
            )}
        {dashboardAllData?.length === 0 && !props.isAppsLoading && renderEmptyApps()}
      </Grid>
    </div>
  );
};

AppsGridView.propTypes = {
  deleteApp: PropTypes.func,
  allApps: PropTypes.array,
  workgroupName: PropTypes.string,
  workgroupId: PropTypes.string,
  totalApps: PropTypes.array,
  projects: PropTypes.array,
  handleSave: PropTypes.func,
  updateApp: PropTypes.func,
  isAppsLoading: PropTypes.bool
};

export default AppsGridView;
