import React, { useEffect, useState } from 'react';
import darkModeLoGo from '../../assets/thumbnails/darkModeLogo.svg';
import { AppBar, Avatar, Button, Menu, MenuItem, Tooltip } from '@mui/material';
import './HeaderComponent.scss';
import DashboardTypography from './DashboardTypography/DashboardTypography';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Link, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '../../utils/KeycloakWrapper';
import ApiService from '../../services/ApiService';
import { ToasterError } from '../../utils/toaster';
import { removeGlobalFiltersFromSessionStorage } from '../../utils/GlobalFilterUtils';
import packageJSON from '../../../package.json';

const HeaderComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const [profilePhoto, setProfilePhoto] = useState();
  const [formData] = useState({
    firstName: keycloak ? keycloak.idTokenParsed?.given_name : '',
    lastName: keycloak ? keycloak.idTokenParsed?.family_name : ''
  });
  const open = Boolean(anchorEl);
  const globalFilterKey = 'globalFilters';

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (keycloak) {
          await keycloak.updateToken();
          const userData = await ApiService.getUserProfileDetails();
          if (userData.logo) {
            setProfilePhoto(userData.logo);
          }
        }
      } catch (error) {
        ToasterError(t('FAILED_USER_PROFILE_FETCH_API'));
      }
    };

    if (keycloak) {
      fetchUserData();
    }
  }, [initialized, keycloak]);

  const handleLogout = () => {
    if (keycloak) {
      removeGlobalFiltersFromSessionStorage(globalFilterKey);
      keycloak.logout();
    }
  };

  const openProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeProfileMenu = () => {
    setAnchorEl(false);
  };

  return (
    <AppBar className='app-bar' position='static'>
      <Link className='logo-image' to='/'>
        <img className='header-logo' src={darkModeLoGo} alt={'iGrafx Logo'} />
      </Link>
      <div>
        <Button id='profile-button' onClick={openProfileMenu}>
          <Tooltip title={`${formData.firstName} ${formData.lastName}`} placement='bottom-start'>
            <div data-testid='icon-avatar' onClick={openProfileMenu}>
              <Avatar
                src={profilePhoto}
                alt={`${formData.firstName} ${formData.lastName}`}
                className='icon-avatar'
              />
            </div>
          </Tooltip>
        </Button>
        <Menu
          id='profile-menu-items'
          anchorEl={anchorEl}
          open={open}
          onClose={closeProfileMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          data-testid='profile-menu'
        >
          <MenuItem
            onClick={() => {
              navigate('/userinfo');
            }}
            data-testid='Profile'
          >
            <PermIdentityIcon className='profile-icon' id='profile-icon' />
            <DashboardTypography id='profile-menu' typographyClass={'title-14-500'}>
              {t('PROFILE')}
            </DashboardTypography>
          </MenuItem>

          <MenuItem onClick={() => handleLogout()} data-testid='logout-btn'>
            {' '}
            <LogoutIcon className='profile-icon' id='logout-icon' />
            <DashboardTypography id='logout-menu' typographyClass={'title-14-500'}>
              {t('LOG_OUT')}
            </DashboardTypography>
          </MenuItem>

          <MenuItem className='version-menu' data-testid='version-lbl'>
            <DashboardTypography id='version-menu' typographyClass={'title-12-500'}>
              {t('VERSION')} {packageJSON.version}
            </DashboardTypography>
          </MenuItem>
        </Menu>
      </div>
    </AppBar>
  );
};

export default HeaderComponent;
