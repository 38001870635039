import { createTheme } from '@mui/material/styles';

const tabTheme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          borderRadius: '20px !important',
          padding: '0px !important'
        },
        indicator: {
          backgroundColor: '#EEEEFD',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderRight: 'solid 1px #C5C7CE !important',
          '&.Mui-selected': {
            backgroundColor: '#EEEEFD !important',
            color: '#0400B9 !important',
          },
        },
      },
    },
  },
});

export default tabTheme;