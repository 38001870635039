import { FormControl, Grid, InputLabel, MenuItem, Select, Tab, Tabs, TextField, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../styles/DashboardLayout.css';
import { OPERATORS, TIME_SCALE } from '../../utils/Constants';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useStyles } from '../../styles/periodFilterStyles';
import TaskFilter from './TaskFilter';
import LinkFilter from './LinkFilter';
import '../../styles/FiltersLayout.scss';
import { checkIsValid, getScope } from '../../utils/filter';
import tabTheme from '../shared/Themes/TabTheme';

const DurationFilter = (props) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState();
  const [numericValueError, setNumericValueError] = useState(false);
  const classes = useStyles();
  const [selectedTask, setSelectedTask] = useState({
    taskIds: [],
    filterType: 'task',
    name: []
  });

  const [selectedLinks, setSelectedLinks] = useState({
    linkIds: [],
    filterType: 'link',
    name: []
  });

  const [durationFilter, setDurationFilter] = useState({
    filterType: 'duration',
    scope: 'CASE',
    name: [],
    operator: '>=',
    value: 0,
    scale: 'DAY'
  });

  useEffect(() => {
    setSelectedTab(1);
    if (props?.filterObj && props?.filterObj?.scope) {
      setDurationFilter(props.filterObj);
      if (props?.filterObj?.scope === 'TASKS') {
        setSelectedTab(0);
        setSelectedTask((prevSelectedTask) => ({
          ...prevSelectedTask,
          taskIds: props.filterObj.taskIds
        }));
      }
      if (props?.filterObj?.scope === 'LINKS') {
        setSelectedTab(2);
        setSelectedLinks((prevSelectedLinks) => ({
          ...prevSelectedLinks,
          linkIds: props.filterObj.linkIds
        }));
      }
    }
    props.setIsValid(
      checkIsValid(
        props.filterObj?.scope === 'TASKS' ? durationFilter.taskIds : durationFilter.linkIds,
        props.filterObj?.value >= 0,
        props.filterObj?.scope === 'CASE' ? true : false
      )
    );
  }, []);

  useEffect(() => {
    const filteredDurationFilter = {
      ...durationFilter,
      ...(durationFilter.scope === 'TASKS' && {
        linkIds: undefined
      }),
      ...(durationFilter.scope === 'LINKS' && {
        taskIds: undefined
      }),
      ...(durationFilter.scope === 'CASE' && {
        taskIds: undefined,
        linkIds: undefined
      })
    };
    props.handleFilterChange(filteredDurationFilter);
  }, [durationFilter]);

  const handleTabChange = (event, newValue) => {
    const selectedScope = getScope(newValue);
    setDurationFilter((prevDurationFilterData) => ({
      ...prevDurationFilterData,
      scope: selectedScope
    }));
    setSelectedTab(newValue);
    props.setIsValid(
      checkIsValid(
        selectedScope === 'TASKS' ? durationFilter.taskIds : durationFilter.linkIds,
        durationFilter.value >= 0,
        selectedScope === 'CASE' ? true : false
      )
    );
  };

  const handleTaskFilterChange = (value) => {
    setDurationFilter((prevDurationFilterData) => ({
      ...prevDurationFilterData,
      taskIds: value ? value.taskIds : []
    }));
    props.handleFilterChange(durationFilter);
    props.setIsValid(checkIsValid(value.taskIds, durationFilter.value >= 0, false));
  };

  const handleLinkFilterChange = (value) => {
    setDurationFilter((prevDurationFilterData) => ({
      ...prevDurationFilterData,
      linkIds: value ? value.linkIds : []
    }));
    props.handleFilterChange(durationFilter);
    props.setIsValid(checkIsValid(value.linkIds, durationFilter.value >= 0, false));
  };

  const handleNumericValueChange = (event) => {
    setDurationFilter((prevDurationFilterData) => ({
      ...prevDurationFilterData,
      value: event.target.value
    }));
    if (event.target.value !== '' && Number(event.target.value) >= 0) {
      setNumericValueError(false);
      props.setIsValid(
        checkIsValid(
          durationFilter.scope === 'TASKS' ? durationFilter.taskIds : durationFilter.linkIds,
          Number(event.target.value) >= 0,
          durationFilter.scope === 'CASE' ? true : false
        )
      );
    } else {
      setNumericValueError(true);
      props.setIsValid(false);
    }
  };

  const handleScaleChange = (selectedScale) => {
    setDurationFilter((prevDurationFilterData) => ({
      ...prevDurationFilterData,
      scale: selectedScale
    }));
  };

  const handleOperatorChange = (selectedOperator) => {
    setDurationFilter((prevDurationFilterData) => ({
      ...prevDurationFilterData,
      operator: selectedOperator
    }));
  };

  const durationPickers = () => {
    return (
      <>
        <Grid container spacing={2} sx={{ marginTop: '16px' }}>
          <Grid item xs={3}>
            <FormControl fullWidth size='small'>
              <InputLabel id='duration-operator_label'>
                {t('FILTER.OPERATOR')}
              </InputLabel>

              <Select
                value={durationFilter.operator}
                onChange={(e) => {
                  handleOperatorChange(e.target.value);
                }}
                sx={{ height: '40px' }}
                fullWidth
                data-testid='duration-operator-select'
                labelId='duration-operator_label'
                label={t('FILTER.OPERATOR')}
                id='duration-operator-label'
              >
                {OPERATORS.map((operator) => (
                  <MenuItem key={operator.key} value={operator.key}>
                    {operator.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size='small'>
              <TextField
                label={t('FILTER.PLEASE_ENTER_NUMERIC_VALUE_TO_COMPARE')}
                id='numeric-value'
                error={numericValueError}
                helperText={numericValueError ? t('FILTER.NUMERIC_VALUE_ERROR_MESSAGE') : null}
                value={durationFilter.value}
                onChange={handleNumericValueChange}
                type='number'
                size='small'
                data-testid='numeric-value-to-compare'
                inputProps={{ min: '0' }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size='small'>
              <InputLabel id='duration-scale_label'>
                {t('FILTER.TIME_SCALE')}
              </InputLabel>
              <Select
                value={durationFilter.scale}
                onChange={(e) => {
                  handleScaleChange(e.target.value);
                }}
                fullWidth
                data-testid='duration-scale-select'
                labelId='duration-scale_label'
                label={t('FILTER.TIME_SCALE')}
              >
                {TIME_SCALE.map((timeScale) => (
                  <MenuItem key={timeScale.key} value={timeScale.key}>
                    {timeScale.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <div className={classes.tabsContainer}>
      <ThemeProvider theme={tabTheme}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          sx={{
            padding: '4px',
            margin: '2%',
            border: '1px solid rgb(224, 226, 231)'
          }}
        >
          <Tab
            label='Tasks'
            className={selectedTab === 0 ? classes.selectedTab : ''}
          />
          <Tab
            label='Cases'
            className={selectedTab === 1 ? classes.selectedTab : ''}
          />
          <Tab
            label='Links'
            className={selectedTab === 2 ? classes.selectedTab : ''}
          />
        </Tabs>
      </ThemeProvider>
      <div>
        {selectedTab === 0 && (
          <div className={classes.tabContent}>
            <TaskFilter
              workgroupId={props.workgroupId}
              projectId={props.projectId}
              filterObj={selectedTask}
              handleFilterChange={handleTaskFilterChange}
              setIsValid={props.setIsValid}
            />
            {durationPickers()}
          </div>
        )}
        {selectedTab === 1 && <div className={classes.tabContent}>{durationPickers()}</div>}
        {selectedTab === 2 && (
          <div className={classes.tabContent}>
            <LinkFilter
              workgroupId={props.workgroupId}
              projectId={props.projectId}
              handleFilterChange={handleLinkFilterChange}
              filterObj={selectedLinks}
              setIsValid={props.setIsValid}
            />
            {durationPickers()}
          </div>
        )}
      </div>
    </div>
  );
};

DurationFilter.propTypes = {
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  filterObj: PropTypes.object,
  handleFilterChange: PropTypes.func,
  setIsValid: PropTypes.func
};
export default DurationFilter;
