import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { themeModes } from '../../utils/Constants';
import { darkThemeModeBackgroundColors, lightThemeModeBackgroundColors } from './idBackgroundColorMapping';
import { darkThemeModeIconColors, lightThemeModeIconColors } from './idIconColorMapping';
const ThemeMode = () => {
  const themeMode = useSelector(state => state?.themeMode?.themeMode);

  useEffect(() => {
    setBackground(themeMode === themeModes?.LIGHT_THEME ? lightThemeModeBackgroundColors : darkThemeModeBackgroundColors);
    setIconColor(themeMode === themeModes?.LIGHT_THEME ? lightThemeModeIconColors : darkThemeModeIconColors);
    setInputLabelStyle(themeMode === themeModes?.LIGHT_THEME ? lightThemeModeIconColors : darkThemeModeIconColors);
  }, [themeMode]);

  const setBackground = (backgroundColors) => {
    if (backgroundColors) {
      for (const id of Object.keys(backgroundColors)) {
        const elements = document.querySelectorAll(`#${id}`);
        if (elements && elements.length) {
          elements.forEach(element => {
            if (id === 'spinner') {
              element.style.background = `rgba(255, 255, 255, 0.4)`;
            } else if ((id === 'workgroup-cards' || id === 'apps-card' || id === 'empty-screen-image-container' || id === 'workgroup-header' || id === 'delete-confirmation' || id === 'pagination-component' || id === 'view-as-table-dialog-title-container' || id === 'view-as-table-dialog-content-container' || id === 'view-as-table-container' || id === 'filter-modal' || id === 'add-dashboard' || id === 'delete-dashboard' || id === 'widget-actions-menu' || id === 'pagination-component' || id === 'view-as-table-dialog-title-container' || id === 'view-as-table-dialog-content-container' || id === 'view-as-table-container' || id === 'duplicate-widget' || id === 'data-warning' || id === 'create-theme' || id === 'variants-selection-panel-container' || id === 'process-model-settings-panel-container' || id === 'process-model-settings-panel-dialog-container' || id === 'pagination-component' || id === 'view-as-table-dialog-title-container' || id === 'view-as-table-dialog-content-container' || id === 'view-as-table-container' || id === 'add-dashboard' || id === 'delete-dashboard' || id === 'widget-actions-menu' || id === 'pagination-component' || id === 'view-as-table-dialog-title-container' || id === 'view-as-table-dialog-content-container' || id === 'view-as-table-container') && themeMode === themeModes?.DARK_THEME) {
              element.style.background = backgroundColors[id];
              element.style.border = 'unset';
            } else if (id === 'widget-config-header' || id === 'existing-chart-card' || id === 'add-filter' || id === 'add-global-filter-container' || id === 'add-global-filter-container-selected' || id === 'dashboard-card' || id === 'add-filter' || id === 'add-global-filter-container' || id === 'add-global-filter-container-selected' || id === 'text-widget-container' || id === 'user-first-name-label' || id === 'user-first-name-field' || id === 'user-last-name-field' || id === 'user-email-field' || id === 'image-info-container' || id === 'exclude-switch' || id === 'filters-container-tabs' || id === 'filters-main-container') {
              element.style.background = backgroundColors[id];
              element.style.border = `solid 1px ${backgroundColors[id + '-border']}`;
            } else if (id === 'widget-config-fields' || id === 'right-dashboard-config-section' || id === 'global-filter-container' || id === 'image-widegt-setting-panel') {
              element.style.background = backgroundColors[id];
              element.style.borderLeft = `solid 1px ${backgroundColors[id + '-border']}`;
            } else if (id === 'dashboard-header-container' || id === 'profile-page-header') {
              element.style.background = backgroundColors[id];
              element.style.borderBottom = `solid 1px ${backgroundColors[id + '-border']}`;
            } else if (id === 'file-upload-container') {
              element.style.background = backgroundColors[id];
              element.style.border = `dashed 3px ${backgroundColors[id + '-border']}`;
            } else if (id === 'select-filter-type' || id === 'filters-container-tab') {
              element.style.background = backgroundColors[id];
              element.style.border = `solid 1px ${backgroundColors[id + '-border']}`;
              element.style.color = `${backgroundColors[id + '-color']}`;
            } else {
              element.style.background = backgroundColors[id];
            }
          });
        }
      }
    }
    const polygons = document.querySelectorAll('polygon');
    if (polygons.length > 0) {
      polygons.forEach((polygon) => {
        polygon.style.fill = 'transparent';
      });
    }
  };

  const setIconColor = (iconColors) => {
    if (iconColors) {
      for (const id of Object.keys(iconColors)) {
        const elements = document.querySelectorAll(`#${id}`);
        if (elements && elements.length) {
          elements.forEach(element => {
            element.style.color = iconColors[id];
          });
        }
      }

      const selectElements = document.querySelectorAll(`.MuiSelect-icon`);
      selectElements.forEach(selectElement => {
        selectElement.style.color = iconColors['clear-search'];
      });
    }
  };

  const setInputLabelStyle = (colorMode) => {
    const elements = document.querySelectorAll(`.MuiFormLabel-root`);
    elements.forEach(element => {
      element.style.color = colorMode['input'];
    });
  };
};



export default ThemeMode;
