import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../styles/DashboardLayout.scss';
import '../../../styles/DashboardSection.scss';
import { Grid, Tab, Tabs, Tooltip, IconButton, Menu, MenuItem, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setIsAddNewDashboardBtnClicked,
  setIsDeleteDashboardBtnClicked,
  setIsEditDashboardNameBtnClicked,
  setSelectedDashboardData,
  setIsFavouriteDashboardBtnClicked,
  setIsDuplicateDashboardBtnClicked
} from '../../../Redux/slices/allDashboardSlice';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { DeleteOutline } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DashboardTypography from '../../shared/DashboardTypography/DashboardTypography';
import ThemeMode from '../../themeMode/themeMode';
import { ThemeProvider } from '@emotion/react';
import { outlinedDashed } from '../../shared/Themes/ButtonTheme';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const DashboardSection = (props) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const { dashboards, selectedDashboardObject, onChangeDashboard } = props;
  const [anchorDashboardActions, setAnchorDashboardActions] = useState(null);
  const openEditDeleteActionModal = Boolean(anchorDashboardActions);
  const dispatch = useDispatch();
  const [selectedDashboardForAction, setSelectedDashboardForAction] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [filteredDashboards, setFilteredDashboards] = useState(props.dashboards);

  useEffect(() => {
    setSelectedDashboard(selectedDashboardObject);
  }, [props.selectedDashboardObject]);

  useEffect(() => {
    setFilteredDashboards(getFilteredDashboards(selectedTab));
  }, [props.dashboards]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setFilteredDashboards(getFilteredDashboards(newValue));
  };

  const getFilteredDashboards = (selectedTabValue) => {
    return selectedTabValue === 1
      ? dashboards.filter((dashboard) => dashboard.isFavourite)
      : dashboards;
  };

  const openDashboardActionsMenu = (event, dashboard) => {
    setAnchorDashboardActions(event.currentTarget);
    event.stopPropagation();
    setSelectedDashboardForAction(dashboard);
  };

  const closeDashboardActionsMenu = (event) => {
    setAnchorDashboardActions(null);
    event.stopPropagation();
  };

  const handleEditDashboardName = (event) => {
    event.stopPropagation();
    dispatch(setIsEditDashboardNameBtnClicked(true));
    dispatch(setSelectedDashboardData(selectedDashboardForAction));
    closeDashboardActionsMenu(event);
  };

  const handleDeleteDashboard = (event) => {
    event.stopPropagation();
    dispatch(setIsDeleteDashboardBtnClicked(true));
    dispatch(setSelectedDashboardData(selectedDashboardForAction));
    closeDashboardActionsMenu(event);
  };

  const handleDashboardChange = (selectedNewDashboard) => {
    setSelectedDashboard(selectedNewDashboard);
    onChangeDashboard(selectedNewDashboard);
  };

  const handleIsFavouriteDashboardChange = (event, dashboard) => {
    event.stopPropagation();
    dispatch(setSelectedDashboardData(dashboard));
    dispatch(setIsFavouriteDashboardBtnClicked(true));
  };

  const createDuplicateDashboard = (event) => {
    event.stopPropagation();
    dispatch(setIsDuplicateDashboardBtnClicked(true));
    dispatch(setSelectedDashboardData(selectedDashboardForAction));
    closeDashboardActionsMenu(event);
  };

  return (
    <div className='right-sectionContainer' data-testid='right-section' id='right-dashboard-section'>
      <DashboardTypography typographyClass={'title-14-700'}>
        {t('DASHBOARDS')}
      </DashboardTypography>
      <ThemeProvider theme={outlinedDashed}>
        <Button
          variant='outlined'
          sx={{ width: '100%', height: 'auto', marginTop: '20px' }}
          data-testid='add-new-dashboard-button'
          onClick={() => dispatch(setIsAddNewDashboardBtnClicked(true))}
        >
          <AddIcon
            sx={{
              color: '#041AB9',
              marginRight: '10px',
              cursor: 'pointer',
              fontWeight: 'bolder'
            }}
          />
          {t('NEW_DASHBOARD')}
        </Button>
      </ThemeProvider>
      {/* </div> */}

      {/* <div className='tabsContainerBox'>
        <div
          className='add-dashboard-button'
          data-testid='add-new-dashboard-button'
          onClick={() => dispatch(setIsAddNewDashboardBtnClicked(true))}
        >
          <AddIcon id='add-dashboard-button-icon'
            sx={{
              color: '#041AB9',
              marginRight: '10px',
              cursor: 'pointer',
              fontWeight: 'bolder'
            }}
          />
          <DashboardTypography id={'add-dashboard-button-title'} typographyClass={'title-14-600'}>{t('NEW_DASHBOARD')}</DashboardTypography>

        </div>
      </div> */}
      {/* <div className='dashboardTabsContainer' data-testid='dashboard-tabs-container'>
        <Grid item lg={12} xl={12} className='genericfilter-grid'>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            sx={{
              border: '1px solid #E0E2E7',
              backgroundColor: '#fff',
              borderRadius: '6px',
              padding: '4px',
              minHeight: 'auto'
            }}
          >
            <Tab
              label={t('ALL')}
              data-testid='all-dashboards-tab'
              sx={{
                borderRadius: '6px',
                backgroundColor: '#fff',
                color: '#667085',
                paddingLeft: '12px',
                paddingRight: '12px',
                paddingTop: '6px',
                marginRight: '3px',
                textTransform: 'unset',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 500
              }}
              className={selectedTab === 0 ? 'selectedTab' : ''}
            />
            <Tab
              label={t('DASHBOARD.FAVOURITE')}
              data-testid='favourite-dashboards-tab'
              sx={{
                borderRadius: '6px',
                backgroundColor: '#fff',
                color: '#667085',
                paddingLeft: '12px',
                paddingRight: '12px',
                paddingTop: '6px',
                marginRight: '3px',
                textTransform: 'unset',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 500
              }}
              className={selectedTab === 1 ? 'selectedTab' : ''}
            />
          </Tabs>
        </Grid>
      </div> */}
      <div className='widgetContainer'>
        {filteredDashboards && filteredDashboards?.length !== 0 ? (
          filteredDashboards.map((dashboard, index) => (
            <div key={index} className='tabsContainerBox cursor-pointer'>
              <div
                data-testid='dashboard-card'
                className={`dashboard-action-row ${selectedDashboard?.id === dashboard.id
                  ? 'dashboard-action-row-selected'
                  : 'dashboard-action-row-not-selected'
                  }`}
                onClick={() => handleDashboardChange(dashboard)}
              >
                <DashboardTypography title={dashboard.name} typographyClass={'title-14-600 text-overflow-60'}>{dashboard.name}</DashboardTypography>

                <div className='dashboard-action-buttons'>
                  <div>
                    <IconButton
                      variant='plain'
                      color='neutral'
                      size='sm'
                      data-testid='toggle-favourite-dashboard-button'
                      onClick={(event) => {
                        handleIsFavouriteDashboardChange(event, dashboard);
                      }}
                    >
                      {dashboard.isFavourite === false ? (
                        <Tooltip title={t('DASHBOARD.MARK_AS_FAVOURITE')} arrow>
                          <StarBorderIcon />
                        </Tooltip>
                      ) : (
                        <Tooltip title={t('DASHBOARD.REMOVE_FROM_FAVOURITES')} arrow>
                          <StarIcon className='favourite-icon' />
                        </Tooltip>
                      )}
                    </IconButton>
                    <IconButton
                      variant='plain'
                      color='neutral'
                      size='sm'
                      data-testid='dashboard-actions-menu'
                      onClick={(event) => openDashboardActionsMenu(event, dashboard)}
                    >
                      <Tooltip title={t('DASHBOARD.DASHBOARD_ACTIONS')} arrow>
                        <MoreVertIcon className='widget-icon' />
                      </Tooltip>
                    </IconButton>
                    <Menu
                      id='edit-delete-menu'
                      anchorEl={anchorDashboardActions}
                      open={openEditDeleteActionModal}
                      onClose={(event) => closeDashboardActionsMenu(event)}
                    >
                      <MenuItem
                        onClick={(event) => {
                          handleEditDashboardName(event);
                        }}
                        data-testid='edit-dashboard-menu'
                        className='edit-delete-menu'
                      >
                        <BorderColorIcon className='edit-delete-menu-icon' />
                        {t('DASHBOARD.EDIT_DASHBOARD_NAME')}
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          createDuplicateDashboard(event);
                        }}
                        data-testid='duplicate-dashboard-menu'
                        className='edit-delete-menu'
                      >
                        <ContentCopyIcon className='edit-delete-menu-icon' />
                        {t('DASHBOARD.DUPLICATE_DASHBOARD')}
                      </MenuItem>
                      <MenuItem
                        className='edit-delete-menu'
                        onClick={(event) => handleDeleteDashboard(event)}
                        data-testid='delete-dashboard-menu'
                      >
                        <DeleteOutline className='edit-delete-menu-icon' />
                        {t('DASHBOARD.DELETE_DASHBOARD')}
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div data-testid='empty-dashboard-label' className='no-dashboard-found'>
            <DashboardTypography id={'no-dashboard'} typographyClass={'title-16-500'}>
              {selectedTab === 0
                ? t('DASHBOARD.NO_DASHBOARDS')
                : t('DASHBOARD.NO_FAVORITE_DASHBOARDS')}
            </DashboardTypography>
          </div>
        )}
      </div>
    </div>
  );
};

DashboardSection.propTypes = {
  dashboards: PropTypes.array,
  selectedDashboardObject: PropTypes.object,
  onChangeDashboard: PropTypes.func
};

export default DashboardSection;
