import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, TextField, InputLabel, Select, MenuItem, IconButton, FormControl, ThemeProvider, Button } from '@mui/material';
import '../../../../../styles/DuplicateWidget.scss';
import DashboardTypography from '../../../../shared/DashboardTypography/DashboardTypography';
import CloseIcon from '@mui/icons-material/Close';
import { button } from '../../../../shared/Themes/ButtonTheme';

const DuplicateWidget = (props) => {
  const { t } = useTranslation();
  const [widgetNameError, setWidgetNameError] = useState(false);
  const [widgetName, setWidgetName] = useState();
  const {
    handleClose,
    duplicateWidget,
    dashboardData,
    dashboards,
    openPopup,
    selectedWidgetTitle
  } = props;
  const [dashboardId, setDashboardId] = useState(null);

  useEffect(() => {
    setWidgetName('Copy of ' + selectedWidgetTitle);
    setDashboardId(dashboardData?.id);
  }, [openPopup]);

  const handleDuplicateWidget = () => {
    if (widgetName && !widgetNameError) {
      duplicateWidget(dashboardId, widgetName);
    } else {
      widgetName ? setWidgetNameError(false) : setWidgetNameError(true);
    }
  };

  const handleWidgetNameChange = function (value) {
    setWidgetName(value);
  };

  const handleDashboardChange = function (value) {
    setDashboardId(value);
  };

  return (
    <div id='duplicate-widget'>
      <Dialog
        className='add-edit-dialog'
        onClose={handleClose}
        aria-labelledby='add-widget-dialog-title'
        open={true}
        role='dialog'
        PaperProps={{
          sx: {
            height: 'auto',
            width: '300px',
            borderRadius: '10px'
          }
        }}
      >
        <DialogTitle id='duplicate-widget-title'>
          <div>
            <DashboardTypography typographyClass='title-16-600'>
              {t('WIDGET.DUPLICATE_WIDGET')}
            </DashboardTypography>

            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#667085'
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className='dialog-mainSection' id='duplicate-widget-dialog-content-container'>
          <Grid container spacing={2} className='dialogBody-grid'>
            <Grid item xs={12}>
              <FormControl sx={{ marginTop: '8px' }} fullWidth size='small'>
                <InputLabel required id='duplicate-widget-destination-label'>
                  {t('WIDGET.DESTINATION_DASHBOARD')}
                </InputLabel>
                <Select
                  label={t('WIDGET.DESTINATION_DASHBOARD')}
                  labelId='duplicate-widget-destination-label'
                  value={dashboardId}
                  id='dashboard-select'
                  onChange={(event) => {
                    handleDashboardChange(event.target.value);
                  }}
                  data-testid='dashboard-select'
                  size='small'
                >
                  {dashboards &&
                    dashboards.map((dashboard, index) => (
                      <MenuItem key={index} value={dashboard.id}>
                        {dashboard.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size='small'>
                <TextField
                  label={t('WIDGET.WIDGET_NAME')}
                  variant='outlined'
                  required
                  fullWidth
                  key='widgetName'
                  error={widgetNameError}
                  helperText={widgetNameError ? t('WIDGET_NAME_ERROR_MESSAGE') : null}
                  id='chart-label'
                  size='small'
                  value={widgetName}
                  data-testid='widget-name-input'
                  onChange={(event) => {
                    var regex = /^$|^\S+.*/;
                    const labelCheck = regex.test(event.target.value);
                    if (labelCheck) {
                      handleWidgetNameChange(event.target.value);
                      event ? setWidgetNameError(false) : setWidgetNameError(true);
                    }
                  }}
                />
              </FormControl>

            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='padding-left-20' id='duplicate-widget-dialog-actions-container'>
          <Grid container item xs={12}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', gap: '8px', marginBottom: '8px', marginRight: '8px' }}>
              <ThemeProvider theme={button}>
                <Button
                  variant='text'
                  onClick={handleClose}
                  dataTestid='cancel-duplicate-widget'
                >
                  {t('CANCEL')}
                </Button>
              </ThemeProvider>

              <ThemeProvider theme={button}>
                <Button
                  variant='contained'
                  onClick={handleDuplicateWidget}
                  dataTestid='duplicate-widget-button'
                >
                  {t('DASHBOARD.DUPLICATE')}
                </Button>
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DuplicateWidget.propTypes = {
  handleClose: PropTypes.func,
  duplicateWidget: PropTypes.func,
  dashboardData: PropTypes.object,
  dashboards: PropTypes.array,
  selectedWidgetTitle: PropTypes.string,
  openPopup: PropTypes.bool
};

export default DuplicateWidget;
