import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import dashboardStyles from '../../styles/dashboardStyles';
import { useTranslation } from 'react-i18next';
import '../../styles/DashboardLayout.scss';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import { ThemeProvider } from '@emotion/react';
import { button } from '../shared/Themes/ButtonTheme';
import { Button } from '@mui/material';

const ConfirmationDialog = (props) => {
  const { t } = useTranslation();

  return (
    <div id='delete-dashboard'>
      <Dialog
        className='delete-confirmation'
        onClose={props.onClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        maxWidth='md'
      >
        <DialogTitle
          sx={{ m: 0, p: 2, paddingLeft: '25px' }}
          id='customized-dialog-title'
          data-testid='customized-dialog-title'
          style={dashboardStyles.modalThemeTitle}
        >
          <DashboardTypography typographyClass='title-16-600'>
            {t(props.title)}
          </DashboardTypography>

          <IconButton
            aria-label='close'
            onClick={props.onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            id='close-icon'
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            data-testid='confirmation-msg'
          >
            <DashboardTypography typographyClass={'title-16-400'}>{t(props.confirmationMsg)}</DashboardTypography>
          </DialogContentText>
          {props.note && (
            <div className='delete-note' data-testid='confirmation-note'>
              <DashboardTypography typographyClass={'title-14-600'}> {t(props.note)}</DashboardTypography>
            </div>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            paddingBottom: '20px',
            display: 'flex',
            justifyContent: props.isConfirmationForPreconfiguredWidgets
              ? 'flex-end'
              : 'end',
            gap: '10px',
            paddingRight: '24px'
          }}
          id='delete-dashboard-action-container'
        >

          <ThemeProvider theme={button}>
            <Button
              variant='text'
              onClick={props.onClose}
              data-testid='cancel-btn'
            >
              {props.isConfirmationForPreconfiguredWidgets ? t('NO') : t('CANCEL')}
            </Button>
          </ThemeProvider>

          <ThemeProvider theme={button}>
            <Button
              variant='contained'
              onClick={props.onConfirm}
              data-testid='ok-btn'
            >
              {props.isConfirmationForPreconfiguredWidgets ? t('YES') : t('OK')}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  confirmationMsg: PropTypes.string,
  note: PropTypes.string,
  isConfirmationForPreconfiguredWidgets: PropTypes.bool
};

export default ConfirmationDialog;
