import React from 'react';
import './ProfileHeader.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

const ProfileHeader = (props) => {
  const { t } = useTranslation();
  return (
    <div className='profile-header'>

      <Tooltip title={t('BACK')} arrow>
        <Link to='/'>
          <ArrowBackIosIcon
            sx={{ color: '#8C8F9D', fontSize: '18px' }}
            data-testid='close-profile-dialog'
          />
        </Link>
      </Tooltip>



      <DashboardTypography typographyClass={'title-18-400 profile-header-title'}>{t('PROFILE')}</DashboardTypography>

    </div>
  );
};
export default ProfileHeader;
