import React, { useEffect, useState } from 'react';
import { Button, Avatar, Grid, ThemeProvider } from '@mui/material';
import { useKeycloak } from '../../utils/KeycloakWrapper';
import { useTranslation } from 'react-i18next';
import Languagedropdown from '../languagedropdown/languagedropdown';
import ApiService from '../../services/ApiService';
import { toast } from 'react-toastify';
import { I18N_LANGUAGES } from '../../utils/Constants';
import { ToasterError, ToasterSuccess } from '../../utils/toaster';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import './ProfilePageDialog.scss';
import ProfileHeader from './ProfileHeader';
import HeaderComponent from '../shared/HeaderComponent';
import { button } from '../shared/Themes/ButtonTheme';

const ProfilePageDialog = () => {
  const { t } = useTranslation();
  const { keycloak, initialized } = useKeycloak();
  const [formData] = useState({
    firstName: keycloak ? keycloak.idTokenParsed?.given_name : '',
    lastName: keycloak ? keycloak.idTokenParsed?.family_name : '',
    email: keycloak ? keycloak.idTokenParsed?.email : ''
  });
  const [profilePhoto, setProfilePhoto] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [userData, setUserData] = useState(null);

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };

  const handleSaveClick = async () => {
    try {
      const updatedUserData = { ...userData, language: selectedLanguage };
      await ApiService.saveUserProfileDetails(updatedUserData);
      const languageDisplayName =
        I18N_LANGUAGES.find((lang) => lang.value === selectedLanguage)?.label || selectedLanguage;
      ToasterSuccess(t('LANGUAGE_CHANGED') + languageDisplayName);
    } catch (error) {
      ToasterError(t('FAILED_USER_PROFILE_SAVE_API'));
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (keycloak.authenticated) {
          await keycloak.updateToken();

          const userProfileData = await ApiService.getUserProfileDetails();

          if (userProfileData.logo) {
            setProfilePhoto(userProfileData.logo);
          }
          setUserData(userProfileData);
        }
      } catch (error) {
        toast.error(t('FAILED_USER_PROFILE_FETCH_API'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: 'light'
        });
      }
    };

    if (keycloak) {
      fetchUserData();
    }
  }, [initialized, keycloak]);

  return (
    <div className='profile-container'>
      <div className='header'>
        <HeaderComponent></HeaderComponent>
      </div>

      <div className='sub-header'>
        <ProfileHeader></ProfileHeader>
      </div>

      <div className='body'>
        <div data-testid='profile-dialog'>
          <div id='profile-page-container'>
            <div className='profile-content-container'>
              <div className='profile-icon-container'>
                <div data-testid='avatar' className='profile-icon-inner-container'>
                  <Avatar src={profilePhoto} className='profile-image' />
                </div>
              </div>
              <Grid container spacing={2} className='profile-attributes' data-testid='profileAttributes'>
                <Grid item container md={6}>
                  <Grid item md={12}>
                    <DashboardTypography typographyClass='title-12-400 first-last-name-title'>{t('FIRST_NAME')}</DashboardTypography>
                  </Grid>
                  <Grid item md={12}>
                    <DashboardTypography typographyClass='title-16-400 first-last-name-value'>{formData.firstName}</DashboardTypography>
                  </Grid>
                </Grid>
                <Grid container item md={6}>
                  <Grid item md={12}>
                    <DashboardTypography typographyClass='title-12-400 first-last-name-title'>{t('LAST_NAME')}</DashboardTypography>
                  </Grid>
                  <Grid item md={12}>
                    <DashboardTypography typographyClass='title-16-400 first-last-name-value'>{formData.lastName}</DashboardTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item md={12}>
                <Grid item md={12}>
                  <DashboardTypography id='user-email' typographyClass='title-14-400 email'>{t('EMAIL')}</DashboardTypography>
                </Grid>
                <Grid item md={12}>
                  <DashboardTypography typographyClass='title-16-400 first-last-name-value'>{formData.email}</DashboardTypography>
                </Grid>
              </Grid>
              {/* <div className='profile-language-dropdown'> */}
              <Grid container item md={12}>
                <Grid item md={12}>
                  <div className='language'>
                    <Languagedropdown
                      selectedLanguage={selectedLanguage}
                      onLanguageChange={handleLanguageChange}
                    />
                  </div>
                </Grid>
              </Grid>

              {/* </div> */}

              <Grid container item md={12}>
                <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ThemeProvider theme={button}>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleSaveClick}
                      data-testid='save-profile-button'
                    >
                      {t('SAVE')}
                    </Button>
                  </ThemeProvider>
                </Grid>
              </Grid>

            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default ProfilePageDialog;
