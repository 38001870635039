import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import '../../../styles/DashboardLayout.scss';
import '../DeleteConfirmationDialog/DeleteConfirmationDialog.scss';
import ThemeMode from '../../themeMode/themeMode';
import DashboardTypography from '../DashboardTypography/DashboardTypography';
import { ThemeProvider } from '@emotion/react';
import { button } from '../Themes/ButtonTheme';
import { Button } from '@mui/material';

const DeleteConfirmationDialog = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog
        className='delete-confirmation'
        onClose={props.onClose}
        open={props.open}
        maxWidth='md'
        data-testid='delete-confirmation-dialog'
      >
        <DialogTitle
          data-testid='delete-confirmation-title'
          id='delete-confirmation-title'
        >
          <DashboardTypography id='delete-app-title' typographyClass={'title-16-600'}>{props.dialogTitle}</DashboardTypography>
          <ThemeMode></ThemeMode>
          <IconButton
            data-testid='close'
            onClick={props.onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent id='delete-confirmation-content'>
          <DialogContentText
            data-testid='delete-confirmation-message'
          >
            <DashboardTypography id='delete-confirmation-message' typographyClass={'title-16-400'}> {props.deleteConfirmationMessage}</DashboardTypography>
          </DialogContentText>
          <div className='delete-note' id='delete-note'>
            <DashboardTypography id='delete-confirmation-note' typographyClass={'title-16-600'}>{props.deleteConfirmationNote}</DashboardTypography>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            paddingBottom: '1rem',
            display: 'flex',
            justifyContent: 'end',
            paddingLeft: '8px',
            paddingRight: '24px',
            gap: '8px'
          }}
          id='delete-confirmation'
        >

          <ThemeProvider theme={button}>
            <Button
              variant='text'
              data-testid='cancel-btn'
              onClick={props.onClose}
            >
              {t('CANCEL')}
            </Button>
          </ThemeProvider>

          <ThemeProvider theme={button}>
            <Button
              variant='contained'
              onClick={props.onDeleteConfirm}
              data-testid='delete-btn'
            >
              {t('DELETE')}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </div>

  );
};

DeleteConfirmationDialog.propTypes = {
  dialogTitle: PropTypes.string,
  deleteConfirmationMessage: PropTypes.string,
  deleteConfirmationNote: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onDeleteConfirm: PropTypes.func
};

export default DeleteConfirmationDialog;