import axios from 'axios';
import { ENDPOINTURL } from '../utils/ApiConstants';
import {
  AVAILABLE_OPTIONS_WITH_DISTRIBUTION,
  DATA_SETS,
  allAppThemes,
  allSavedApps,
  allWorkgroupsPageable,
  chartDataForDistribution,
  chartDataForLineChart,
  chartDataForMetric,
  chartDataForPieChart,
  createNewAppResponse,
  createNewThemeResponse,
  dashboardData,
  selectedWorkgroupAppsPageable,
  selectedWorkgroupProjects,
  getAppDetailsResponse,
  taskFilters,
  variantFilters,
  linkFilters,
  caseFilters,
  totalApps,
  filterTypesList,
  mockRoleDimentions,
  BusinessRuleFilterOptions,
  mockConformanceModels,
  mockProcessModel,
  mockStatisticData,
  mockGlobalFilterData,
  SavedFilterOptions,
  userProfileDetails,
  variantStatisticsForProcessModelView,
  mockDashboardTemplates,
  workgroupRepositories
} from '../utils/MockData';

const ApiService = {
  /**
   * Api to get all data sets
   */

  getFieldList: async (workgroupId, projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return DATA_SETS;
    } else {
      try {
        const url = `${process.env.REACT_APP_API_BASE_URL}${ENDPOINTURL.API_HUBCAP}${ENDPOINTURL.API_WORKGROUP}/${workgroupId}${ENDPOINTURL.API_PROJECTS}/${projectId}${ENDPOINTURL.FIELD_LIST}`;
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  getAvailableOptions: async (type, workgroupId, projectId, dimensionId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return AVAILABLE_OPTIONS_WITH_DISTRIBUTION;
    } else {
      try {
        let url = `${process.env.REACT_APP_API_BASE_URL}${ENDPOINTURL.API_HUBCAP}${ENDPOINTURL.API_WORKGROUP}/${workgroupId}${ENDPOINTURL.API_PROJECTS}/${projectId}${ENDPOINTURL.AVAILABLE_OPTIONS}${type}`;
        if (dimensionId) {
          url += `${ENDPOINTURL.DIMENSION_ID}${dimensionId}`;
        }
        const response = await axios.get(url);

        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  getChartData: async (requestBody, workgroupId, projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      switch (requestBody['type']) {
        case 'newCasesInTimeNumeric':
          return chartDataForMetric;
        case 'newCasesInTimeBarchart':
          return chartDataForDistribution;
        case 'newCasesInTimeLine':
          return chartDataForLineChart;
        case 'newCasesInTimePiechart':
          return chartDataForPieChart;
      }
    } else {
      try {
        const url = `${process.env.REACT_APP_API_BASE_URL}${ENDPOINTURL.API_HUBCAP}${ENDPOINTURL.API_WORKGROUP}/${workgroupId}${ENDPOINTURL.API_PROJECTS}/${projectId}${ENDPOINTURL.CHART_DATA}`;
        requestBody['editURL'] = ENDPOINTURL.EDIT_URL;
        const response = await axios.post(url, requestBody);
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  /**
   * Api to fetch all created apps
   */
  getAllWorkgroups: async (pageNumber, pageSize, searchQuery) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return allWorkgroupsPageable;
    } else {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_WORKGROUP +
            '?pageNo=' +
            pageNumber +
            '&pageSize=' +
            pageSize +
            '&search=' +
            searchQuery
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  /**
   * Api to get all DASHBOARD data based on
   * @param requestBody which contains charts data,name,mdification date and description.
   */
  createDashboard: async (requestBody, appId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return dashboardData;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_DASHBOARD +
            '?appId=' +
            appId,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  /**
   * Api to update DASHBOARD data based on
   * @param requestBody which contains charts data,name,mdification date and description.
   */
  updateDashboard: async (requestBody) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return dashboardData;
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_DASHBOARD +
            '/' +
            requestBody.id,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  /**
   * Api to get all Project based on workgroupId
   */
  getProjectsByWorkgroup: async (ID) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return selectedWorkgroupProjects;
    } else {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_WORKGROUP +
            '/' +
            ID +
            ENDPOINTURL.DATASOURCES
        );

        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  /**
   * Api to get all apps based on workgroupId
   */

  getAppsByWorkgroup: async (requestBody, workgroupId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return selectedWorkgroupAppsPageable;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_WORKGROUP +
            '/' +
            workgroupId +
            ENDPOINTURL.API_APPLICATIONS,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  //http://localhost:8080/api/v1/hubcap/workgroups/workgroupId/applications?search=second&pageNo=0&pageSize=1

  /**
   * Api to get all Project based on workgroupId
   */

  createNewApp: async (requestBody) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return createNewAppResponse;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_APPLICATIONS,
          requestBody
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response?.status === 409) {
          throw error;
        } else {
          throw new Error(error.message);
        }
      }
    }
  },
  /**
   * Api to get all DASHBOARD data based on ID
   */
  getDashboardById: async (dashboardId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return dashboardData;
    } else {
      try {
        var url =
          process.env.REACT_APP_API_BASE_URL +
          ENDPOINTURL.API_HUBCAP +
          ENDPOINTURL.API_DASHBOARD +
          '/' +
          dashboardId;
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  /**
   * Api to get all DASHBOARD data based on ID
   */
  getDashboardByApplicationId: async (appId, pageNumber, pageSize, searchQuery) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return allSavedApps;
    } else {
      try {
        var url =
          process.env.REACT_APP_API_BASE_URL +
          ENDPOINTURL.API_HUBCAP +
          ENDPOINTURL.API_APPLICATIONS +
          '/' +
          appId +
          ENDPOINTURL.API_DASHBOARD +
          '?pageNo=' +
          pageNumber +
          '&pageSize=' +
          pageSize;
        if (searchQuery) {
          url = url + '&search=' + searchQuery;
        }
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  getTotalApps: async (id) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return totalApps.appNames;
    } else {
      try {
        var url =
          process.env.REACT_APP_API_BASE_URL +
          ENDPOINTURL.API_HUBCAP +
          ENDPOINTURL.API_WORKGROUP +
          '/' +
          id +
          ENDPOINTURL.API_APPLICATIONS +
          '/names';
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  /**
   * Api to fetch all app themes
   */
  getAppThemes: async (appId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return allAppThemes;
    } else {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_APPLICATIONS +
            '/' +
            appId +
            ENDPOINTURL.APP_THEMES
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  deleteTheme: async (themeId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return null;
    } else {
      try {
        const response = await axios.delete(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_APPLICATIONS +
            ENDPOINTURL.APP_THEMES +
            '/' +
            themeId
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  deleteApp: async (appId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return null;
    } else {
      try {
        const response = await axios.delete(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_APPLICATIONS +
            '/' +
            appId
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  deleteDashboard: async (dashboardId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return null;
    } else {
      try {
        const response = await axios.delete(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_DASHBOARD +
            '/' +
            dashboardId
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  createTheme: async (appId, requestBody) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return createNewThemeResponse;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_APPLICATIONS +
            '/' +
            appId +
            ENDPOINTURL.APP_THEMES,
          requestBody
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response?.status === 409) {
          throw error;
        } else {
          throw new Error(error.message);
        }
      }
    }
  },

  updateTheme: async (themeId, requestBody) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return null;
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_APPLICATIONS +
            ENDPOINTURL.APP_THEMES +
            '/' +
            themeId,
          requestBody
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response?.status === 409) {
          throw error;
        } else {
          throw new Error(error.message);
        }
      }
    }
  },

  getApplicationDetails: async (appId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return getAppDetailsResponse;
    } else {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_APPLICATIONS +
            '/' +
            appId
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  getFilterTypes: async (requestBody, workgroupId, projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return filterTypesList;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_WORKGROUP +
            '/' +
            workgroupId +
            ENDPOINTURL.API_PROJECT +
            '/' +
            projectId +
            ENDPOINTURL.API_FILTERS,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  getTaskFilter: async (requestBody, workgroupId, projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return taskFilters;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_WORKGROUP +
            '/' +
            workgroupId +
            ENDPOINTURL.API_PROJECT +
            '/' +
            projectId +
            ENDPOINTURL.API_FILTERS +
            ENDPOINTURL.API_TASKS,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  getVariantFilter: async (requestBody, workgroupId, projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return variantFilters;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_WORKGROUP +
            '/' +
            workgroupId +
            ENDPOINTURL.API_PROJECT +
            '/' +
            projectId +
            ENDPOINTURL.API_FILTERS +
            ENDPOINTURL.API_VARIANTS,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  getBusinessRuleFilter: async (requestBody, workgroupId, projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return BusinessRuleFilterOptions;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_WORKGROUP +
            '/' +
            workgroupId +
            ENDPOINTURL.API_PROJECT +
            '/' +
            projectId +
            ENDPOINTURL.API_FILTERS +
            ENDPOINTURL.API_BUSINESS,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  getLinkFilter: async (requestBody, workgroupId, projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return linkFilters;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_WORKGROUP +
            '/' +
            workgroupId +
            ENDPOINTURL.API_PROJECT +
            '/' +
            projectId +
            ENDPOINTURL.API_FILTERS +
            ENDPOINTURL.API_LINK,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  getCaseFilter: async (requestBody, workgroupId, projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return caseFilters;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_WORKGROUP +
            '/' +
            workgroupId +
            ENDPOINTURL.API_PROJECT +
            '/' +
            projectId +
            ENDPOINTURL.API_FILTERS +
            ENDPOINTURL.API_CASE,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  updateApplicationDetails: async (requestBody, appId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return null;
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_APPLICATIONS +
            '/' +
            appId,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  getPeriodFilter: async (requestBody, workgroupId, projectId) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL +
          ENDPOINTURL.API_HUBCAP +
          ENDPOINTURL.API_WORKGROUP +
          '/' +
          workgroupId +
          ENDPOINTURL.API_PROJECT +
          '/' +
          projectId +
          ENDPOINTURL.API_FILTERS +
          ENDPOINTURL.API_PERIOD,
        requestBody
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  getSavedFilter: async (projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return SavedFilterOptions;
    } else {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_PROJECT +
            '/' +
            projectId +
            ENDPOINTURL.API_SAVED_FILTERS
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  getUserProfileDetails: async () => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return userProfileDetails;
    } else {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_AUTHENTICATION
        );

        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  saveUserProfileDetails: async (profileData) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return userProfileDetails;
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_AUTHENTICATION,
          profileData
        );

        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  getDimensions: async (requestBody, workgroupId, projectId, dimensionId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return mockRoleDimentions;
    } else {
      try {
        try {
          const response = await axios.post(
            process.env.REACT_APP_API_BASE_URL +
              ENDPOINTURL.API_HUBCAP +
              ENDPOINTURL.API_WORKGROUP +
              '/' +
              workgroupId +
              ENDPOINTURL.API_PROJECT +
              '/' +
              projectId +
              ENDPOINTURL.API_DIMENSIONS +
              '/' +
              dimensionId,
            requestBody
          );
          return response.data;
        } catch (error) {
          throw new Error(error);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  getConformanceModels: async (requestBody, workgroupId, projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return mockConformanceModels;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_WORKGROUP +
            '/' +
            workgroupId +
            ENDPOINTURL.API_PROJECT +
            '/' +
            projectId +
            ENDPOINTURL.API_FILTERS +
            ENDPOINTURL.API_VALID_MODELS,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  generateProcessModelView: async (requestBody, projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return mockProcessModel;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_PROJECT +
            '/' +
            projectId +
            ENDPOINTURL.API_VIEW,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  getStatisticData: async (workgroupId, projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return mockStatisticData;
    } else {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_WORKGROUP +
            '/' +
            workgroupId +
            ENDPOINTURL.API_PROJECTS +
            '/' +
            projectId +
            ENDPOINTURL.API_STATISTIC
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  getSvgStatisticData: async (requestBody, workgroupId, projectId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return variantStatisticsForProcessModelView;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_WORKGROUP +
            '/' +
            workgroupId +
            ENDPOINTURL.API_PROJECT +
            '/' +
            projectId +
            ENDPOINTURL.API_FILTERS +
            ENDPOINTURL.API_STATISTIC_SVG,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  saveGlobalFilters: async (dashboardId, requestBody) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return mockGlobalFilterData;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_DASHBOARD +
            '/' +
            dashboardId +
            ENDPOINTURL.API_GLOBAL_FILTERS,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  fetchGlobalFilters: async (dashboardId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return mockGlobalFilterData;
    } else {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_DASHBOARD +
            '/' +
            dashboardId +
            ENDPOINTURL.API_GLOBAL_FILTERS
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  deleteGlobalFilters: async (dashboardId, globalFilterId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return null;
    } else {
      try {
        const response = await axios.delete(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_DASHBOARD +
            '/' +
            dashboardId +
            ENDPOINTURL.API_GLOBAL_FILTERS +
            '/' +
            globalFilterId
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  /**
   * Api to toggle dashboard favorite status
   */
  toggleDashboardFavouriteStatus: async (dashboardId, isFavouriteStatus) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return dashboardData;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_DASHBOARD +
            '/' +
            dashboardId +
            ENDPOINTURL.API_FAVOURITE +
            '/' +
            isFavouriteStatus,
          {}
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response?.status === 409) {
          throw error;
        } else {
          throw new Error(error.message);
        }
      }
    }
  },

  updateGlobalFilters: async (dashboardId, requestBody) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return mockGlobalFilterData;
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_DASHBOARD +
            '/' +
            dashboardId +
            ENDPOINTURL.API_GLOBAL_FILTERS,
          requestBody
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  createDuplicateDashboard: async (requestBody, dashboardId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return dashboardData;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_DASHBOARD +
            '/' +
            dashboardId +
            ENDPOINTURL.API_COPY +
            '?appId=' +
            requestBody.appId +
            '&dashboardName=' +
            requestBody.dashboardName,
          {}
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response?.status === 409) {
          throw error;
        } else {
          throw new Error(error.message);
        }
      }
    }
  },

  /**
   * Api to create duplicate widget
   */
  createDuplicateWidget: async (requestBody) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return dashboardData;
    } else {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_DASHBOARD +
            ENDPOINTURL.COPY_WIDGET,
          requestBody
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response?.status === 409) {
          throw error;
        } else {
          throw new Error(error.message);
        }
      }
    }
  },

  addThumbnailToApp: async (appId, requestBody) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return null;
    } else {
      try {
        const url = `${process.env.REACT_APP_API_BASE_URL}${ENDPOINTURL.API_HUBCAP}${ENDPOINTURL.API_APPLICATIONS}/${appId}${ENDPOINTURL.API_THUMBNAIL}`;
        const response = await axios.post(url, requestBody);
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  /**
   * Api to get all existing widgets for selected app
   */
  getAllExistingWidgets: async (appId) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return selectedWorkgroupProjects;
    } else {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_APPLICATIONS +
            '/' +
            appId +
            ENDPOINTURL.API_DASHBOARD +
            ENDPOINTURL.CHART_DATA
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  fetchDashboardTemplates: async () => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return mockDashboardTemplates;
    } else {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            ENDPOINTURL.API_DASHBOARD_TEMPLATES
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  /**
   * Api to get all repositories based on workgroupId
   */
  getRepositoriesByWorkgroup: async (ID) => {
    if (process.env.REACT_APP_USE_DUMMY_DATA === 'true') {
      return workgroupRepositories;
    } else {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL +
            ENDPOINTURL.API_HUBCAP +
            '/' +
            ID +
            ENDPOINTURL.REPOSITORIES
        );

        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};

export default ApiService;
