import { createTheme } from '@mui/material/styles';
import './DashboardLayout.css';

const theme = createTheme();
const dashboardStyles = {
  container: {
    borderTop: '1px solid #D8D8D8',
    display: 'flex',
    flexDirection: 'row',
    height: '36rem'
  },
  dashboardAppContainer: {
    backgroundColor: '#F9F9FC',
    justifyContent: 'center',
    marginTop: '1rem'
  },

  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F9F9FC'
  },

  middleSection: {
    borderRight: '1px solid #D8D8D8',
    padding: theme.spacing(2),
    maxWidth: '40px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF'
  },
  rightSection: {
    padding: '1.2rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF'
  },
  leftContainer: {
    height: '430px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  emptyContainer: {
    textAlign: 'center'
  },
  middleSectionIcons: {
    fontSize: '28px',
    color: '#667085',
    cursor: 'pointer',
    padding: '5px'
  },
  middleSectionIconsActive: {
    backgroundColor: '#EFEFFD',
    borderRadius: '8px'
  },
  iconsOuterContainer: {
    width: '40px',
    height: '40px',
    marginBottom: '20px'
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px'
  },
  tabsContainerBox: {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: 0
  },
  widgetContainer: {
    justifyContent: 'space-between'
  },
  widgetContainerHeading: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  ExistingCharts: {
    cursor: 'pointer',
    paddingLeft: '3rem',
    color: '#041AB9',
    fontSize: '16px !important',
    margin: 'unset',
    fontFamily: 'Inter',
    fontWeight: 500
  },
  chartsDetails: {
    display: 'flex',
    fontFamily: 'Inter',
    fontSize: '16px !important',
    fontweight: 700,
    lineHeight: '24px',
    marginTop: '10px'
  },
  charts: {
    color: '#808B99',
    margin: 'unset',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700
  },
  Widgets: {
    color: '#808B99',
    textTransform: 'unset',
    fontSize: '14px',
    margin: '4px',
    borderRadius: '6px'
  },
  Layouts: {
    cursor: 'pointer',
    color: '#808B99',
    textTransform: 'unset',
    fontSize: '14px',
    margin: '4px',
    borderRadius: '6px'
  },
  selectedTab: {
    color: '#4543AE',
    backgroundColor: '#DEDEFA',
    margin: '4px',
    borderRadius: '6px',
    textTransform: 'unset'
  },
  Blocks: {
    color: '#808B99',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    margin: 'unset'
  },
  blocksDetails: {
    display: 'flex',
    fontFamily: 'Inter',
    fontSize: '16px !important',
    fontweight: 700,
    marginTop: '20px'
  },
  draggableBox: {
    display: 'flex',
    alignItems: 'center',
    width: '215px',
    height: '39px',
    background: '#EFEFFD',
    cursor: 'pointer',
    marginRight: '10px',
    marginTop: '10px',
    paddingLeft: '5%',
    borderRadius: '6px'
  },

  nonDraggableBox: {
    display: 'flex',
    alignItems: 'center',
    width: '215px',
    height: '39px',
    background: '#EFEFFD',
    cursor: 'pointer',
    marginRight: '10px',
    marginTop: '10px',
    paddingLeft: '5%',
    borderRadius: '6px'
  },

  iconContainer: {
    marginRight: '10px'
  },
  iconText: {
    color: 'blue'
  },
  components: {
    fontSize: '16px !important',
    lineHeight: '24px !important',
    fontFamily: 'Open Sans !important',
    paddingLeft: '10% !important'
  },
  emptyScreen: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    fontFamily: 'Inter',
    color: '#333843',
    textAlign: 'center'
  },
  AddComponentsToScreen: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    color: '#667085',
    fontFamily: 'Inter',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      marginLeft: '30px !important'
    }
  },
  AddComponents: {
    backgroundColor: '#3B82F6 !important',
    textTransform: 'unset !important'
  },
  card: {
    height: 420,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  themeheader: {
    display: 'flex',
    paddingLeft: '1.2rem',
    paddingTop: '1rem'
  },
  themeTitle: {
    fontSize: '16px !important',
    fontFamily: 'Open Sans !important',
    paddingLeft: '10% !important'
  },
  themeCards: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5%',
    marginRight: '0.5rem',
    marginLeft: '1rem',
  },
  defaultThemeTitle: {
    margin: 'unset',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#333843',
    marginTop: '1%',
    marginLeft: '7px'
  },

  themeCardsModal: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  defaultThemeFont: {
    color: '#667085',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    marginLeft: '3%',
    marginBottom: '2%'
  },
  modalThemeName: {
    color: '#4D5464',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '2rem'
  },
  modalThemeDescription: {
    color: '#3F455B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    marginTop: '20px'
  },
  modalThemeColorPallete: {
    color: '#667085',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    width: '80px'
  },
  modalShadeColor: {
    color: '#667085',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    border: '1px solid #E0E2E7',
    padding: '8px 6px',
    borderRadius: '3px',
    backgroundColor: '#F9F9FC'
  },
  actionButtons: {
    marginLeft: '100px',
    display: 'flex'
  },
  shadeNameContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '3rem'
  },
  selectColor: {
    width: '20px'
  },
  shadeColorHexCode: {
    width: '83px',
    marginLeft: '10px'
  },
  fontFamilyContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  colorNotSelectedError: {
    color: '#d32f2f',
    fontWeight: 400,
    fontSize: '0.75rem',
    marginBottom: '0rem'
  },
  downloadPdf: {
    paddingLeft: '20%',
    display: 'flex'
  }
};
export default dashboardStyles;
