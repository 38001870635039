import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../../styles/WorkgroupsContainer.scss';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import './WorkgroupsHeader.scss';
import { InputAdornment, TextField } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import theme from '../shared/Themes/InputFieldTheme';
import { Clear, Search } from '@mui/icons-material';

const WorkgroupsHeader = (props) => {
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();

  const searchWorkgroup = (e) => {
    setSearchText(e.target.value);
    props.searchWorkgroup(e.target.value);
  };

  const clearSearch = () => {
    setSearchText('');
    props.searchWorkgroup('');
  };
  return (
    <div className='workgroup-header'>
      <DashboardTypography id={'all-workgroup-text'} typographyClass={'title-16-700'}>{t('ALL_WORKGROUPS')}</DashboardTypography>

      <ThemeProvider theme={theme}>
        <TextField placeholder={t('SEARCH_PLACEHOLDER')}
          onChange={searchWorkgroup}
          value={searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' >
                <Search id='search-icon' />
              </InputAdornment>
            ),
            endAdornment: searchText && (
              <InputAdornment
                position='end'
                onClick={clearSearch}
                style={{ cursor: 'pointer' }}
              >
                <Clear id='clear-search' />
              </InputAdornment>
            ),
          }}
        />
      </ThemeProvider>
    </div>
  );
};

WorkgroupsHeader.propTypes = {
  searchWorkgroup: PropTypes.func
};
export default WorkgroupsHeader;
