import { MenuItem, Select, TextField, FormControl, InputLabel } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/DashboardLayout.css';
import { OPERATORS } from '../../utils/Constants';
import { useStyles } from '../../styles/periodFilterStyles';
import { useTranslation } from 'react-i18next';

const ReworkAndOccurrencesPickers = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <div className={classes.customPeriod}>
        <div className={classes.width48}>
          <FormControl fullWidth size='small'>
            <InputLabel id='duration-operator_label'>
              {t('FILTER.OPERATOR')}
            </InputLabel>

            <Select
              value={props.reworkOccurrencesFilter.operator}
              onChange={(e) => {
                props.handleOperatorChange(e.target.value);
              }}
              fullWidth
              data-testid='occurrences-operator-select'
              labelId='duration-operator_label'
              label={t('FILTER.OPERATOR')}
            >
              {OPERATORS.map((operator) => (
                <MenuItem key={operator.key} value={operator.key}>
                  {operator.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </div>
        <div className={classes.occurrencesField}>
          <TextField
            id='occurrences-value'
            error={props.reworkOccurrencesError}
            helperText={props.reworkOccurrencesError ? props.minNumberErrorMessage : null}
            value={props.reworkOccurrencesFilter.value}
            onChange={props.reworkOccurrenceNumberChange}
            type='number'
            size='small'
            label={props.numericFieldTitle}
            data-testid='value-to-compare'
            inputProps={{ min: props.minNumber }}
          />
        </div>
      </div>
    </>
  );
};

ReworkAndOccurrencesPickers.propTypes = {
  reworkOccurrencesError: PropTypes.bool,
  reworkOccurrencesFilter: PropTypes.object,
  reworkOccurrenceNumberChange: PropTypes.func,
  handleOperatorChange: PropTypes.func,
  numericFieldTitle: PropTypes.string,
  minNumber: PropTypes.string,
  minNumberErrorMessage: PropTypes.string,
  placeholder: PropTypes.string
};

export default ReworkAndOccurrencesPickers;