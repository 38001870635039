import { MenuItem, Select, Tab, Tabs, TextField, FormControl, ThemeProvider, InputLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../styles/DashboardLayout.css';
import { OPERATORS, TASK_TYPES_IN_LINKS } from '../../utils/Constants';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useStyles } from '../../styles/periodFilterStyles';
import TaskFilter from './TaskFilter';
import LinkFilter from './LinkFilter';
import { checkIsValid, getScope } from '../../utils/filter';
import tabTheme from '../shared/Themes/TabTheme';

const MetricFilter = (props) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState();
  const [metricError, setMetricError] = useState(false);
  const classes = useStyles();
  const [selectedTask, setSelectedTask] = useState({
    taskIds: [],
    filterType: 'task',
    name: []
  });

  const [selectedLinks, setSelectedLinks] = useState({
    linkIds: [],
    filterType: 'link',
    name: []
  });

  const [metricFilter, setMetricFilter] = useState({
    filterType: 'metric',
    scope: 'CASE',
    name: [],
    operator: '>=',
    metricId: props.filterObj.customFieldId,
    customFieldName: props.filterObj?.customFieldName,
    metricName: props.filterObj?.customFieldName,
    metricValue: props.filterObj?.metricValue ? props.filterObj?.metricValue : 0,
    linksParameters: {
      bound: 'source',
      linkIds: []
    }
  });

  useEffect(() => {
    setSelectedTab(1);
    if (props?.filterObj && props?.filterObj?.scope) {
      if (props?.filterObj?.scope === 'TASKS') {
        setSelectedTab(0);
        setSelectedTask((prevSelectedTask) => ({
          ...prevSelectedTask,
          taskIds: props.filterObj.taskIds
        }));
      }
      if (props?.filterObj?.scope === 'LINKS') {
        setSelectedTab(2);
        setSelectedLinks((prevSelectedLinks) => ({
          ...prevSelectedLinks,
          linkIds: props.filterObj.linksParameters?.linkIds
        }));
      }
      setMetricFilter(props.filterObj);
    }
    props.setIsValid(
      checkIsValid(
        props?.filterObj?.scope === 'TASKS'
          ? props.filterObj.taskIds
          : props.filterObj.linksParameters?.linkIds,
        props?.filterObj?.metricValue !== '',
        props?.filterObj?.scope === 'CASE' ? true : false
      )
    );
  }, []);

  useEffect(() => {
    const filteredMetricFilter = {
      ...metricFilter,
      ...(metricFilter.scope === 'TASKS' && {
        linksParameters: undefined
      }),
      ...(metricFilter.scope === 'LINKS' && {
        taskIds: undefined
      }),
      ...(metricFilter.scope === 'CASE' && {
        taskIds: undefined,
        linksParameters: undefined
      })
    };
    props.handleFilterChange(filteredMetricFilter);
  }, [metricFilter]);

  const handleTabChange = (event, newValue) => {
    const selectedScope = getScope(newValue);
    setMetricFilter((prevMetricFilterData) => ({
      ...prevMetricFilterData,
      scope: selectedScope
    }));
    setSelectedTab(newValue);
    props.setIsValid(
      checkIsValid(
        selectedScope === 'TASKS' ? metricFilter.taskIds : metricFilter.linksParameters?.linkIds,
        metricFilter.metricValue !== '',
        selectedScope === 'CASE' ? true : false
      )
    );
  };

  const handleTaskFilterChange = (value) => {
    setMetricFilter((prevMetricFilterData) => ({
      ...prevMetricFilterData,
      taskIds: value ? value.taskIds : []
    }));
    props.handleFilterChange(metricFilter);
    props.setIsValid(checkIsValid(value.taskIds, metricFilter.metricValue !== '', false));
  };

  const handleLinkFilterChange = (value) => {
    setMetricFilter((prevMetricFilterData) => ({
      ...prevMetricFilterData,
      linksParameters: {
        bound: prevMetricFilterData.linksParameters?.bound
          ? prevMetricFilterData.linksParameters?.bound
          : 'source',
        linkIds: value ? value.linkIds : []
      }
    }));
    props.handleFilterChange(metricFilter);
    props.setIsValid(checkIsValid(value.linkIds, metricFilter.metricValue !== '', false));
  };

  const handleMetricNumberChange = (event) => {
    setMetricFilter((prevMetricFilterData) => ({
      ...prevMetricFilterData,
      metricValue: event.target.value
    }));
    if (event.target.value !== '') {
      setMetricError(false);
      props.setIsValid(
        checkIsValid(
          metricFilter.scope === 'TASKS'
            ? metricFilter.taskIds
            : metricFilter.linksParameters?.linkIds,
          event.target.value !== '',
          metricFilter.scope === 'CASE' ? true : false
        )
      );
    } else {
      setMetricError(true);
      props.setIsValid(false);
    }
  };

  const handleOperatorChange = (selectedOperator) => {
    setMetricFilter((prevMetricFilterData) => ({
      ...prevMetricFilterData,
      operator: selectedOperator
    }));
  };

  const handleTaskTypeChange = (selectedTaskType) => {
    setMetricFilter((prevMetricFilterData) => ({
      ...prevMetricFilterData,
      linksParameters: {
        bound: selectedTaskType,
        linkIds: prevMetricFilterData.linksParameters?.linkIds
          ? prevMetricFilterData.linksParameters?.linkIds
          : []
      }
    }));
  };

  const metricPickers = () => {
    return (
      <>
        <div className={classes.customPeriod}>
          <FormControl fullWidth size='small' sx={{ marginRight: '16px' }}>
            <InputLabel id='metric-operator-label'>
              {t('FILTER.OPERATOR')}
            </InputLabel>
            <Select
              value={metricFilter.operator}
              onChange={(e) => {
                handleOperatorChange(e.target.value);
              }}
              fullWidth
              data-testid='metric-operator-select'
              labelId='metric-operator-label'
              label={t('FILTER.OPERATOR')}
              id='metric-operator-label'
            >
              {OPERATORS.map((operator) => (
                <MenuItem key={operator.key} value={operator.key}>
                  {operator.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size='small'>
            <TextField
              label={t('FILTER.NUMERIC_VALUE_TO_COMPARE')}
              id='metric-number-value'
              error={metricError}
              helperText={metricError ? t('FILTER.PLEASE_ENTER_NUMERIC_VALUE_TO_COMPARE') : null}
              value={metricFilter.metricValue}
              onChange={handleMetricNumberChange}
              type='number'
              data-testid='metric-value-to-compare'
              size='small'
            />
          </FormControl>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={classes.tabsContainer}>
        <ThemeProvider theme={tabTheme}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            sx={{
              border: '1px solid rgb(224, 226, 231)'
            }}
          >
            <Tab
              label='Tasks'
              className={selectedTab === 0 ? classes.selectedTab : ''}
            />
            <Tab
              label='Cases'
              className={selectedTab === 1 ? classes.selectedTab : ''}
            />
            <Tab
              label='Links'
              className={selectedTab === 2 ? classes.selectedTab : ''}
            />
          </Tabs>
        </ThemeProvider>

        <div>
          {selectedTab === 0 && (
            <div className={classes.tabContent}>
              <TaskFilter
                workgroupId={props.workgroupId}
                projectId={props.projectId}
                filterObj={selectedTask}
                handleFilterChange={handleTaskFilterChange}
                setIsValid={props.setIsValid}
              />
              {metricPickers()}
            </div>
          )}
          {selectedTab === 1 && <div className={classes.tabContent}>{metricPickers()}</div>}
          {selectedTab === 2 && (
            <div className={classes.tabContent}>
              <div className={classes.customPeriod}>
                <FormControl className={classes.metricLinkSelect}>
                  <InputLabel id='source_or_destination_label'>
                    {t('FILTER.SOURCE_OR_DESTINATION')}
                  </InputLabel>
                  <Select
                    value={metricFilter.linksParameters?.bound}
                    onChange={(e) => {
                      handleTaskTypeChange(e.target.value);
                    }}
                    fullWidth
                    data-testid='metric-task-type-select'
                    labelId='metric-task-type_label'
                    label={t('FILTER.SOURCE_OR_DESTINATION')}
                    id='metric-task-type-label'
                    size='small'
                  >
                    {TASK_TYPES_IN_LINKS.map((taskType) => (
                      <MenuItem key={taskType} value={taskType}>
                        {taskType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.occurrencesField}>
                  <LinkFilter
                    workgroupId={props.workgroupId}
                    projectId={props.projectId}
                    handleFilterChange={handleLinkFilterChange}
                    filterObj={selectedLinks}
                    setIsValid={props.setIsValid}
                    data-testid='filter_link_select'
                  />
                </div>
              </div>
              {metricPickers()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

MetricFilter.propTypes = {
  workgroupId: PropTypes.string,
  projectId: PropTypes.string,
  filterObj: PropTypes.object,
  handleFilterChange: PropTypes.func,
  setIsValid: PropTypes.func
};
export default MetricFilter;
