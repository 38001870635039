import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../../styles/WorkgroupsContainer.scss';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import './AppsHeader.scss';
import { Button, InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import theme from '../shared/Themes/InputFieldTheme';
import { Clear, Search } from '@mui/icons-material';
import { button } from '../shared/Themes/ButtonTheme';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SortIcon from '@mui/icons-material/Sort';
import AppListSort from '../sort/AppListSort';
import MenuIcon from '@mui/icons-material/Menu';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import AddIcon from '@mui/icons-material/Add';
import AddNewApp from './AddNewApp';
import { useDispatch, useSelector } from 'react-redux';
import { setIsGridView } from '../../Redux/slices/appsSlice';
import { useNavigate } from 'react-router-dom';

const AppsHeader = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isGridView = useSelector((state) => state.apps.isGridView);
  const [searchText, setSearchText] = useState('');
  const [sortMenuState, setSortMenuState] = useState(null);

  const [openPopup, setOpenPopup] = useState(false);
  const open = Boolean(sortMenuState);
  const { t } = useTranslation();

  const searchApps = (e) => {
    setSearchText(e.target.value);
    props.searchApps(e.target.value);
  };

  const clearSearch = () => {
    setSearchText('');
    props.searchApps('');
  };

  const openSortMenu = (event) => {
    setSortMenuState(event.currentTarget);
  };

  const onSortMenuClose = () => {
    setSortMenuState(null);
  };

  const toggleViewType = (event, nextView) => {
    if (nextView !== null && isGridView !== nextView) {
      dispatch(setIsGridView(!isGridView));
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
    props.closeAddAppPopup(false);
  };

  const openConfirmationPopupForPreconfiguredWidgets = (app) => {
    props.createApp({
      ...app
    });
  };

  const openAddNewPopup = () => {
    setOpenPopup(true);
  };

  const navigateToWorkgroup = () => {
    navigate('/Workgroups');
  };

  return (
    <div className='apps-header'>
      <span className='back-to-workgroup'>
        <ArrowBackIosIcon sx={{ 'cursor': 'pointer' }} onClick={navigateToWorkgroup} titleAccess={t('BACK')}></ArrowBackIosIcon>
        <DashboardTypography typographyClass={'title-16-700 workgroup-name'}>{props.workgroupName} - {t('APPS')}</DashboardTypography>
        <DashboardTypography typographyClass={'title-16-700 apps-count'}>&#40;{props.totalNumberOfApps}&#41;</DashboardTypography>
      </span>

      <div className='action-items'>
        <ThemeProvider theme={theme}>
          <TextField placeholder={t('SEARCH_PLACEHOLDER')}
            onChange={searchApps}
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start' >
                  <Search id='search-icon' />
                </InputAdornment>
              ),
              endAdornment: searchText && (
                <InputAdornment
                  position='end'
                  onClick={clearSearch}
                  style={{ cursor: 'pointer' }}
                >
                  <Clear id='clear-search' />
                </InputAdornment>
              ),
            }}
          />
        </ThemeProvider>

        <ThemeProvider theme={button}>
          <Button
            variant='outlined'
            onClick={openSortMenu}
            startIcon={<SortIcon />}
          >
            {t('APP.SORT')}
          </Button>
          <AppListSort
            anchorEl={sortMenuState}
            open={open}
            onSortMenuClose={onSortMenuClose}
          />
        </ThemeProvider>

        <ThemeProvider theme={button}>
          <ToggleButtonGroup
            value={isGridView}
            exclusive
            onChange={toggleViewType}
          >
            <ToggleButton value={true}>
              <ViewModuleIcon />
            </ToggleButton>
            <ToggleButton value={false}>
              <MenuIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </ThemeProvider>

        <ThemeProvider theme={button}>
          <Button
            variant='contained'
            color='primary'
            onClick={openAddNewPopup}
            endIcon={<AddIcon />}
          >
            {t('ADD_NEW_APP')}
          </Button>
        </ThemeProvider>

        <AddNewApp
          open={openPopup}
          handleClose={handleClose}
          handleSave={openConfirmationPopupForPreconfiguredWidgets}
          projects={props.projects}
          totalApps={props.totalApps}
        ></AddNewApp>
      </div>
    </div>
  );
};

AppsHeader.propTypes = {
  searchWorkgroup: PropTypes.func,
  workgroupName: PropTypes.string,
  totalNumberOfApps: PropTypes.number,
  searchApps: PropTypes.func,
  closeAddAppPopup: PropTypes.func,
  createApp: PropTypes.func,
  projects: PropTypes.array,
  totalApps: PropTypes.array,
};
export default AppsHeader;
