import React from 'react';
import './WidgetConfigHeaderComponent.scss';
import PropTypes from 'prop-types';
import { Button, Grid, ThemeProvider } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DashboardButton from '../../shared/Buttons/DashboardButton';
import { useTranslation } from 'react-i18next';
import DashboardTypography from '../DashboardTypography/DashboardTypography';
import { button, outlinedPrimary } from '../Themes/ButtonTheme';

const WidgetConfigHeaderComponent = (props) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={2}
      className='widget-config-header-component'
      data-testid='widget-config-header'
      id='widget-config-header'
    >
      <Grid item xs={6} md={6} sm={6} lg={6} xl={6} className='add-edit-new-chart'>
        <DashboardTypography testId='dialog-config-title' typographyClass={'title-16-600 widget-config-header-title'}>{props.headerTitle}</DashboardTypography>
      </Grid>
      <Grid item xs={6} md={6} sm={6} lg={6} xl={6} className='add-edit-new-chart-action-buttons'>
        {props.isExistFullScreen && <DashboardButton color='secondary' dataTestid='pmv-exit-fullscreen-btn' eventHandler={props.onClose}>
          {t('PROCESS_MODEL.EXIT_FULLSCREEN')}
        </DashboardButton>}

        {!props.isExistFullScreen && <ThemeProvider theme={outlinedPrimary}>
          <Button
            variant='outlined'
            onClick={props.onClose}
            sx={{ marginRight: '8px' }}
          >
            {t('DISCARD')}
          </Button>
        </ThemeProvider>}

        {!props.isExistFullScreen && <ThemeProvider theme={button}>
          <Button
            variant='contained'
            onClick={props.saveChartConfig}
            data-testid='save-widget'
            endIcon={<SaveOutlinedIcon />}
          >
            {t('SAVE')}
          </Button>
        </ThemeProvider>}


      </Grid>
    </Grid>
  );
};

WidgetConfigHeaderComponent.propTypes = {
  headerTitle: PropTypes.string,
  onClose: PropTypes.func,
  saveChartConfig: PropTypes.func,
  isExistFullScreen: PropTypes.bool
};

export default WidgetConfigHeaderComponent;
