import { createSlice } from '@reduxjs/toolkit';

const initialDashboardState = {
  isGridView: true,
};

const appsSlice = createSlice({
  name: 'apps',
  initialState: initialDashboardState,
  reducers: {
    setIsGridView: (state, action) => {
      state.isGridView = action.payload;
    },
  }
});

export const {
  setIsGridView,
} = appsSlice.actions;
export default appsSlice.reducer;
