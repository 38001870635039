import React, { useState, useEffect } from 'react';
import { Card, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

import CreateThemeDialog from './CreateThemeDialog';
import PropTypes from 'prop-types';
import '../../../styles/DashboardLayout.css';
import ApiService from '../../../services/ApiService';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../loader/LoadingSpinner';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteConfirmationDialog from '../../shared/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useSelector } from 'react-redux';
import DashboardTypography from '../../shared/DashboardTypography/DashboardTypography';
import { ThemeProvider } from '@emotion/react';
import { outlinedDashed } from '../../shared/Themes/ButtonTheme';
import AddIcon from '@mui/icons-material/Add';
const ThemeSection = (props) => {
  const { t } = useTranslation();
  const [isCreateThemeDialogOpen, setIsCreateThemeDialogOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [themeToDelete, setThemeToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [appThemes, setAppThemes] = useState([]);
  const [isEditTheme, setIsEditTheme] = useState(false);
  const [themeToEdit, setThemeToEdit] = useState(null);
  const [isViewOnly, setIsViewOnly] = useState(false);

  useEffect(() => {
    fetchAppThemes(props.appId);
  }, [props.appId]);

  const fetchAppThemes = async (appId) => {
    try {
      setLoading(true);
      const response = await ApiService.getAppThemes(appId);
      setAppThemes(response);
      findAndSetTheme(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t('THEME.FAILED_TO_FETCH_APP_THEME'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };

  const findAndSetTheme = (allThemes) => {
    if (!selectedTheme && props.selectedThemeId) {
      const selectedNewTheme = allThemes.find((theme) => theme.id === props.selectedThemeId);
      setSelectedTheme(selectedNewTheme);
    }
  };

  const handleThemeChange = (themeId) => {
    const selectedNewTheme = appThemes.find((theme) => theme.id === themeId);
    setSelectedTheme(selectedNewTheme);
    props.onChangeTheme(selectedNewTheme);
  };

  const handleOpenCreateTheme = () => {
    setIsEditTheme(false);
    setThemeToEdit(null);
    setIsCreateThemeDialogOpen(true);
    setIsViewOnly(false);
  };

  const handleEditTheme = (theme, isViewOnlyTheme, event) => {
    event.stopPropagation();
    setIsViewOnly(isViewOnlyTheme);
    setIsEditTheme(true);
    setThemeToEdit(theme);
    setIsCreateThemeDialogOpen(true);
  };

  const handleCreateTheme = async (newTheme) => {
    try {
      setLoading(true);
      await ApiService.createTheme(props.appId, newTheme);
      fetchAppThemes(props.appId);
      setLoading(false);

      toast.success(t('THEME.NEW_THEME_CREATED'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    } catch (error) {
      setLoading(false);
      if (error.response?.status === 409) {
        toast.error(t('THEME.DUPLICATE_THEME_NAME'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: 'light'
        });
      } else {
        toast.error(t('THEME.FAILED_TO_CREATE_THEME'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: 'light'
        });
      }
    }
  };

  const handleUpdateTheme = async (updatedThemeData) => {
    try {
      setLoading(true);
      await ApiService.updateTheme(updatedThemeData.id, updatedThemeData);
      fetchAppThemes(props.appId);
      setLoading(false);
      toast.success(t('THEME.THEME_UPDATED'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 409) {
        toast.error(t('THEME.DUPLICATE_THEME_NAME'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: 'light'
        });
      } else {
        toast.error(t('THEME.FAILED_TO_UPDATE_THEME'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: 'light'
        });
      }
    }
  };

  const handleDeleteTheme = (themeId, event) => {
    event.stopPropagation();
    setThemeToDelete(themeId);
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = (event) => {
    event.stopPropagation();
    setConfirmationOpen(false);
  };

  const deleteTheme = async (event) => {
    event.stopPropagation();
    setConfirmationOpen(false);
    try {
      setLoading(true);
      const response = await ApiService.deleteTheme(themeToDelete);
      fetchAppThemes(props.appId);
      if (response.id) {
        handleThemeChange(response.id);
      }
      setLoading(false);
      toast.success(t('THEME.THEME_DELETED'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    } catch (error) {
      setLoading(false);
      toast.error(t('THEME.FAILED_TO_DELETE_THEME'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light'
      });
    }
  };

  return (
    <div className={loading === true ? 'mainSection-blur' : ''}>
      <div className='themeheader-Container' data-testid='theming-header'>
        {/* <div className='themeTitle-section'> */}
        <Typography className='themeTitle'>{t('THEMES')}</Typography>
        {/* </div> */}
        {/* <div>
          <Button
            className='createTheme-btn'
            data-testid='create-theme-button'
            startIcon={<ColorLensOutlinedIcon />}
            onClick={handleOpenCreateTheme}
          >
            {t('CREATE')}
          </Button>

        </div> */}
      </div>

      <div className='themeCards-section' data-testid='theming-container'>
        <ThemeProvider theme={outlinedDashed}>
          <Button
            variant='outlined'
            sx={{ width: '100%', height: 'auto', marginTop: '20px' }}
            data-testid='add-new-dashboard-button'
            onClick={handleOpenCreateTheme}
          >
            <AddIcon
              sx={{
                color: '#041AB9',
                marginRight: '10px',
                cursor: 'pointer',
                fontWeight: 'bolder'
              }}
            />
            {t('NEW_THEME')}
          </Button>
        </ThemeProvider>
        {appThemes &&
          appThemes.map((theme, index) => (
            <div key={index}>
              <Card
                className='themeCards'
                data-testid='theme-card'
                sx={{
                  border: selectedTheme?.id === theme.id ? '2px solid #0400B966' : '2px solid #C5C7CE', background: selectedTheme?.id === theme.id ? '#0400B90D' : '#FFFFFF;', boxShadow: 'unset', borderRadius: '8px'
                }}
                onClick={() => handleThemeChange(theme.id)}
              >
                <div className='themeCardsModal'>
                  <DashboardTypography typographyClass={'title-14-700 theme-title'}>{theme.name}</DashboardTypography>
                  {!theme.isDefault && (
                    <div>
                      <Tooltip title={t('DELETE_THEME')} arrow>
                        <Button
                          onClick={(e) => handleDeleteTheme(theme.id, e)}
                          data-testid='delete-theme-button'
                          role='delete-btn'
                          className='theme-btn'
                        >
                          <DeleteOutlinedIcon id='delete-theme-button-icon' color='disabled' />
                        </Button>
                      </Tooltip>
                      {confirmationOpen && (
                        <DeleteConfirmationDialog
                          open={confirmationOpen}
                          onClose={(e) => handleConfirmationClose(e)}
                          onDeleteConfirm={(e) => deleteTheme(e)}
                          dialogTitle={t('DELETE_THEME')}
                          deleteConfirmationMessage={t('DELETE_CONFIRMATION')}
                          deleteConfirmationNote={t('DELETE_NOTE')}
                        ></DeleteConfirmationDialog>
                      )}
                      <Tooltip title={t('EDIT_THEME')} arrow>
                        <Button
                          sx={{ minWidth: '20px' }}
                          onClick={(e) => handleEditTheme(theme, false, e)}
                          data-testid='edit-theme-button'
                          className='theme-btn'
                        >
                          <ModeEditOutlineOutlinedIcon id='edit-theme-button-icon' color='disabled' data-testid='edit-button' />
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                  {theme.isDefault && (
                    <div>
                      <Tooltip title={t('VIEW_THEME')} arrow>
                        <Button
                          data-testid='view-theme-button'
                          onClick={(e) => handleEditTheme(theme, true, e)}
                          role='delete-btn'
                        >
                          <VisibilityOutlinedIcon id='view-theme-button-icon' color='disabled' />
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div>
                  {theme.colourList.map((themeColor) => (
                    <Button
                      key={themeColor + index}
                      className='color-btn'
                      sx={{
                        minWidth: '15px !important',
                        borderRadius: '50px',
                        padding: '3%',
                        marginLeft: '10px',
                        backgroundColor: themeColor,
                        pointerEvents: 'none'
                      }}
                    ></Button>
                  ))}
                </div>
              </Card>
            </div>
          ))}
      </div>
      {loading === true && <LoadingSpinner></LoadingSpinner>}
      {isCreateThemeDialogOpen && (
        <CreateThemeDialog
          open={isCreateThemeDialogOpen}
          onClose={() => setIsCreateThemeDialogOpen(false)}
          isEdit={isEditTheme}
          isDefault={isViewOnly}
          themeData={themeToEdit}
          onCreate={handleCreateTheme}
          onUpdate={handleUpdateTheme}
          role='dialog'
          data-testid='create-theme-dialog'
        />
      )}
    </div>
  );
};
ThemeSection.propTypes = {
  appId: PropTypes.string,
  onChangeTheme: PropTypes.func,
  selectedThemeId: PropTypes.string
};
export default ThemeSection;
