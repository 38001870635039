import { Card, Skeleton } from '@mui/material';
import React from 'react';
import DashboardTypography from './DashboardTypography/DashboardTypography';
import { CorporateFareOutlined } from '@mui/icons-material';
import './WorkGroupsCard.scss';
import PropTypes from 'prop-types';
import { generatePath, useNavigate } from 'react-router-dom';

const WorkgroupsCard = (props) => {
  const { workgroup, isLoading } = props;
  const navigate = useNavigate();
  const navigateToApps = (selectedWorkgroup) => {
    const routePattern = '/Workgroups/:id/Apps';
    const params = {
      id: selectedWorkgroup.id
    };

    const generatedUrl = generatePath(routePattern, params);
    const urlWithQueryParams = `${generatedUrl}?${new URLSearchParams({
      workgroupName: encodeURIComponent(selectedWorkgroup.name)
    })}`;
    navigate(urlWithQueryParams);
  };
  return (
    <Card className='workgroup-card' onClick={() => navigateToApps(workgroup)}>
      <div className='card-content'>
        <div className='workgroup-card-icon-section'>
          {isLoading ? (
            <Skeleton variant='circular' width={40} height={40} />
          ) : (
            <CorporateFareOutlined />
          )}
        </div>

        <DashboardTypography typographyClass={'title-14-600 workgroup-name'}>
          {isLoading ? <Skeleton variant='text' width={100} /> : workgroup.name}
        </DashboardTypography>
      </div>
    </Card>
  );
};

WorkgroupsCard.propTypes = {
  workgroup: PropTypes.object,
  isLoading: PropTypes.bool
};

export default WorkgroupsCard;
