import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import ApiService from '../../services/ApiService';
import LoadingSpinner from '../loader/LoadingSpinner';
import { getUuid } from '../../utils/utils.js';
import {
  setGlobalFiltersInSessionStorage,
  getGlobalFiltersFromSessionStorage
} from '../../utils/GlobalFilterUtils.js';
import AddIcon from '@mui/icons-material/Add';
import { ToasterError, ToasterSuccess } from '../../utils/toaster';
import { setGlobalFilterDrawerState } from '../../Redux/slices/globalFilterSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import AddGlobalFilterForm from './AddGlobalFilterForm.js';
import { Box, Button, Checkbox, ThemeProvider, Tooltip } from '@mui/material';
import './GlobalFilters.scss';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog/DeleteConfirmationDialog.js';
import { sessionKeys } from '../../utils/Constants.js';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography.js';
import ThemeMode from '../themeMode/themeMode.js';
import { button } from '../shared/Themes/ButtonTheme.js';

const GlobalFilters = (props) => {
  const { t } = useTranslation();
  const [filterForm, setFilterForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [savedGlobalFilters, setSavedGlobalFilters] = useState([]);
  const [isGlobalFilterEdit, setIsGlobalFilterEdit] = useState(false);
  const [editedGlobalFilter, setEditedGlobalFilter] = useState(null);
  const [globalFilterList, setGlobalFilterList] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedGlobalFilterToDelete, setSelectedGlobalFilterToDelete] = useState({});
  const isGlobaFilterDrawerOpen = useSelector(
    (state) => state.globalFilter.isglobalFiltersDrawerOpen
  );
  const dispach = useDispatch();

  useEffect(() => {
    fetchGlobalFilter(props.dashboardId);
    setGlobalFilterList(getCurrentDashboardGlobalFilterList());
  }, [props.dashboardData]);

  const getCurrentDashboardGlobalFilterList = () => {
    let currentDashboardGlobalFilter = [];
    const appliedGlobalFilters = getGlobalFiltersFromSessionStorage(sessionKeys.GLOBAL_FILTERS);
    if (appliedGlobalFilters?.length > 0) {
      currentDashboardGlobalFilter = appliedGlobalFilters?.filter(
        (appliedGlobalFilter) => appliedGlobalFilter.dashboardId === props?.dashboardId
      );
    }
    return currentDashboardGlobalFilter;
  };

  const closeGlobalFilterDrawerSection = () => {
    dispach(setGlobalFilterDrawerState(false));
  };

  const openFilterForm = () => {
    initializeFilters();
  };

  const initializeFilters = () => {
    setFilterForm(false);
    setIsGlobalFilterEdit(false);
  };

  const fetchGlobalFilter = async (dashboardId) => {
    if (dashboardId) {
      try {
        setLoading(true);
        const response = await ApiService.fetchGlobalFilters(dashboardId);
        setSavedGlobalFilters(response);
      } catch (error) {
        ToasterError(t('FAILED_GLOBAL_FILTERS_FETCH'));
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteGlobalFilter = async (event) => {
    try {
      setLoading(true);
      event.stopPropagation();
      setDeleteConfirmation(false);
      await ApiService.deleteGlobalFilters(props.dashboardId, selectedGlobalFilterToDelete.id);
      const updatedGlobalFilterList = savedGlobalFilters?.filter(
        (savedGlobalFilter) => savedGlobalFilter.id !== selectedGlobalFilterToDelete.id
      );
      setSavedGlobalFilters(updatedGlobalFilterList);
      applyGlobalFilter(deleteGlobalFilterFromSession());
      ToasterSuccess(t('GLOBAL_FILTER_DELETE'));
    } catch (error) {
      ToasterError(t('FAILED_GLOBAL_FILTER_DELETE'));
    } finally {
      setLoading(false);
    }
  };

  const deleteGlobalFilterFromSession = () => {
    let updatedGlobalFiltersInSession = [];
    const globalFiltersInSessionStorage = getGlobalFiltersFromSessionStorage(
      sessionKeys.GLOBAL_FILTERS
    );
    if (globalFiltersInSessionStorage?.length > 0) {
      updatedGlobalFiltersInSession = globalFiltersInSessionStorage?.filter(
        (filter) => filter.id !== selectedGlobalFilterToDelete.id
      );
    }
    return updatedGlobalFiltersInSession;
  };

  const editGlobalFilter = async (filter) => {
    setIsGlobalFilterEdit(true);
    setEditedGlobalFilter(filter);
    setFilterForm(false);
  };

  const setGlobalFilters = (isSelect, filterToApply) => {
    let globalFilterToApply = [];
    if (isSelect) {
      filterToApply = constructGlobalFilterObject(filterToApply);
      const appliedGlobalFilters = getGlobalFiltersFromSessionStorage(sessionKeys.GLOBAL_FILTERS);
      if (appliedGlobalFilters?.length > 0) {
        globalFilterToApply = [...appliedGlobalFilters, filterToApply];
      } else {
        globalFilterToApply.push(filterToApply);
      }
    }
    if (!isSelect) {
      globalFilterToApply = globalFilterList?.filter(
        (globalFilter) => globalFilter.id !== filterToApply.id
      );
    }
    applyGlobalFilter(globalFilterToApply);
  };

  const getDashboardDataByGlobalFilter = () => {
    props.dashboardData?.charts?.forEach((chart) => {
      props.getChartDataByConfig(chart);
    });
  };

  const applyGlobalFilter = (selectedGlobalFiltersToApply) => {
    setGlobalFiltersInSessionStorage(sessionKeys.GLOBAL_FILTERS, selectedGlobalFiltersToApply);
    setGlobalFilterList(selectedGlobalFiltersToApply);
    getDashboardDataByGlobalFilter();
  };

  const constructGlobalFilterObject = (globalFilter) => {
    globalFilter.dashboardId = props?.dashboardId;
    globalFilter?.filters?.forEach((filter) => {
      filter.isGlobalFilter = true;
      filter.globalFilterUniqueId = getUuid();
    });
    return globalFilter;
  };

  const applyAllGlobalFilters = () => {
    let allSavedGlobalFilters = [];
    allSavedGlobalFilters = [...savedGlobalFilters];
    allSavedGlobalFilters?.forEach((filterItem) => {
      constructGlobalFilterObject(filterItem);
    });
    applyGlobalFilter(allSavedGlobalFilters);
  };

  const clearAllGlobalFilters = () => {
    let appliedFiltersOfAllDashboard = [];
    appliedFiltersOfAllDashboard = getGlobalFiltersFromSessionStorage(sessionKeys.GLOBAL_FILTERS);
    const appliedFiltersOfOtherDashboard = appliedFiltersOfAllDashboard?.filter(
      (appliedFilter) => appliedFilter.dashboardId !== props?.dashboardId
    );
    setGlobalFiltersInSessionStorage(sessionKeys.GLOBAL_FILTERS, appliedFiltersOfOtherDashboard);
    props.dashboardData?.charts?.forEach((chart) => {
      const chartFilters = chart?.filters;
      chart.filters = [];
      chart.filters = chartFilters?.filter(
        (chartFilter) =>
          chartFilter.globalFilterUniqueId === undefined && chartFilter.isGlobalFilter === undefined
      );
      props.getChartDataByConfig(chart);
    });
  };

  const getDataSourceName = (filter) => {
    const dataSourceName = props?.selectedDataSourceList?.filter(
      (dataSource) => dataSource.projectId === filter?.projectIds[0]
    );
    return dataSourceName[0]?.name;
  };

  const openDeleteConfirmationDialog = (filter) => {
    setSelectedGlobalFilterToDelete(filter);
    setDeleteConfirmation(true);
  };

  const closeDeleteConfirmationDialog = (event) => {
    event.stopPropagation();
    setDeleteConfirmation(false);
  };

  const DrawerList = (
    <Box sx={{ width: 300 }} role='presentation' id='global-filter-container'>
      <>
        {filterForm ? (
          <>
            <div className='main-section' data-testid='global-filter-container'>
              <div className='global-filter-section-title'>
                <div>
                  <DashboardTypography typographyClass={'title-16-600'}>{t('FILTERS')}</DashboardTypography>
                </div>
                <div
                  className='close'
                  data-testid='global-filter-close-icon'
                  onClick={closeGlobalFilterDrawerSection}
                  id='global-filter-close-icon'
                ></div>
              </div>
              <div
                className='new-filter-heading'
                data-testid='create-global-filter-button'
                onClick={openFilterForm}
                id='add-filter'
              >
                <ThemeMode />
                <AddIcon className='plus-icon' id='plus-icon'></AddIcon>
                <DashboardTypography id='new-filter-heading' typographyClass={'title-14-600'}> {t('ADD_FILTER')}</DashboardTypography>
              </div>
              {savedGlobalFilters?.length === 0 && (
                <div className='no-filter-created'>
                  <DashboardTypography id='no-filter-created' typographyClass={'title-14-500'}>{t('NO_FILTER_CREATED')}</DashboardTypography>
                </div>
              )}

              {savedGlobalFilters?.length > 0 &&
                savedGlobalFilters.map((filter, index) => (
                  <div
                    className={
                      globalFilterList?.some((list) => list.id === filter.id)
                        ? 'add-global-filter-container add-global-filter-container-selected'
                        : 'add-global-filter-container'
                    }
                    id={globalFilterList?.some((list) => list.id === filter.id)
                      ? 'add-global-filter-container-selected'
                      : 'add-global-filter-container'}
                    key={index}
                  >
                    <div className='filter-checkbox-section'>
                      <Checkbox
                        data-testid='global-filter-checkbox'
                        checked={globalFilterList?.some((list) => list.id === filter.id)}
                        onChange={(e) => setGlobalFilters(e.target.checked, filter)}
                        className='filter-checkbox'
                      />
                      <Tooltip title={filter.globalFilterName}>
                        <div className='global-filter-truncate'>
                          <DashboardTypography id='global-filter-app-name' typographyClass={'title-14-500 global-filter-name'}>{t('APP.NAME')}:</DashboardTypography>
                          <DashboardTypography id='global-filter-name-list' typographyClass={'title-14-500'}>{filter.globalFilterName}</DashboardTypography>
                        </div>
                      </Tooltip>
                      <Tooltip title={getDataSourceName(filter)}>
                        <div className='data-source-name'>
                          <DashboardTypography id='global-filter-datasource' typographyClass={'title-14-500 global-filter-name'}>{t('DATA_SOURCE')}:</DashboardTypography>
                          <DashboardTypography id='global-filter-datasource-name' typographyClass={'title-14-500'}>{getDataSourceName(filter)}</DashboardTypography>
                        </div>
                      </Tooltip>
                    </div>
                    <div className='filter-icons'>
                      <ThemeMode />
                      <EditOutlinedIcon
                        className='global-filter-icon edit-icon'
                        data-testid='edit-global-filter'
                        onClick={() => editGlobalFilter(filter)}
                        id='global-filter-icon-edit-icon'
                      />
                      <DeleteOutlinedIcon
                        className='global-filter-icon'
                        data-testid='delete-global-filter'
                        onClick={() => openDeleteConfirmationDialog(filter)}
                        id='global-filter-icon-delete-icon'
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className='global-filter-btn-section global-filter-section'>
              <Tooltip
                title={globalFilterList?.length === 0 ? t('NO_GLOBAL_FILTER_SELECTED') : ''}
                arrow
              >
                <span>
                  <ThemeProvider theme={button}>
                    <Button
                      variant='text'
                      onClick={clearAllGlobalFilters}
                      data-testid='clear-all-global-filter'
                      disabled={globalFilterList?.length === 0}
                    >
                      {t('CLEAR_ALL')}
                    </Button>
                  </ThemeProvider>
                </span>
              </Tooltip>
              <Tooltip
                title={
                  savedGlobalFilters?.length === 0 ||
                    globalFilterList?.length === savedGlobalFilters?.length
                    ? t('ALL_GLOBAL_FILTERS_APPLIED')
                    : ''
                }
                arrow
              >
                <span>
                  <ThemeProvider theme={button}>
                    <Button
                      variant='contained'
                      onClick={applyAllGlobalFilters}
                      data-testid='apply-all-global-filter'
                      disabled={
                        savedGlobalFilters?.length === 0 ||
                        globalFilterList?.length === savedGlobalFilters?.length
                      }
                    >
                      {t('APPLY_ALL')}
                    </Button>
                  </ThemeProvider>
                </span>
              </Tooltip>
            </div>
          </>
        ) : (
          <AddGlobalFilterForm
            setFilterForm={setFilterForm}
            savedGlobalFilters={savedGlobalFilters}
            isGlobalFilterEdit={isGlobalFilterEdit}
            editedGlobalFilter={editedGlobalFilter}
            selectedDataSourceList={props.selectedDataSourceList}
            setIsGlobalFilterEdit={setIsGlobalFilterEdit}
            fetchGlobalFilter={fetchGlobalFilter}
            dashboardId={props.dashboardId}
            applyGlobalFilter={applyGlobalFilter}
          />
        )}
        {deleteConfirmation && (
          <DeleteConfirmationDialog
            open={deleteConfirmation}
            onClose={(e) => closeDeleteConfirmationDialog(e)}
            onDeleteConfirm={(e) => deleteGlobalFilter(e)}
            dialogTitle={t('DELETE_GLOBAL_FILTER')}
            deleteConfirmationMessage={t('DELETE_GLOBAL_FILTER_MESSAGE')}
            deleteConfirmationNote={t('DELETE_GLOBAL_FILTER_NOTE')}
          ></DeleteConfirmationDialog>
        )}
      </>
      {loading === true && <LoadingSpinner></LoadingSpinner>}
    </Box>
  );

  return (
    <Drawer open={isGlobaFilterDrawerOpen} anchor='right' onClose={closeGlobalFilterDrawerSection}>
      {DrawerList}
    </Drawer>
  );
};

GlobalFilters.propTypes = {
  selectedDataSourceList: PropTypes.array,
  dashboardId: PropTypes.string,
  dashboardData: PropTypes.object,
  getChartDataByConfig: PropTypes.func
};

export default GlobalFilters;
