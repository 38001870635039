
import { createTheme } from '@material-ui/core';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input': {
            padding: '10px 0px',
          },
          '& .MuiOutlinedInput-root': {
            color: '#B2B5BD',
            fontWeight: '400',
            padding: '0px 8px',
            borderRadius: '20px',

            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#C5C7CE',
            },

            '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
              borderColor: '#8C8F9D',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#0400B9'
            }
          },
          '& .MuiSvgIcon-root': {
            color: '#8C8F9D'
          }
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
        },
      },
    },
  },
});

export default theme;