import { createTheme } from '@mui/material/styles';

export const button = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderRadius: '20px',
          borderColor: '#ECECEF',
          color: '#3F455B',
          fontSize: '14px',
          fontWeight: '400',
          fontFamily: 'Open Sans',
          textTransform: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        contained: {
          borderRadius: '20px',
          color: '#ffffff',
          backgroundColor: '#0400B9',
          fontSize: '14px',
          fontWeight: '400',
          fontFamily: 'Open Sans',
          width: 'auto',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#0400B9',
          },
        },
        text: {
          borderRadius: '20px',
          color: '#0400B9',
          fontSize: '14px',
          fontWeight: '600',
          fontFamily: 'Open Sans',
          width: 'auto',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      }
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          borderColor: '#ECECEF',
          color: '#3F455B',
          fontSize: '14px',
          fontWeight: '400',
          fontFamily: 'Open Sans',
          padding: '5px 11px;',

          '&.Mui-selected': {
            color: '#0400B9',
          },
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        }
      }
    }
  }
});

export const outlinedPrimary = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderRadius: '20px',
          borderColor: '#0400B9',
          color: '#0400B9',
          fontSize: '14px',
          fontWeight: '400',
          fontFamily: 'Open Sans',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        }
      }
    },
  }
});

export const outlinedDashed = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderRadius: '20px',
          borderColor: '#3F455B',
          borderStyle: 'dashed',
          color: '#0400B9',
          fontSize: '14px',
          fontWeight: '400',
          fontFamily: 'Open Sans',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        }
      }
    },
  }
});