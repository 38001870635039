import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, TextField, InputLabel, IconButton, ThemeProvider, Button } from '@mui/material';
import '../../styles/AppsLayout.scss';
import '../../styles/DashboardSection.scss';
import DashboardButton from '../shared/Buttons/DashboardButton';
import DashboardDataWarning from './DashboardDataWarning';
import ThemeMode from '../themeMode/themeMode';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import CloseIcon from '@mui/icons-material/Close';
import { button } from '../shared/Themes/ButtonTheme';

const AddEditDashboard = (props) => {
  const { t } = useTranslation();
  const [dashboardNameError, setDashboardNameError] = useState(false);
  const [dashboardNameDuplicateError, setDashboardNameDuplicateError] = useState(false);
  const [dashboardName, setDashboardName] = useState();
  const [showDataWarning, setShowDataWarning] = useState(false);
  const [addEditDashboardDialogTitle, setAddEditDashboardDialogTitle] = useState('');
  const [addEditDashboardButtonLabel, setAddEditDashboardButtonLabel] = useState('');

  useEffect(() => {
    if (props.isEditDashboard) {
      setDashboardName(props.dashboardData.name);
      setAddEditDashboardDialogTitle(t('EDIT_DASHBOARD'));
      setAddEditDashboardButtonLabel(t('SAVE'));
    }
    if (props.isCreateDuplicateDashboard) {
      if (isDuplicate('Copy of ' + props.dashboardData.name)) {
        setDashboardNameDuplicateError(true);
      }
      setDashboardName('Copy of ' + props.dashboardData.name);
      setAddEditDashboardDialogTitle(t('DASHBOARD.DUPLICATE_DASHBOARD'));
      setAddEditDashboardButtonLabel(t('DASHBOARD.DUPLICATE'));
    }
    if (!props.isEditDashboard && !props.isCreateDuplicateDashboard) {
      setAddEditDashboardDialogTitle(t('NEW_DASHBOARD'));
      setAddEditDashboardButtonLabel(t('ADD') + ' +');
    }
  }, [props.openPopup]);

  const handleConfirmationClose = () => {
    setShowDataWarning(false);
  };

  const handleSaveDashboard = () => {
    if (props.isValidDashboard(props.dashboardData)) {
      if (dashboardName && !dashboardNameDuplicateError && !dashboardNameError) {
        props.handleSave(dashboardName);
      } else {
        dashboardName ? setDashboardNameError(false) : setDashboardNameError(true);
      }
    } else {
      setShowDataWarning(true);
    }
  };

  const handleDashboardNameChange = function (value) {
    if (value && props.dashboards && isDuplicate(value)) {
      setDashboardNameDuplicateError(true);
    } else {
      setDashboardNameDuplicateError(false);
    }
    setDashboardName(value);
  };

  const getNamesArray = () => {
    if (props.isEditDashboard) {
      return props.dashboards.map((dashboard) => {
        if (dashboard.id !== props.dashboardData.id) {
          return dashboard.name?.trim().toLowerCase();
        }
      });
    } else {
      return props.dashboards.map((dashboard) => {
        return dashboard.name?.trim().toLowerCase();
      });
    }
  };

  const isDuplicate = (value) => {
    const valueArr = getNamesArray();
    return valueArr.includes(value?.trim().toLowerCase());
  };

  return (
    <div id='add-dashboard'>
      <Dialog
        className='add-edit-dialog'
        onClose={props.handleClose}
        aria-labelledby='add-widget-dialog-title'
        open={true}
        role='dialog'
        PaperProps={{
          sx: {
            height: 'auto',
            width: '300px',
            padding: '8px',
            borderRadius: '10px'
          }
        }}
      >
        <ThemeMode></ThemeMode>
        <DialogTitle id='add-edit-dashboard-dialog-title'>
          <div>
            <DashboardTypography typographyClass='title-16-600'>
              {addEditDashboardDialogTitle}
            </DashboardTypography>
          </div>

          <IconButton
            data-testid='close'
            onClick={props.handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-main-section' id='add-edit-dashboard-dialog-content'>
          <Grid container spacing={2} className='dialogBody-grid' id='add-edit-dashboard-dialog-body'>
            <Grid item xs={12} id='add-edit-dashboard-dialog-inner-container' sx={{ padding: '16px 0px 16px 16px' }}>
              {/* <InputLabel required className='label-name'>
                <DashboardTypography id='dashboard-name' typographyClass={'title-14-600'}> {t('DASHBOARD.NAME')}</DashboardTypography>
              </InputLabel> */}
              <TextField
                sx={{ marginTop: '8px' }}
                label={t('DASHBOARD.NAME')}
                variant='outlined'
                required
                fullWidth
                key='Dashboard Name'
                error={dashboardNameError}
                helperText={dashboardNameError ? t('DASHBOARD_NAME_ERROR_MESSAGE') : null}
                id='chart-label'
                placeholder={t('DASHBOARD_NAME')}
                size='small'
                className='app-input'
                style={{ backgroundColor: '#F9F9Fc' }}
                value={dashboardName}
                data-testid='dashboard-name-input'
                onChange={(event) => {
                  var regex = /^$|^\S+.*/;
                  const labelCheck = regex.test(event.target.value);
                  if (labelCheck) {
                    handleDashboardNameChange(event.target.value);
                    event ? setDashboardNameError(false) : setDashboardNameError(true);
                  }
                }}
              />
              {dashboardNameDuplicateError === true ? (
                <div className='appName-errorSection'>{t('DASHBOARD_NAME_DUPLICATE_ERROR')}</div>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='padding-left-20' id='add-edit-dashboard-dialog-action-container'>
          <Grid container item xs={12} className='grid-btn'>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
              <ThemeProvider theme={button}>
                <Button
                  variant='text'
                  data-testid='cancel-add-dashboard'
                  onClick={props.handleClose}
                >
                  {t('CANCEL')}
                </Button>
              </ThemeProvider>

              <ThemeProvider theme={button}>
                <Button
                  variant='contained'
                  onClick={handleSaveDashboard}
                  data-testid='save-dashboard-button'
                  disabled={dashboardNameDuplicateError || dashboardName === ''}
                >
                  {addEditDashboardButtonLabel}
                </Button>
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {showDataWarning && <DashboardDataWarning onClose={handleConfirmationClose} />}
    </div>
  );
};

AddEditDashboard.propTypes = {
  handleClose: PropTypes.func,
  dashboardData: PropTypes.object,
  handleSave: PropTypes.func,
  dashboards: PropTypes.array,
  isEditDashboard: PropTypes.bool,
  isCreateDuplicateDashboard: PropTypes.bool,
  openPopup: PropTypes.bool,
  isValidDashboard: PropTypes.func
};

export default AddEditDashboard;
