import React, { useState, useEffect, useContext } from 'react';
import ApiService from '../../services/ApiService';
import WorkgroupsHeader from './WorkgroupsHeader';
import '../../styles/LoadingSpinner.scss';
import '../../styles/WorkgroupsContainer.scss';
import { t } from 'i18next';
import { AuthContext } from '../../utils/KeycloakWrapper';
import AuthInterceptor from '../../utils/AuthInterceptor';
import './WorkgroupsContainer.scss';
import HeaderComponent from '../shared/HeaderComponent';
import WorkgroupsCard from '../shared/WorkGroupsCard';
import { Box, Grid } from '@mui/material';
import { ToasterError } from '../../utils/toaster';
import PaginationComponent from '../shared/Pagination/PaginationComponent';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';

const WorkgroupsContainer = () => {
  const [allWorkGroups, setAllWorkGroups] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(12);
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    AuthInterceptor(authContext);
    const fetchData = async () => {
      setCurrentPageNumber(0);
      await fetchAllWorkGroups(0, '');
    };
    if (authContext && Object.keys(authContext).length !== 0) {
      fetchData();
    }
  }, [authContext]);

  const searchWorkgroup = async (searchText) => {
    setSearchQuery(searchText);
    setCurrentPageNumber(0);
    await fetchAllWorkGroups(0, searchText);
  };

  const fetchAllWorkGroups = async (pageNumber, searchText, newPageSize) => {
    try {
      setLoading(true);
      const response = await ApiService.getAllWorkgroups(
        pageNumber,
        newPageSize ? newPageSize : pageSize,
        searchText
      );
      setAllWorkGroups(response);
    } catch (error) {
      if (!error.toString().includes('401')) {
        ToasterError(t('WORKGROUPS.FAILED_TO_FETCH_WORKGROUPS'));
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (event, pageNumber) => {
    const selectedPageNumber = pageNumber - 1;
    setCurrentPageNumber(selectedPageNumber);
    await fetchAllWorkGroups(selectedPageNumber, searchQuery);
  };

  const getStartingDataNumber = () => {
    return currentPageNumber * pageSize + 1;
  };

  const getEndingDataNumber = () => {
    if (currentPageNumber + 1 === allWorkGroups?.totalPages) {
      return allWorkGroups?.totalElements;
    }
    return (currentPageNumber + 1) * pageSize;
  };

  const handlePageSizeChange = async (selectedPageSize) => {
    setPageSize(selectedPageSize);
    setCurrentPageNumber(0);
    await fetchAllWorkGroups(0, searchQuery, selectedPageSize);
  };

  const renderEmptyWorkgroup = () => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'center',
          marginTop: '10%',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <DashboardTypography id='empty-workgroups-msg' typographyClass={'title-18-700'}>
          {t('YOU_DO_NOT_HAVE_ANY_WORKGROUPS')}
        </DashboardTypography>
      </Box>
    );
  };

  return (
    <div className='workgroup-container'>
      <div className='header'>
        <HeaderComponent></HeaderComponent>
      </div>

      <div className='sub-header'>
        <WorkgroupsHeader searchWorkgroup={searchWorkgroup} />
      </div>

      <div className='body'>
        <Grid container spacing={2}>
          {loading
            ? Array.from(new Array(8)).map((_, index) => (
                <Grid item xs={3} key={index}>
                  <WorkgroupsCard isLoading={true} />
                </Grid>
              ))
            : allWorkGroups?.content.length > 0 &&
              allWorkGroups?.content.map((workgroup, index) => {
                return (
                  <Grid item xs={3} key={index}>
                    <WorkgroupsCard workgroup={workgroup} isLoading={false}></WorkgroupsCard>
                  </Grid>
                );
              })}
          {allWorkGroups?.content?.length === 0 && !loading && renderEmptyWorkgroup()}
        </Grid>
      </div>

      <div className='footer' data-testid='pagination-view'>
        <PaginationComponent
          data={allWorkGroups}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          getStartingDataNumber={getStartingDataNumber}
          getEndingDataNumber={getEndingDataNumber}
          currentPageNumber={currentPageNumber}
          handlePageChange={handlePageChange}
        ></PaginationComponent>
      </div>
    </div>
  );
};

export default WorkgroupsContainer;
