import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  MenuItem,
  Chip,
  Box,
  ListItemText,
  Checkbox,
  ThemeProvider,
  IconButton
} from '@mui/material';
import Button from '@mui/material/Button';
import configVariables from '../../utils/ConfigurationVariables';
import DashboardTypography from '../shared/DashboardTypography/DashboardTypography';
import DashboardTemplate from './DashboardTemplate.js';
import { ToasterError } from '../../utils/toaster.js';
import ApiService from '../../services/ApiService.js';
import dialogTheme from '../shared/Themes/DialogTheme.js';
import './AddNewApp.scss';
import { button } from '../shared/Themes/ButtonTheme.js';
import CloseIcon from '@mui/icons-material/Close';

const AddNewApp = (props) => {
  const { t } = useTranslation();
  const [appNameError, setAppNameError] = useState(false);
  const [appNameDuplicateError, setAppNameDuplicateError] = useState(false);
  const [dataSourceError, setDataSourceError] = useState(false);
  const [selectedDataSourceName, setSelectedDataSourceName] = useState([]);
  const [dashboardTemplates, setDashboardTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  const [app, setApp] = useState({
    name: '',
    description: '',
    workgroupId: '',
    workgroupName: '',
    connection: {
      projectIds: []
    },
    isPublic: 'true',
    dashboardTemplateId: ''
  });

  const selectedDataSource = () => {
    const selectedDataToDisplay = [];
    props?.appToEdit?.connection?.projectIds?.forEach((projectId) => {
      props?.projects?.forEach((projects) => {
        if (projects.projectId === projectId) {
          selectedDataToDisplay.push(projects);
        }
      });
    });
    return selectedDataToDisplay;
  };

  const constructDashboardTemplateData = (templates) => {
    const updatedTemplates = [];
    const blankTemplate = {
      id: '',
      name: 'Blank Template',
      description: 'Dashboard will be created with no pre-configured widgets',
      widgetNames: []
    };
    updatedTemplates.push(blankTemplate);
    templates?.forEach((template) => {
      updatedTemplates.push(template);
    });
    return updatedTemplates;
  };

  const getDashboardTemplates = async () => {
    try {
      setLoading(true);
      const response = await ApiService.fetchDashboardTemplates();
      const updatedDashboardTemplate = constructDashboardTemplateData(response);
      setDashboardTemplates(updatedDashboardTemplate);
    } catch (error) {
      ToasterError(t('DASHBOARD_TEMPLATE.TEMPLATE_FETCH_FAILED_INFO'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.open) {
      getDashboardTemplates();
      if (props.appToEdit) {
        setApp((prevApp) => ({
          ...prevApp,
          ...props.appToEdit,
          connection: {
            projectIds: props?.appToEdit?.connection?.projectIds || []
          }
        }));
        setAppDialogTitle(t('APP.EDIT_APP'));
        setSelectedDataSourceName(selectedDataSource());
      } else {
        setApp({
          name: '',
          description: '',
          workgroupId: '',
          workgroupName: '',
          connection: {
            projectIds: []
          },
          isPublic: 'true'
        });
        setAppDialogTitle(t('ADD_NEW_APP'));
      }

      setAppNameError(false);
      setAppNameDuplicateError(false);
      setDataSourceError(false);
    }
  }, [props.open, props.appToEdit]);

  const [appDialogTitle, setAppDialogTitle] = useState('');

  const addUpdateApp = () => {
    if (
      app?.name &&
      app?.connection?.projectIds?.length > 0 &&
      props?.appToEdit &&
      !appNameDuplicateError
    ) {
      props.updateApp(app);
      props.handleClose();
    } else if (
      app?.name &&
      app?.connection?.projectIds?.length > 0 &&
      !props?.appToEdit &&
      !appNameDuplicateError
    ) {
      props.handleSave(app);
      props.handleClose();
    } else {
      app?.name ? setAppNameError(false) : setAppNameError(true);
      app?.connection?.projectIds.length !== 0
        ? setDataSourceError(false)
        : setDataSourceError(true);
    }
  };
  const handleAppChange = (key, value) => {
    if (key === 'name') {
      if (value) {
        let valueArr = [];
        valueArr = props.totalApps?.includes(value);
        if (valueArr) {
          setAppNameDuplicateError(true);
        } else {
          setAppNameDuplicateError(false);
        }
      }
    }
    setApp((prevApp) => ({
      ...prevApp,
      [key]: value
    }));
  };

  const selectDataSource = (selectedProjectIds) => {
    setApp((prevApp) => ({
      ...prevApp,
      connection: { ...prevApp.connection, projectIds: selectedProjectIds }
    }));
  };

  // To set max Height for dropdown section for multiple data source
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
        width: 250
      }
    }
  };

  const getLabel = (projectId) => {
    if (props.projects.length > 0) {
      const dataSource = props.projects.find((item) => item.projectId === projectId);
      return dataSource?.name;
    }
  };

  const removeSelectedDataSource = (id) => {
    const index = app.connection.projectIds.findIndex((item) => item === id);
    app.connection.projectIds.splice(index, 1);
    selectDataSource(app.connection.projectIds);
  };

  const setDashboardTemplateId = (key, value) => {
    setApp((prevApp) => ({
      ...prevApp,
      [key]: value
    }));
  };

  return (
    <div id='add-new-app'>
      <ThemeProvider theme={dialogTheme}>
        <Dialog
          onClose={props.handleClose}
          aria-labelledby='add-widget-dialog-title'
          open={props.open}
          role='dialog'
          PaperProps={{
            sx: {
              height: props.appToEdit ? 'auto' : '430px',
              maxHeight: '800px',
              maxWidth: props.appToEdit ? '600px' : '850px',
              width: '850px',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px'
            }
          }}
        >
          <DialogTitle id='dialog-title'>
            <DashboardTypography typographyClass={'title-16-600 dialog-title'}>
              {appDialogTitle}
            </DashboardTypography>

            <IconButton
              aria-label='close'
              onClick={props.handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#667085'
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Grid container spacing={2}>
            <Grid xs={props.appToEdit ? 12 : 6} item>
              <DialogContent className='dialog-mainSection' id='dialog-content'>
                <Grid item xs={12} className='appgrid-item' sx={{ marginBottom: '15px' }}>
                  <TextField
                    label={t('APP_NAME')}
                    required
                    fullWidth
                    key='App Name'
                    error={appNameError}
                    size='small'
                    value={app.name}
                    onChange={(event) => {
                      handleAppChange(
                        'name',
                        event.target.value.slice(0, configVariables.app.maxAppNameAllowedChar)
                      );
                      event ? setAppNameError(false) : setAppNameError(true);
                    }}
                  />
                  <FormHelperText>
                    <DashboardTypography typographyClass={'title-12-400'}>
                      {appNameError
                        ? t('APP_NAME_ERROR_MESSAGE')
                        : t('MAX_CHARACTERS_MESSAGE', {
                          characterLength: app?.name?.length,
                          maxAllowdedCharLength: configVariables?.app?.maxAppNameAllowedChar
                        })}
                    </DashboardTypography>
                  </FormHelperText>
                  {appNameDuplicateError === true ? (
                    <div className='appName-errorSection'>App Name is Duplicate</div>
                  ) : null}
                </Grid>

                <Grid item xs={12} className='appgrid-item' sx={{ marginBottom: '15px' }}>
                  <FormControl fullWidth size='small' error={dataSourceError}>
                    {!props.appToEdit ? (
                      <InputLabel id='data-source'>
                        {t('SELECT_DATA_SOURCE', {
                          maxAllowdedDataSources: configVariables?.app?.maxAllowdedDataSources
                        })}
                        <span className='red-asterisk'>*</span>
                      </InputLabel>
                    ) : (
                      <InputLabel id='data-source'>{t('SELECTED_DATA_SOURCE')}</InputLabel>
                    )}
                    {
                      <Select
                        label={
                          !props.appToEdit
                            ? t('SELECT_DATA_SOURCE', {
                              maxAllowdedDataSources: configVariables?.app?.maxAllowdedDataSources
                            })
                            : t('SELECTED_DATA_SOURCE')
                        }
                        labelId='data-source'
                        data-testid='data-source-select'
                        multiple
                        MenuProps={MenuProps}
                        value={
                          props.projects && props.projects.length > 0
                            ? app.connection.projectIds
                            : []
                        }
                        onChange={(event) => {
                          selectDataSource(event.target.value);
                        }}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected?.map((value) => (
                              <Chip
                                key={value}
                                label={getLabel(value)}
                                variant='light'
                                data-testid='source-chip'
                                size='small'
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                                onDelete={() => {
                                  removeSelectedDataSource(value);
                                }}
                                sx={{ color: '#041AB9', backgroundColor: '#CDD1F1' }}
                                id='chip'
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {props.projects && props.projects.length > 0 ? (
                          props.projects.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={option.projectId}
                              disabled={
                                app.connection.projectIds.length ===
                                configVariables?.app?.maxAllowdedDataSources &&
                                app.connection.projectIds.indexOf(option.projectId) <= -1
                              }
                            >
                              <Checkbox
                                checked={app.connection.projectIds.indexOf(option.projectId) > -1}
                              />
                              <ListItemText>{option.name}</ListItemText>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value='no_data' disabled>
                            {t('APP.EMPTY_DATA_SOURCE_MESSAGE')}
                          </MenuItem>
                        )}
                      </Select>
                    }

                    {dataSourceError && (
                      <FormHelperText>{t('DATA_SOURCE_ERROR_MESSAGE')}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} className='grid-section appgrid-item'>
                  <TextField
                    label={t('DESCRIPTION')}
                    fullWidth
                    key='Description'
                    multiline
                    minRows={4}
                    maxRows={4}
                    size='large'
                    value={app.description}
                    onChange={(event) => {
                      handleAppChange('description', event.target.value.slice(0, 255));
                    }}
                  />
                  <FormHelperText>
                    <DashboardTypography
                      id='description-helper-text'
                      typographyClass={'title-12-400'}
                    >
                      {' '}
                      {t('MAX_CHARACTERS_MESSAGE', {
                        characterLength: app?.description?.length,
                        maxAllowdedCharLength: configVariables?.app?.maxDescriptionAllowedChar
                      })}
                    </DashboardTypography>
                  </FormHelperText>
                </Grid>
              </DialogContent>
            </Grid>
            {!props.appToEdit && (
              <Grid item xs={6} className='dashboard-template-grid'>
                <DashboardTemplate
                  setDashboardTemplateId={setDashboardTemplateId}
                  dashboardTemplates={dashboardTemplates}
                  isLoading={loading}
                />
              </Grid>
            )}
          </Grid>
          <DialogActions id='dialog-action'>
            <Button data-testid='cancel-app' onClick={props.handleClose}>
              {t('CANCEL')}
            </Button>
            <ThemeProvider theme={button}>
              <Button
                sx={{ height: 'auto', width: 'auto' }}
                variant='contained'
                onClick={addUpdateApp}
                data-testid='save-app-button'
                disabled={
                  app.name === '' ||
                  app.connection.projectIds.length === 0 ||
                  appNameDuplicateError === true
                }
              >
                {props.appToEdit ? t('UPDATE') : t('CreateApp')}
              </Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};
AddNewApp.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  projects: PropTypes.array,
  handleSave: PropTypes.func,
  totalApps: PropTypes.array,
  appToEdit: PropTypes.object,
  updateApp: PropTypes.func
};
export default AddNewApp;
