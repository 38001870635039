import React, { useEffect, useState } from 'react';
import { Button, ThemeProvider, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../../styles/WidgetComponents.scss';
import PropTypes from 'prop-types';
import ApiService from '../../../services/ApiService';
import { ToasterError } from '../../../utils/toaster';
import LoadingSpinner from '../../loader/LoadingSpinner';
import barPlaceholder from '../../../assets/thumbnails/barPlaceholder.svg';
import imagePlaceholder from '../../../assets/thumbnails/imagePlaceholder.svg';
import linePlaceholder from '../../../assets/thumbnails/linePlaceholder.svg';
import metricPlaceholder from '../../../assets/thumbnails/metricPlaceholder.svg';
import piePlaceholder from '../../../assets/thumbnails/piePlaceholder.svg';
import proceessPlaceholder from '../../../assets/thumbnails/proceessPlaceholder.svg';
import textPlaceholder from '../../../assets/thumbnails/textPlaceholder.svg';
import databasePlaceholder from '../../../assets/thumbnails/database.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedExistingWidget } from '../../../Redux/slices/existingWidgetSlice';
import { button } from '../../shared/Themes/ButtonTheme';
import DashboardTypography from '../../shared/DashboardTypography/DashboardTypography';

const ExistingCharts = (props) => {
  const { t } = useTranslation();
  const [allExistingCharts, setAllExistingCharts] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const selectedDataSources = useSelector((state) => state.allDashboard.selectedDataSources);
  const dispatch = useDispatch();
  const isCurrentDashboardUpdated = useSelector(
    (state) => state.allDashboard.isCurrentDashboardUpdated
  );

  useEffect(() => {
    getExistingCharts();
  }, []);

  const getExistingCharts = async () => {
    try {
      setLoading(true);
      const response = await ApiService.getAllExistingWidgets(props.appId);
      setAllExistingCharts(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ToasterError(t('APP.FAILED_TO_FETCH_EXISTING_CHARTS'));
    }
  };

  const getProjectName = (projectId) => {
    const selectedProject = selectedDataSources.find((project) => project.projectId === projectId);
    return selectedProject ? selectedProject.name : 'Unknown';
  };

  const addExistingWidget = (selectedWidget) => {
    if (isCurrentDashboardUpdated) return;
    dispatch(
      setSelectedExistingWidget({
        dashboardId: selectedWidget.dashboardId,
        chartId: selectedWidget.chartId
      })
    );
  };

  return (
    <div className='widgetContainer'>

      {allExistingCharts && allExistingCharts?.length !== 0 ? (
        allExistingCharts.map((existingChart, index) => (
          <div key={index} className='existingChartsContainerBox'>
            <div
              id='existing-chart-card'
              data-testid='existing-chart-card'
              className={`existing-chart-row existing-chart-row-not-selected`}
            >
              <div className='widget-image-placeholder-section'>
                {existingChart.shape === 'bar' && <img src={barPlaceholder} alt={barPlaceholder} />}
                {existingChart.shape === 'line' && (
                  <img src={linePlaceholder} alt='Line Placeholder' />
                )}
                {existingChart.shape === 'svg' && (
                  <img src={proceessPlaceholder} alt='Process Model View Placeholder' />
                )}
                {existingChart.shape === 'text' && (
                  <img src={textPlaceholder} alt='Text Placeholder' />
                )}
                {existingChart.shape === 'numeric' && (
                  <img src={metricPlaceholder} alt='Metric Placeholder' />
                )}
                {existingChart.shape === 'pie' && (
                  <img src={piePlaceholder} alt='Pie Placeholder' />
                )}
                {existingChart.shape === 'image' && (
                  <img src={imagePlaceholder} alt='Image Placeholder' />
                )}
              </div>
              <div className='right-section'>
                <div className='row'>
                  <DashboardTypography title={existingChart.title} typographyClass='title-14-700 text-overflow-85 existing-widget-name'>
                    {existingChart.title}
                  </DashboardTypography>
                </div>
                <div className='row'>
                  <div data-testid='data-source-name' className='data-source-details'>
                    <span className='data-source-placeholder'>
                      <img src={databasePlaceholder} alt='Data Source Placeholder' />
                    </span>
                    <DashboardTypography title={getProjectName(existingChart.projectIds[0])} typographyClass='title-12-400 text-overflow-85 xisting-widget-project-name'>
                      {getProjectName(existingChart.projectIds[0])}
                    </DashboardTypography>
                  </div>
                </div>
                <div className='row'>
                  <Tooltip
                    title={isCurrentDashboardUpdated ? t('WIDGET.ADD_WIDGET_BUTTON_DISABLED') : ''}
                    arrow
                  >
                    <ThemeProvider theme={button}>
                      <Button
                        dataTestid='add-existing-chart-btn'
                        variant='contained'
                        color='primary'
                        onClick={() => addExistingWidget(existingChart)}
                      >
                        {t('ADD')}
                      </Button>
                    </ThemeProvider>

                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div data-testid='empty-dashboard-label' className='no-dashboard-found'>
          {t('APP.NO_EXISTING_CHARTS')}
        </div>
      )}
      {loading === true && <LoadingSpinner data-testid='spinner'></LoadingSpinner>}
    </div>
  );
};

ExistingCharts.propTypes = {
  appId: PropTypes.string
};

export default ExistingCharts;
